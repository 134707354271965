import React from 'react';
import StarfinderNumberInput from "./Components/StarfinderNumberInput";


function ArmorCheckPenalty(props) {
    return (
            <StarfinderNumberInput path="armor_check_penalty" labelId="armorCheckPenalty" size={4} {...props}/>

    );
}

export default ArmorCheckPenalty;