import * as React from 'react';
import {IconButton} from "@mui/material";
import CasinoIcon from "@mui/icons-material/Casino";

export interface Props {
    setRollOpen: (value: boolean) => void,
}


export const DiceButton: React.FC<Props> = (props) => {
    const {setRollOpen} = props;
    return (
        <IconButton

            color="inherit"
            aria-label="roll"
            edge="start"
            onClick={() => setRollOpen(true)}
            size="large">
            <CasinoIcon/>
        </IconButton>
    );
}

export default DiceButton;