import React, {useContext, useEffect, useState} from 'react';
import {CharacterContainer, CharacterContext} from "../Characters/CharacterContainer";
import {IntlProvider, useIntl} from "react-intl";
import Screen from "../Components/Screen";
import {Container, IconButton, Typography} from "@mui/material";
import {messages} from "./en";
import Skills from "./Inputs/Sections/Skills";
import Attributes from "./Inputs/Sections/Attributes";
import CharacterInfo from "./Inputs/Sections/CharacterInfo";
import AdvantagesAndFlaws from "./Inputs/Sections/AdvantagesAndFlaws";
import Blood from "./Inputs/Sections/Blood";
import Disciplines from "./Inputs/Sections/Disciplines";
import Notes from "./Inputs/Sections/Notes";
import BackgroundInfo from "./Inputs/Sections/BackgroundInfo";
import Profile from "./Inputs/Sections/Profile";
import History from "./Inputs/Sections/History";
import VtMDrawer from './VtMDrawer';
import {useCharacterId} from '../Characters/characterHooks';
import {broadcastMessage} from '../MessageComponents/messageListeners';
import {createTheme, adaptV4Theme, ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import {colors, fonts} from "../appConstants";
import DicePopop from "../Components/DicePopop";
import SheetSection from "./Components/SheetSection";
import CasinoIcon from "@mui/icons-material/Casino";


const theme = createTheme(adaptV4Theme({
    typography: {
        fontFamily: "cormorant",
        fontWeightRegular: 'bold',
    },
    MuiCheckbox: {
        color: '#0000000',
    },
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#000000',
        }
    },
}));


const styles = {
    sheetHeader: {
        fontFamily: fonts.vtmSheetFont,
        textAlign: 'center',
    },
    sheetContainer: {
        fontFamily: fonts.vtmSheetFont,
        backgroundColor: colors.vtmBackgroundColor,
        borderStyle: 'double',
        borderRadius: 10,
    },
}

function VtMSheet(props) {
    //todo replace this with reducer
    const {characterId} = props;
    return (
        <CharacterContainer
            characterId={characterId}
        >
            <IntlProvider locale="en" messages={messages}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <VtMSheetContents/>
                    </ThemeProvider>
                </StyledEngineProvider>
            </IntlProvider>
        </CharacterContainer>
    );
}

function VtMSheetContents(props) {
    const [state] = useContext(CharacterContext);
    const [rollOpen, setRollOpen] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const channel = useCharacterId();
    const intl = useIntl();


    useEffect(() => {
        if (initializing) {
            broadcastMessage(channel, "", {...state, serverLoadBroadCast: true});
            setInitializing(false);
        }
    }, [state, initializing, channel])

    if (!state) {
        return (<React.Fragment/>);
    }

    const diceAction = (
        <IconButton
            key="dice"
            color="inherit"
            aria-label="roll"
            edge="start"
            onClick={() => setRollOpen(true)}
            size="large">
            <CasinoIcon/>
        </IconButton>
    );

    return (
        <Screen
            title={state.name}
            actions={[diceAction]}
            drawerItems={[<VtMDrawer key="vtm"/>]}
        >
            <DicePopop
                sectionTemplate={<SheetSection titleId="rollDice"/>}
                font={fonts.vtmSheetFont}
                containerStyles={{padding: '8px'}}
                open={rollOpen}
                handleClose={() => setRollOpen(false)}
            />
            <div
                style={styles.sheetHeader}
            >
                <Typography variant="h4">
                    {intl.formatMessage({id: 'vampire_the_masquerade'})}
                </Typography>
            </div>
            <Container style={styles.sheetContainer}>
                <CharacterInfo/>
                <Attributes/>
                <Skills/>

                <AdvantagesAndFlaws/>
                <BackgroundInfo/>
                <Blood/>
                <Disciplines/>
                <Profile/>

                <History/>
                <Notes/>
            </Container>
        </Screen>

    );
}

export default VtMSheet;