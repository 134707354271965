import React from 'react';
import {IconButton, Tooltip, useMediaQuery} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {useIntl} from 'react-intl';
import {useCharacterId, useIsEditable} from "../../Characters/characterHooks";
import {broadcastMessage} from "../../MessageComponents/messageListeners";
import {useTheme} from "@mui/material/styles";
import StarfinderNumberInput from "./Components/StarfinderNumberInput";
import StarfinderTextInput from "./Components/StarfinderTextInput";

function WeaponInput(props) {

    const {path} = props;
    const intl = useIntl();
    const theme = useTheme();
    const isEditable = useIsEditable();
    const channel = useCharacterId();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));

    function removeItem() {
        broadcastMessage(channel, `${path}.deleted`, true);
    }

    const styles = {
        weaponContainer: {
            marginBottom: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            border: '1px solid grey',
            borderRadius: 6,
            padding: theme.spacing(1),
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        },
    }

    return (
        <div style={styles.weaponContainer}>
            <StarfinderTextInput labelId="weapon" size={largeLayout? 40: 33} path={`${path}.weapon`}/>
            <StarfinderNumberInput labelId="level" size={4} path={`${path}.level`}/>
            <StarfinderNumberInput labelId="attackBonus" size={4} path={`${path}.attack_bonus`}/>
            <StarfinderTextInput labelId="damage" size={10} path={`${path}.damage`}/>
            <StarfinderTextInput labelId="critical" size={10} path={`${path}.critical`}/>
            <StarfinderNumberInput labelId="range" size={4} path={`${path}.range`}/>
            <StarfinderTextInput labelId="type" size={10} path={`${path}.type`}/>
            <StarfinderNumberInput labelId="ammo" size={4} path={`${path}.ammo`}/>
            <StarfinderNumberInput labelId="usage" size={4} path={`${path}.usage`}/>
            <StarfinderTextInput labelId="special" size={10} path={`${path}.special`}/>
            <StarfinderTextInput labelId="notes" size={largeLayout? 40: 33} path={`${path}.notes`}/>
            {isEditable && (
                <React.Fragment>
                    <div style={{flexGrow: 1}}/>
                    <div>
                        <Tooltip title={intl.formatMessage({id: 'deleteWeapon'})}>
                            <IconButton onClick={removeItem} size="large">
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

export default WeaponInput;