import React from 'react';
import {Typography, useMediaQuery} from "@mui/material";
import {useIntl} from "react-intl";
import NumberInput from '../../Components/Inputs/NumberInput';
import {useTheme} from '@mui/styles';
import {useCharacterObjectListener} from "../../Characters/characterHooks";


function SavingThrowInputRow(props) {

    const {
        path,
        ability,
        modifier,
        name
    } = props;

    const intl = useIntl();
    const current = useCharacterObjectListener(path, path);
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));

    const total = Object.keys(current).reduce((acc, key) => acc + parseInt(current[key]), 0) + (modifier || 0);

    const abilityType = intl.formatMessage({id: ability});

    return (
        <tr>
            {largeLayout && (<td>
                <Typography
                    align="left">
                    {intl.formatMessage({id: name.toLowerCase()}, {ability: abilityType})}
                </Typography>
            </td>)}
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    path={`${path}.total`}
                    labelId="total"
                    size={4}
                    min={-10}
                    max={40}
                    readOnlyValue={total}
                    readOnly
                />
            </td>
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    labelId="savingBase"
                    min={0}
                    max={40}
                    name={name}
                    size={4}
                    path={`${path}.base`}
                />
            </td>
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    labelId="abilityMod"
                    path={`${path}.modifier`}
                    min={0}
                    max={40}
                    size={4}
                    readOnly
                    readOnlyValue={modifier}
                />
            </td>
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    labelId="savingMisc"
                    min={0}
                    max={40}
                    name={name}
                    size={4}
                    path={`${path}.misc`}
                />
            </td>
        </tr>
    );
}

export default SavingThrowInputRow;