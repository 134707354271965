import React from 'react';
import SpellRankInput from '../SpellRankInput';
import ListSection from '../../../Components/ListSection';
import SheetSection from '../Components/SheetSection';
import StarfinderAddButton from "../Components/StarfinderAddButton";


function Spells(props) {
    const inputPrototype = SpellRankInput;
    const path="spells";
    return (
        <ListSection
            titleId="spells"
            path={path}
            inputPrototype={inputPrototype}
            sheetSection={SheetSection}
            addButton={<StarfinderAddButton addLabelId="addSpellRank" path={path}/>}
            {...props}
        />
    )
}
export default Spells;