import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Select, InputLabel, FormControl} from "@mui/material";
import {useIntl} from "react-intl";
import {broadcastMessage} from '../../MessageComponents/messageListeners';
import {useCharacterId, useCharacterListener, useIsEditable} from '../../Characters/characterHooks';

function SelectInput(props) {

    const {
        name,
        path,
        options,
        labelId,
        inputLabelStyle,
    } = props;

    const usedName = name ?? path;
    const channel = useCharacterId();
    const isReadOnly = !useIsEditable();
    const intl = useIntl();
    const storedValue = useCharacterListener(usedName, path);

    function onInputChange(event) {
        broadcastMessage(channel, path, event.target.value);
    }

    function renderOptions() {
        return options.map((option) => {
            const display = (option.displayId) ? intl.formatMessage({id: option.displayId}) : option.value;
            return <MenuItem key={option.value} value={option.value}>{display}</MenuItem>;
        });
    }

    const usedCurrent = storedValue || "";
    const usedLabel = labelId ?? usedName;
    const label = intl.formatMessage({id: usedLabel});
    return (
        <div>
            <FormControl
                style={{
                    minWidth: 120
                }}
                variant="outlined"
            >
                <InputLabel
                    htmlFor="select-outlined-label"
                    id="select-label"
                    style={inputLabelStyle}
                    shrink={true}
                >
                    {label}
                </InputLabel>
                <Select
                    disabled={isReadOnly}
                    label={label}
                    value={usedCurrent}
                    displayEmpty
                    inputProps={{
                        id: path.replaceAll(".", "_"),
                        name: "select-labeled",
                      }}
                    onChange={onInputChange}
                >
                    {renderOptions()}
                </Select>
            </FormControl>
        </div>
    );
}

SelectInput.propTypes = {
    name: PropTypes.string,
    path: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    labelId: PropTypes.string,
    inputLabelStyle: PropTypes.object,
};

SelectInput.defaultProps = {
    name: undefined,
    labelId: undefined,
    inputLabelStyle: {}
};


export default SelectInput;