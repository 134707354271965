import React from 'react';
import PropTypes from 'prop-types';
import {Typography, useTheme} from "@mui/material";
import {useIntl} from "react-intl";
import VtMSlider from "../VtMSlider";


function AttributeSubSection(props){

    const {
        attributes,
        titleId,
        path
    } = props;

    const intl = useIntl();
    const theme = useTheme();
    const filler = '.....................................................';

    const styles = {
        attributeSubSectionTitle: {
            fontStyle: 'italic',
        },
        attributeSubSectionContainer: {
            border: '1px solid',
            borderRadius: 6,
        },
        attributeRow: {
            display: 'flex',
            padding: theme.spacing(2)
        },
        attributeName: {
            flexGrow: 1,
            alignItems: 'left',
            overflow: 'hidden',
        },
        attributeSlider: {
            marginLeft: 4,
        }
    }

    function renderAttribute(attribute) {
        return (
            <div
                key={attribute}
                style={styles.attributeRow}
            >
                <Typography
                    align="left"
                    variant="h6"
                    style={styles.attributeName}
                >
                    {intl.formatMessage({id: attribute})}{filler}
                </Typography>
                <div
                    style={styles.attributeSlider}
                >
                    <VtMSlider
                        max={5}
                        path={`${path}.${attribute}`}
                    />
                </div>
            </div>
        );
    }

    return (
        <div
            style={styles.attributeSubSectionContainer}
            key={titleId}
        >
            <Typography
                align="center"
                variant="h6"
                style={styles.attributeSubSectionTitle}
            >
                {intl.formatMessage({id: titleId})}
            </Typography>
            {attributes.map((attribute) => renderAttribute(attribute))}
        </div>
    );
}

AttributeSubSection.propTypes = {
    titleId: PropTypes.string.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.string)
};

AttributeSubSection.defaultProps = {
    attributes: [],
};

export default AttributeSubSection;