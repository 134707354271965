import {connectAuthEmulator, getAuth} from "firebase/auth";
import {firebaseApp} from "../index";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";

let authEmulatorConnected = false;
let firestoreEmulatorConnected = false;

/**
 * Returns a firebsae auth object that can talk to the emulator
 * if the environment is emulated
 * @returns {Auth}
 */
export function getEmulationCapableAuth() {
    const auth = getAuth(firebaseApp);
    if(process.env.REACT_APP_FB_EMULATED === 'true' && !authEmulatorConnected){
        connectAuthEmulator(auth, 'http://localhost:9099');
       authEmulatorConnected = true;
    }
    return auth;
}

/**
 * Returns a firebase firestore object that can talk to the emulator
 * if the environment is emulated;
 * @returns {FirebaseFirestore}
 */
export function getEmulationCapableFirestore() {
    const db = getFirestore(firebaseApp);
    if(process.env.REACT_APP_FB_EMULATED === 'true' && !firestoreEmulatorConnected){
        connectFirestoreEmulator(db, 'localhost', 8080);
        firestoreEmulatorConnected = true;
    }
    return db;
}