import * as React from 'react';
import SheetSection from "../../Components/SheetSection";
import DnD5eTextInput from "../../Components/DnD5eTextInput";
import DnD5eNumberInput from "../../Components/DnD5eNumberInput";
import {useTheme} from "@mui/material";


export const Spellcasting: React.FC = () => {

    const theme = useTheme();

    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            border: '1px dashed',
            borderRadius: 10,
            padding: 16,
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        },
        boxContainer: {
            width: '100%',
        },
        statsContainer: {
            display: 'flex',
            justifyContent: 'space-evenly',
        }
    };

    const path = 'spellcasting';

    return (
        <SheetSection
            titleId="spellcasting"
        >
            <div style={styles.container as React.CSSProperties}>
                <div style={styles.statsContainer}>
                <DnD5eTextInput path={`${path}.class`} labelId="spellcasting_class"/>
                <DnD5eTextInput path={`${path}.ability`} labelId="spellcasting_ability"/>
                </div>
                <div style={styles.statsContainer}>
                <DnD5eNumberInput size={4} labelOnSide path={`${path}.spell_save_dc`} labelId="spell_save_dc"/>
                <DnD5eNumberInput size={4} labelOnSide path={`${path}.spell_attack_bonus`} labelId="spell_attack_bonus"/>
                </div>
            </div>
        </SheetSection>
    );
}

export default Spellcasting;