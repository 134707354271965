import * as React from 'react';
import {Button, Typography} from "@mui/material";
import {fonts} from "../appConstants";

interface Props {
    onClick: () => void;
}

export const SignInWithGoogleButton: React.FC<Props> = (props) => {
    const {onClick} = props;

    return (
        <Button
            variant="outlined"
            onClick={onClick}
        >
        <div className="google-btn">
            <div className="google-icon-wrapper">
                <img className="google-icon"
                     alt="Sign In Button"
                     src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/>
            </div>
            <Typography
                style={{fontFamily: fonts.appFont, fontWeight: "bold"}}
            >
                Sign Up with Google
            </Typography>
        </div>
        </Button>
    );
}