import React from 'react';
import TextBox from "../../../Components/Inputs/TextBox";
import SheetSection from '../Components/SheetSection';

function Feats(props) {
    return (
        <SheetSection
            key="featsAndProficiencies"
            titleId="featsAndProficiencies"
        >
            <TextBox {...props} path="feats" labelId="featsAndProficiencies" rows={22} cols={40} maxLength={1000}/>
        </SheetSection>
    );
}

export default Feats;