import * as React from 'react';
import {useState} from "react";
import {useIntl} from "react-intl";
import {makeNewCharacter} from "../Firebase/firebaseCharacters";
import {Button, Menu, MenuItem, Tooltip} from "@mui/material";
import {PersonAdd} from "@mui/icons-material";
import {colors, fonts, charTypes} from "../appConstants";
import {useHistory} from "react-router-dom";
import {useCanAddCharacters} from "../Accounts/accountHooks";

const styles = {
    addButton: {
        color: "#000000",
    },
    addIcon: {
        color: colors.toolbarColor,
        height: '40px',
        width: '40px',
    },
    starfinder: {
        fontFamily: fonts.starfinderTitleFont,
        textTransform: 'uppercase',
        color: colors.starfinderTitleColor,
    },
    vtm: {
        fontFamily: fonts.vtmTitleFont,
        fontSize: '20px',
    },
    dnd5e: {
        fontFamily: fonts.dnd5eTitleFont,
        fontSize: '20px',
    }
}

export const CharacterAddButton: React.FC = () => {

    const [addAnchorEl, setAddAnchorEl] = useState(null);
    const history = useHistory();
    const canAddCharacters = useCanAddCharacters();
    const addOpen = Boolean(addAnchorEl);

    const intl = useIntl();

    // @ts-ignore
    function addClicked({currentTarget}) {
        if(addAnchorEl == null) {
            setAddAnchorEl(currentTarget);
        }else{
            setAddAnchorEl(null);
        }
    }

    function handleAddClose() {
        setAddAnchorEl(null);
    }


    function getAddHandler(type: string){
        return () => {
            makeNewCharacter({ type: type})
                .then((char) => {
                    history.push(`/${type}/${char.id}`);
                })
            handleAddClose();
        }
    }

    return (
        <div>
            <div id="addButton">
                <Tooltip
                    title={intl.formatMessage({id: 'addCharacter'})}
                >
                    <Button
                        disabled={!canAddCharacters}
                        style={styles.addButton}
                        onClick={addClicked}
                        endIcon={<PersonAdd style={styles.addIcon}/>}
                    >
                        {intl.formatMessage({id: 'addCharacter'})}
                    </Button>
                </Tooltip>
            </div>
            <Menu
                id="basic-menu"
                anchorEl={addAnchorEl}
                open={addOpen}
                onClose={handleAddClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem style={styles.starfinder as React.CSSProperties} onClick={getAddHandler(charTypes.starfinder)}>Starfinder</MenuItem>
                <MenuItem style={styles.vtm as React.CSSProperties} onClick={getAddHandler(charTypes.vtm)}>Vampire the Masquerade</MenuItem>
                <MenuItem style={styles.dnd5e as React.CSSProperties} onClick={getAddHandler(charTypes.dnd5e)}>Dungeons & Dragons 5e</MenuItem>
            </Menu>
        </div>
    );
}
export default CharacterAddButton;