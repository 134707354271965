import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import {IconButton, Toolbar, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuIcon from '@mui/icons-material/Menu';
import Wordmark from "../Marks/Wordmark";
import {makeStyles} from "@mui/styles";
import Profile from "./Profile";

const useStyles = makeStyles((theme) => {
    return {
        appBar: {
            background: '#ffffff',
            color: '#000000',
            marginBottom: '10px',
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        spacer: {
            flexGrow: 1,
            textAlign: 'center',
        },
        appBarShift: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        hide: {
            display: 'none',
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        rightDrawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        rightDrawerPaper: {
            width: drawerWidth,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: -drawerWidth,
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
    };
});

const drawerWidth = 240;

function Screen(props) {

    const {
        children,
        drawerItems,
        drawerHeader,
        isHome,
        headerItems,
        actions,
    } = props;

    const theme = useTheme();
    const classes = useStyles(theme);
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));

    const [open, setOpen] = React.useState(false);

    const defaultActions = [
        <Profile key="profile"/>,
    ];
    const toolbarActions = actions != null? [...actions, ...defaultActions] : defaultActions;

    return (
        <div id="appRoot">
            <AppBar
                position="sticky"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    {!isHome && (<IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setOpen(true)}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                        size="large">
                        <MenuIcon/>
                    </IconButton>)}
                    <Wordmark/>
                    <div>
                        {headerItems}
                    </div>
                    <div className={classes.spacer}>

                    </div>
                    {toolbarActions}
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    {drawerHeader}
                    <IconButton onClick={() => setOpen(false)} size="large">
                        {theme.direction === 'ltr' ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                    </IconButton>
                </div>
                {drawerItems}
            </Drawer>
            <div
                onClick={() => {
                    if(!largeLayout) {
                        setOpen(false);
                    }
                }}
            >
                {children}
            </div>
        </div>
    );
}

Screen.propTypes = {
    drawerItems: PropTypes.arrayOf(PropTypes.node),
    title: PropTypes.string,
    drawerHeader: PropTypes.node,
    isHome: PropTypes.bool,
}

Screen.defaultProps = {
    drawerItems: [],
    title: "",
    isHome: false,
    drawerHeader: <React.Fragment/>
}
export default Screen;