/**
 * Similar to characters the account store is our in ram copy of the user's
 * account
 */
import React, {createContext, useEffect, useReducer, useState} from 'react';
import {listenForYourStripeAccount, listenForYourStripeSubscriptions} from "../Firebase/firebaseAccounts";
import {reducer, updateAccount, updateSubscription} from "./stripeAccountStoreReducer";

const StripeAccountStore = createContext({});

function StripeAccountStoreContainer(props) {

    const {children} = props;
    const [initializing, setInitializing] = useState(true);
    const [state, dispatch] = useReducer(reducer, {});
    // set up sync from firestore to ram
    useEffect(() => {
        if (initializing) {
            // make our connection to firebase
            listenForYourStripeAccount((doc) => {
                dispatch(updateAccount(doc));
            });
            listenForYourStripeSubscriptions((snapShot) => {
                snapShot.docChanges().forEach((change) => {
                    switch (change.type) {
                        case 'added':
                            dispatch(updateSubscription(change.doc));
                            break;
                        case 'modified':
                            dispatch(updateSubscription(change.doc));
                            break;
                        default:
                        // do nothing
                    }
                });
            });
            setInitializing(false);
        }
    }, [initializing]);

    return (
        <StripeAccountStore.Provider value={[state]}>
            {children}
        </StripeAccountStore.Provider>
    )
}

export {StripeAccountStore, StripeAccountStoreContainer};