import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {useCharacterObjectListener} from "../../Characters/characterHooks";
import _ from 'lodash';

function ListInput(props) {
    const {
        path,
        inputPrototype,
    } = props;

    const Prototype = inputPrototype;
    // force it to an array if it's the default {} object
    const current = useCharacterObjectListener(`${path}-listinput`, path, {});


    function renderItem(item, index) {
        const {id} = item;
        return (
                <Prototype
                    key={id}
                    path={`${path}.${id}`}
                    sortOrder={index}
                />
        );
    }

    function renderItems() {
        const dateSorted = _.orderBy(current, 'dateCreated');
        const filtered = dateSorted.filter((item) => !item.deleted);
        return filtered.map((item, index) => renderItem(item, index));
    }


    return (
        <Grid
            container
        >
            {renderItems()}
        </Grid>
    );

}

ListInput.propTypes = {
    path: PropTypes.string.isRequired,
    inputPrototype: PropTypes.any,
};

export default ListInput;