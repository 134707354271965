import React, {useContext} from 'react';
import {Typography, useMediaQuery} from "@mui/material";
import {useIntl} from "react-intl";
import {CharacterContext} from '../../Characters/CharacterContainer';
import {useTheme} from '@mui/styles';
import {useCharacterObjectListener} from "../../Characters/characterHooks";
import StarfinderNumberInput from "./Components/StarfinderNumberInput";

const styles = {
    attackTable: {
        tableLayout: 'fixed',
        width: '100%',
    },
    attackCell: {
        paddingTop: '8px',
    }
};

function AttackBonusInputRow(props) {

    const {
        path,
        ability,
        modifier,
        name
    } = props;

    const intl = useIntl();

    const [character] = useContext(CharacterContext);
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));

    const safeChar = character || {};
    const safeAB = safeChar.attack_bonuses || {};
    const safeBAB = safeAB.base_attack_bonus || 0;
    const current = useCharacterObjectListener(path, path);


    const total = Object.keys(current).reduce((acc, key) => acc + parseInt(current[key]), 0) + safeBAB + (modifier || 0);


    const abilityType = intl.formatMessage({id: ability});

    return (
        <React.Fragment>
            {largeLayout && (<td>
                <Typography
                    align="left">{intl.formatMessage({id: name.toLowerCase()}, {ability: abilityType})}</Typography>
            </td>)}
            <td style={styles.attackCell}>
                <StarfinderNumberInput
                    labelId="total"
                    size={4}
                    min={-10}
                    max={40}
                    path={`${path}.total`}
                    readOnlyValue={total}
                    readOnly
                />
            </td>
            <td style={styles.attackCell}>
                <StarfinderNumberInput
                    labelId="BAB"
                    min={0}
                    max={40}
                    size={4}
                    readOnlyValue={safeBAB}
                    path={`${path}.bab`}
                    readOnly
                />
            </td>
            <td style={styles.attackCell}>
                <StarfinderNumberInput
                    labelId="attackAbilityMod"
                    labelArgs={{ability: intl.formatMessage({id: ability})}}
                    min={0}
                    max={40}
                    size={4}
                    path={`${path}.modifier`}
                    readOnlyValue={modifier}
                    readOnly
                />
            </td>
            <td style={styles.attackCell}>
                <StarfinderNumberInput
                    labelId="attackMisc"
                    min={0}
                    max={40}
                    size={4}
                    path={`${path}.misc`}
                />
            </td>
        </React.Fragment>
    );
}

export default AttackBonusInputRow;