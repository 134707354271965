import React from 'react';
import TOCDrawer from '../TOCDrawer';


function VtMDrawer(props) {


    const sheetSections = [
        'characterInfo',
        'attributes',
        'skills',
        'advantages_and_flaws',
        'background_info',
        'blood',
        'disciplines',
        'profile',
        'history_header',
        'notes_header',
    ];

    return (
        <TOCDrawer sheetSections={sheetSections}/>
    )

}

export default VtMDrawer;