import {getEmulationCapableAuth, getEmulationCapableFirestore} from "./initUtils";
import {collection, query, doc, onSnapshot, addDoc,} from "firebase/firestore";
import {getFunctions, httpsCallable} from "firebase/functions";
import {firebaseApp} from "../index";

// the stripe extension really owns our customer stuff now
export const CUSTOMERS_COLLECTION = 'customers';
export const PRODUCTS_COLLECTION = 'products';

export const ACCOUNT_COLLECTION = 'users';

export function listenForYourAccount(shapshotHandler) {
    const db = getEmulationCapableFirestore();
    const auth = getEmulationCapableAuth();
    const user = auth.currentUser;
    const docRef = doc(db, ACCOUNT_COLLECTION, user.uid);
//    const q = query(charsRef, where('userId', '==', user.uid));
    onSnapshot(docRef, shapshotHandler);
}


export function listenForYourStripeAccount(snapshotHandler) {
    const db = getEmulationCapableFirestore();
    const auth = getEmulationCapableAuth();
    const user = auth.currentUser;
    const customerRef = doc(db, CUSTOMERS_COLLECTION, user.uid);
    onSnapshot(customerRef, snapshotHandler);
}

export function listenForYourStripeSubscriptions(snapshotHandler) {
    const db = getEmulationCapableFirestore();
    const auth = getEmulationCapableAuth();
    const user = auth.currentUser;
    const subsRef = collection(db, CUSTOMERS_COLLECTION, user.uid, "subscriptions");
    const subs = query(subsRef);
    onSnapshot(subs, snapshotHandler);
}


export function subscribeWithStripe() {
    // todo move this to the firestore side
    // start the checkout session (there's a hook in stripe watching for the checkout session collection
    const db = getEmulationCapableFirestore();
    const auth = getEmulationCapableAuth();
    const user = auth.currentUser;
    return addDoc(collection(db, `customers/${user?.uid}/checkout_sessions`), {
        price: process.env.REACT_APP_STRIPE_SUBSCRIPTION_MONTHLY_ID,
        success_url: window.location.toString(),
        cancel_url: window.location.toString(),
    }).then((docRef) => {
        return new Promise((resolve, reject) => {
            onSnapshot(docRef, (snap) => {
                const {error, url} = snap.data();
                if (error) {
                    reject(error);
                }
                if (url) {
                    resolve(url);
                }
            });
        });
    });
}

export function openStripeManagePage() {
    // Call billing portal function
    const functions = getFunctions(firebaseApp);
    const portal = httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
    return portal({returnUrl: window.location.toString()})
        .then((result) => {
            const {data} = result;
            return data.url;
        });
}
