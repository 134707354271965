import * as React from 'react';
import TextInput from "../../Components/Inputs/TextInput";


const textFieldStyle = {
    margin: 0,
    padding: 0,
    color: '#000000',
};

const VtMTextInput: React.FC = (props) => {

    return (
        <TextInput {...props} style={textFieldStyle}/>
    )
}

export default VtMTextInput;