/**
 * THe character store is our _in RAM_ copy of the characters,
 * and is mostly used to make it east for sub components to
 * get the parts of the character they want.
 */
import React, {createContext, useEffect, useReducer, useState} from 'react';
import {reducer, removeChar, updateChar} from './characterStoreReducer';
import {listenForYourCharacters} from "../Firebase/firebaseCharacters";

const CharacterStore = createContext({});
export const CHARACTERS = "Characters";

function CharacterStoreContainer(props) {

    const { children } = props;
    const [initializing, setInitializing] = useState(true);
    const [state, dispatch] = useReducer(reducer, {});
    // set up sync from firestore to ram
    useEffect(() => {
        if (initializing) {
            // make our connection to firebase
            listenForYourCharacters((snapShot) => {
               snapShot.docChanges().forEach((change) => {
                    switch (change.type) {
                        case 'added':
                            //console.dir("add")
                            dispatch(updateChar(change.doc));
                            break;
                        case 'modified':
                            //console.dir("modified")
                            dispatch(updateChar(change.doc));
                            break;
                        case 'removed':
                            //console.dir("removed");
                            dispatch(removeChar(change.doc));
                            break;
                        default:
                            // do nothing
                    }
                });
            });
            setInitializing(false);
        }
    }, [initializing]);

    return (
        <CharacterStore.Provider value={[state, dispatch]}>
            {children}
        </CharacterStore.Provider>
    )
}

export { CharacterStore, CharacterStoreContainer};