import * as React from 'react';
import TOCDrawer from '../TOCDrawer';


const DnD5eDrawer: React.FC = () => {


    const sheetSections = [
        'vital_information',
        'abilities_and_skills',
        'combat_stats',
        'weapons',
        'attacks',
        'spellcasting',
        'spells',
        'motives',
        'features_and_traits',
        'proficiencies',
        'equipment',
        'wealth',
    ];

    return (
        <TOCDrawer sheetSections={sheetSections}/>
    )

}

export default DnD5eDrawer;