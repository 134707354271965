import React from 'react';
import {Modal} from '@mui/material';
import PropTypes from 'prop-types';
import DiceRoller from './Dice/DiceRoller';
import Button from "@mui/material/Button";


function DicePopop(props) {

    const {sectionTemplate, font, containerStyles} = props;

    const safeContainerStyles = containerStyles ?? {};
    const styles = {
        container: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            ...safeContainerStyles
        },
        buttonContainer: {
            marginTop: '8px',
            display: 'flex',
        },
        buttonSpacer: {
            flexGrow: 1,
        }
    }


    const {
        open,
        handleClose,
    } = props;


    const rollers = (
        <div style={{padding: '8px'}}>
            <DiceRoller font={font}/>
            <DiceRoller font={font}/>
            <DiceRoller font={font}/>
            <div style={styles.buttonContainer}>
                <div style={styles.buttonSpacer}/>
                <Button
                    variant="contained"
                    onClick={handleClose}
                >
                    Close
                </Button>
            </div>
        </div>
    );

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <div style={styles.container}>
                {React.cloneElement(sectionTemplate, {children: rollers})}
            </div>
        </Modal>
    )
}

DicePopop.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    sectionTemplate: PropTypes.element.isRequired,
    font: PropTypes.string.isRequired,
}

export default DicePopop;