import * as React from 'react';
import {colors, fonts} from "../appConstants";
import {useIntl} from "react-intl";
import {useHistory} from "react-router-dom";
import {Character} from "../types";
import CharacterSummaryMore from "./CharacterSummaryMore";
import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import {Pause, PlayArrow} from "@mui/icons-material";
import {reactivateCharacter, retireCharacter} from "../Firebase/firebaseCharacters";


export interface Props {
    character: Character;
    menuActions?: React.ReactNode;
}


export const CharacterSummary: React.FC<Props> = (props) => {

    const {character} = props;
    //console.dir(character);
    const {id, name, type, retired} = character;

    const intl = useIntl();
    const history = useHistory();

    const styles = {
        container: {
            borderRadius: 6,
            border: '1px solid black',

        },
        header: {
            display: 'flex',
        },
        infoContainer: {
            cursor: 'pointer',
        },
        vtmCharName: {
            fontFamily: fonts.vtmSheetFont,
            fontSize: '32px',
        },
        starfinderCharName: {
            fontSize: '32px',
            fontFamily: fonts.starfinderSheetFont,
        },
        dnd5eCharName: {
            fontSize: '32px',
            fontFamily: fonts.dnd5eSheetFont,
        },
        vtmName: {
            fontFamily: fonts.vtmTitleFont,
            fontWeight: 'bold',
            fontSize: '20px',
        },
        starfinderName: {
            fontFamily: fonts.starfinderTitleFont,
            color: colors.starfinderTitleColor,
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        dnd5eName: {
            fontFamily: fonts.dnd5eTitleFont,
            fontWeight: 'bold',
            fontSize: '20px',
        },

    };

    function onCharClick() {
        history.push(`${type}/${id}`);
    }

    // @ts-ignore
    const nameStyle = styles[`${type}Name`];
    // @ts-ignore
    const charNameStyle = styles[`${type}CharName`];

    const gameName = (
        <div
            style={nameStyle}
        >
            {intl.formatMessage({id: `${type}Name`})}
        </div>
    );

    const liveCharItems =
        [{
            name: "Retire Character", onClick: () => {
                retireCharacter(id);
            }, icon: <Pause/>
        }];

    const retiredCharItems = [{
        name: "Reactivate Character", onClick: () => {
            reactivateCharacter(id);
        }, icon: <PlayArrow/>
    }]

    const items = (retired ?? false) === false? liveCharItems : retiredCharItems;

    return (
        <Card
            style={styles.container as React.CSSProperties}
        >
            <CardHeader
                action={<CharacterSummaryMore items={items}/>}
                title={gameName}
                 />
            <CardContent
                style={styles.infoContainer}
                onClick={onCharClick}
                >
                <Typography style={charNameStyle}>{name}</Typography>
            </CardContent>
        </Card>

    );
}

export default CharacterSummary;