import * as React from 'react';
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import {useIntl} from "react-intl";
import {colors} from "../appConstants";

export interface Props {
    // translation id of title
    titleId: string,
    actionButton?: React.ReactNode,
}

const homeStyles = {
    section: {
        marginTop: 15,
        marginBottom: 15,
    },

    sectionHeaderContainerSmall: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alightItems: 'center',
    },

    sectionHeaderContainer: {
        display: "flex",
    },

    sectionHeaderSpacer: {
        flexGrow: 1,
    },


    sectionNameContainer: {
        width: 300,
        display: 'block',
        marginLeft: 0,
        paddingLeft: '1rem',
        marginRight: 'auto',
        background: colors.toolbarColor,
        color: '#000000',
        borderRadius: '6px 6px 0px 0px'
    },

    sectionTitle: {
        paddingBottom: '8px',
        paddingTop: '8px',
        align: 'left',
        minWidth: 350,
    },

    sectionChildContainer: {
        border: '1px solid #1c4e77',
        borderRadius: 6,
        padding: 24,
    },

};

export const HomeSection: React.FC<Props> = (props) => {

    const {titleId, actionButton, children} = props;
    const intl = useIntl();
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));
    const headerStyle = largeLayout ? homeStyles.sectionHeaderContainer : homeStyles.sectionHeaderContainerSmall;

    return (
        <div
            style={homeStyles.section}
            id={titleId}
        >
            <div style={headerStyle}>
                <div style={homeStyles.sectionNameContainer}>
                    <div style={homeStyles.sectionTitle}>
                        <Typography variant="h5">
                            {intl.formatMessage({id: titleId})}
                        </Typography>
                    </div>
                </div>
                {largeLayout && (<div style={homeStyles.sectionHeaderSpacer}/>)}
                    <div>
                        {actionButton}
                    </div>

            </div>
            <div style={homeStyles.sectionChildContainer}>
                {children}
            </div>
        </div>
    );
}