import React from 'react';
import SheetSection from "../../Components/SheetSection";
import {Grid} from "@mui/material";
import NumberInput from "../../../Components/Inputs/NumberInput";
import TextBox from "../../../Components/Inputs/TextBox";
import VtMTextInput from "../VtMTextInput";


function Profile(props) {


    return (
        <SheetSection
            titleId="profile"
        >
            <Grid
                container
                justifyContent='space-around'
                spacing={1}
            >
                <Grid
                    item
                    container
                    key="true_age"
                    xs={12}
                    sm={2}
                    justifyContent="center"
                >
                    <NumberInput
                        path="true_age"
                        min={0}
                        max={99999}
                         size={5}
                    />
                </Grid>
                <Grid
                    item
                    container
                    key="apparent_age"
                    xs={12}
                    sm={2}
                    justifyContent="center"
                >
                    <NumberInput
                        min={0}
                        max={99999}
                        path="apparent_age"
                        size={10}
                    />
                </Grid>
                <Grid
                    item
                    container
                    key="date_of_birth"
                    xs={12}
                    sm={2}
                    justifyContent="center"
                >
                    <VtMTextInput
                        path="date_of_birth"
                        size={30}
                    />
                </Grid>
                <Grid
                    item
                    container
                    key="date_of_death"
                    xs={12}
                    sm={3}
                    justifyContent="center"
                >
                    <VtMTextInput
                        path="date_of_death"
                        size={30}
                    />
                </Grid>
                <Grid
                    item
                    container
                    key="appearance"
                    xs={12}
                    justifyContent="center"
                >
                    <div style={{width: '100%'}}>
                        <TextBox
                            path="appearance"
                            rows={5}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    container
                    key="distinguishing_features"
                    xs={12}
                    justifyContent="center"
                >
                    <div style={{width: '100%'}}>
                        <TextBox
                            path="distinguishing_features"
                            rows={5}
                        />
                    </div>
                </Grid>
                <Grid
                    item
                    container
                    key="total_experience"
                    xs={12}
                    sm={6}
                    justifyContent="center"
                >
                    <NumberInput
                        min={0}
                        max={99999}
                        path="total_experience"
                        size={10}
                    />
                </Grid>
                <Grid
                    item
                    container
                    key="spent_experience"
                    xs={12}
                    sm={6}
                    justifyContent="center"
                >
                    <NumberInput
                        path="spent_experience"
                        min={0}
                        max={99999}
                        size={10}
                    />
                </Grid>
            </Grid>
        </SheetSection>
    );
}
export default Profile;