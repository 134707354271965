import * as React from 'react';
import DnD5eNumberInput from "../Components/DnD5eNumberInput";

export interface Props {
    path: string,
}

const styles = {
    container: {
        border: `1px dashed`,
        borderRadius: 10,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
    sliderBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    sliderLabel: {
        textAlign: 'left',
        marginRight: 8,
    }

}

export const HP: React.FC<Props> = (props) => {

    const {path} = props;


    return (
        <div
            style={styles.container as React.CSSProperties}
        >
            <DnD5eNumberInput labelOnSide size={4} min={0} path={`${path}.max_hp`} labelId="max_hp"/>
            <DnD5eNumberInput labelOnSide size={4} min={0} path={`${path}.current_hp`} labelId="current_hp"/>
            <DnD5eNumberInput labelOnSide size={4} min={0} path={`${path}.temp_hp`} labelId="temp_hp"/>
        </div>
    );

}

export default HP;