import React from 'react';
import TextBox from "../../../Components/Inputs/TextBox";
import SheetSection from '../Components/SheetSection';

function Abilities(props) {

    return (
        <SheetSection
            key="abilities"
            titleId="abilities"
        >
            <TextBox {...props} path="abilities" labelId="abilities" rows={20} cols={100} maxLength={2000}/>
        </SheetSection>
    );
}

export default Abilities;