import _ from 'lodash';
/**
 * Registers a listener for the given object with the path.
 * Since an object in a heirarchy can be modified either from above or below
 * the callback will fire on any message that is a proper prefix or a suffix
 * of the given path. E.G. given a path foo.bar the listner will receive a message
 * if a message is sent for foo or foo.bar.baz or foo.bar
 * @param channel the channel to listen on. Can be considered a namespace for prefixes
 * @param path the path inside the channel to listen on.
 * @param initialValue the initial value to return for the object
 */
const registry = {};

// a path listener listens to the entirety of the channel,
// but does not get decoupled path objects.
// it just gets the update with the path the update occured on

export function registerPathListener(channel, name, callback){
    const channelRegistry = registry[channel]?? {};
    channelRegistry[name] = {path: '', pathBased: true, callback, name}
    registry[channel] = channelRegistry;
}

export function registerListener(channel, name, path, callback) {
    const channelRegistry = registry[channel]?? {};
    channelRegistry[name] = {path, callback, name}
    registry[channel] = channelRegistry;
}

export function unregisterListener(channel, name) {
    const channelRegistry = registry[channel]?? {};
    delete channelRegistry[name];
}

// Given an update path, and a subpath, extracts the value of
// the item at the subpath FROM the value object passed in.
// eg. given updatePath foo.bar, subpath foo.bar.baz.car the value extracted
// would be the subkey baz.car from the object
function extractObjectPath(updatePath, subPath, value) {
    const valuePath = subPath.slice(updatePath.length);
   // console.error(`value path ${valuePath}`);
   // console.error(value);
    // if we're  looking for an empty valuePath it means we've got an exact match, just return the value
    const extracted = valuePath? _.get(value, valuePath) : value;
  //  console.error(extracted);
    return extracted;
}

function buildPatchObject(path, value){
    // if we're not the root, and we are some random subkey we'll start with ., which we need to trim off
    const trimmedPath = path.startsWith('.')? path.substring(1) : path;
    const patch = {};
    _.set(patch, trimmedPath, value);
    return patch;
}

export function broadcastMessage(channel, path, value) {
    // do this all in a new thread
    setTimeout(() => {
        const channelRegistry = registry[channel] ?? {};
        const listenerCandidates = Object.keys(channelRegistry);
        listenerCandidates.forEach((candidateName) => {
            const candidate = channelRegistry[candidateName];
            const {path: candidatePath, callback, pathBased} = candidate;
            if(pathBased) {
                // just forward the update along
                callback(path, value);
                return;
            }
            // update came from above, send message consisting of my subpath of upper level object
            if (candidatePath == null) {
                return;
            }
            let update;
            if (candidatePath.startsWith(path)){
                //          console.error('higher level key update')
                update = extractObjectPath(path, candidatePath, value);
            }else if(path.startsWith(`${candidatePath}`)) {
                // update came from below, return sub tree corresponding to the update
                    //console.dir(`subkey update for path ${candidatePath} and candidate ${name}`);
                const subpath = path.substring(candidatePath.length);
                update = buildPatchObject(subpath, value);
                setTimeout(() => callback(update), 1);
            }else{
                return;
            }
           // console.log(`broadcasting update ${JSON.stringify(update)} to ${candidatePath}`)
            setTimeout(() => callback(update), 1);
        });
    }, 1);
}


