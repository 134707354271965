import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "../components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import Wordmark from "../../Marks/Wordmark";
import {useHistory, useLocation} from "react-router-dom";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import macHeroScreenshotImageSrc from "../images/hero-screenshot-2.png";
import {useAuth} from "reactfire";
import {SignInWithGoogleButton} from "../../Components/SignInWithGoogleButton";

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/4 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${props => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

// stolen from react router docs
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Login = (props) => {
  const query = useQuery();
  const auth = useAuth();
  const history = useHistory();

  const signIn = async auth => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
    history.push("/");
  }

  const signup = !!query.get("signup");
  return (
      <AnimationRevealPage disabled>
        <Container>
          <Content>
            <MainContainer>
              <LogoLink href="/">
                <Wordmark/>
              </LogoLink>
              <MainContent>
                {signup && (<Heading>Welcome!</Heading>)}
                <div>
                  We're cloud based, hence you'll need a Google account
                  to use Character Place
                </div>
                <FormContainer>
                  <SignInWithGoogleButton onClick={() => signIn(auth)}/>
                </FormContainer>
              </MainContent>
            </MainContainer>
            <IllustrationContainer>
              <IllustrationImage imageSrc={macHeroScreenshotImageSrc}/>
            </IllustrationContainer>
          </Content>
        </Container>
      </AnimationRevealPage>
  );
}
export default Login;