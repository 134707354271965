import * as React from 'react';
import CharacterAddButton from "./CharacterAddButton";
import UpgradeButton from "./UpgradeButton";
import {useCanAddCharacters} from "../Accounts/accountHooks";


export const AddCharOrUpgradeButton: React.FC = () => {
    const canAddCharacters = useCanAddCharacters();

    if (!canAddCharacters) {
        return (<UpgradeButton/>);
    }

    return <CharacterAddButton/>
}

export default AddCharOrUpgradeButton;


