import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@mui/material';
import {useIntl} from 'react-intl';
import {useCharacterId, useCharacterListener, useIsEditable} from '../../Characters/characterHooks';
import {broadcastMessage} from '../../MessageComponents/messageListeners';
import {INPUT_SAVE_DELAY_MILLIS} from '../../constants';
import {pathToCSSId} from '../../Utils/css';

let onChangeTimeout;
function TextBox(props) {

    const {
        name,
        path,
        maxLength,
        labelId,
        rows,
    } = props;

    const fieldRef = useRef();
    const channel = useCharacterId();
    const isReadOnly = !useIsEditable();
    const usedName = name ?? path;
    const storedValue = useCharacterListener(usedName, path);
    const intl = useIntl();


    // self debounces
    function onInputChange(event) {
        const newValue = event.target.value;
        clearTimeout(onChangeTimeout);
        onChangeTimeout = setTimeout(() => {
            broadcastMessage(channel, path, newValue);
        }, INPUT_SAVE_DELAY_MILLIS);
    }



    const usedLabel = labelId ?? usedName;
    return (
        <div>
                <TextField
                    id={pathToCSSId(path)}
                    label={intl.formatMessage({id: usedLabel})}
                    defaultValue={storedValue}
                    onBlur={onInputChange}
                    multiline
                    margin="normal"
                    fullWidth
                    inputProps={{minRows: rows, readOnly: isReadOnly, ref: fieldRef}}
                    variant="outlined"
                    maxLength={maxLength}
                    disabled={isReadOnly}
                />
        </div>
    );
}

TextBox.propTypes = {
    name: PropTypes.string,
    path: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    labelId: PropTypes.string,
};

TextBox.defaultProps = {
    name: undefined,
    labelId: undefined,
    rows: 5,
    cols: 5,
    maxLength: 100,
};


export default TextBox;

