import React from 'react';
import {IconButton, Tooltip} from '@mui/material';
import {Delete} from '@mui/icons-material';
import {useIntl} from 'react-intl';
import {useCharacterId, useIsEditable} from "../../Characters/characterHooks";
import {broadcastMessage} from "../../MessageComponents/messageListeners";
import {useTheme} from "@mui/material/styles";
import DnD5eTextInput from "../Components/DnD5eTextInput";
import DnD5eNumberInput from "../Components/DnD5eNumberInput";
import TextBox from "../../Components/Inputs/TextBox";

function EquipmentInput(props) {

    const {path} = props;
    const intl = useIntl();
    const channel = useCharacterId();
    const theme = useTheme();
    const isEditable = useIsEditable();

    const styles = {
        equipmentContainer: {
            margin: theme.spacing(1),
            border: '1px dashed',
            borderRadius: 10,
            padding: theme.spacing(1),
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        },
    }


    function softDelete() {
        broadcastMessage(channel, `${path}.deleted`, true);
    }

    return (
        <div style={styles.equipmentContainer}>
            <DnD5eTextInput labelId="equipment_name" path={`${path}.name`}/>
            <DnD5eNumberInput size={4} path={`${path}.weight`} labelId="weight"/>
            {isEditable && (
                <React.Fragment>
                    <div style={{flexGrow: 1}}/>
                    <div>
                        <Tooltip title={intl.formatMessage({id: 'delete_equipment'})}>
                            <IconButton onClick={softDelete} size="large">
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </React.Fragment>
            )}
            <div style={{width: '100%'}}>
                <TextBox
                    rows={2}
                    labelId="notes"
                    path={`${path}.notes`}
                />
            </div>

        </div>
    );
}

export default EquipmentInput;