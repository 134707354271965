import * as React from 'react';
import SheetSection from "../../Components/SheetSection";
import NumberInput from "../../../Components/Inputs/NumberInput";

const styles = {
    container: {
        marginTop: 16,
        marginBottom: 8,
        marginLeft: 8,
        marginRight: 8,
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    }
}

const coinTypes = ['cp', 'sp', 'ep', 'gp', 'pp'];

export const Wealth: React.FC = () => {

    const path = "wealth";


    return (
        <SheetSection titleId="wealth">
            <div style={styles.container as React.CSSProperties}>
                {coinTypes.map((type) => {
                    return (
                        <NumberInput
                            key={type}
                            path={`${path}.${type}`}
                            labelId={type}
                        />
                    );
                })}
            </div>
        </SheetSection>
    )
}

export default Wealth;