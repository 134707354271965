import React from 'react';
import {Typography, Button} from '@mui/material';
import {rollDie} from '../../Utils/Dice';
import NumSelect from '../NumSelect';
import RollResult from './RollResult';
import {Casino} from "@mui/icons-material";

function DiceRoller(props) {

    const {font} = props;

    const diceStyles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            margin: '4px',
        },
        rollContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        text: {
            margin: '4px',
            fontFamily: font,
        },
        input: {
            fontFamily: font,
        },
        button: {
            fontFamily: font,
        }
    };

    const [num, setNum] = React.useState(1);
    const [d, setD] = React.useState(20);
    const [rolls, setRolls] = React.useState([]);


    function rollDice() {
        const rolls = Array(num).fill().map(() => rollDie(d));
        setRolls(rolls);
    }

    return (
        <div style={diceStyles.container}>
            <div style={diceStyles.rollContainer}>
                <Button
                    style={diceStyles.button}
                    onClick={rollDice}
                    variant="contained"
                    endIcon={<Casino/>}
                >
                    Roll
                </Button>
                <NumSelect inputStyle={diceStyles.input} value={num} onChange={setNum}
                           possibleValues={[1, 2, 3, 4, 5]}/>
                <Typography style={diceStyles.text}>D</Typography>
                <NumSelect inputStyle={diceStyles.input} value={d} onChange={setD}
                           possibleValues={[4, 6, 8, 10, 20, 100]}/>
            </div>
            <RollResult font={font}     rolls={rolls}/>
        </div>
    );

}

export default DiceRoller;