import React from 'react';
import WeaponInput from '../WeaponInput';
import ListSection from '../../../Components/ListSection';
import SheetSection from '../Components/SheetSection';
import StarfinderAddButton from "../Components/StarfinderAddButton";

function Weapons(props) {

    const inputPrototype = WeaponInput;
    const path = 'weapons';

    return (
        <ListSection
            path={path}
            inputPrototype={inputPrototype}
            addButton={<StarfinderAddButton addLabelId="addWeapon" path={path}/>}
            titleId="weapons"
            sheetSection={SheetSection}
            {...props}
        />
    );
}

export default Weapons;