import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox} from "@mui/material";
import {useCharacterId, useCharacterListener, useIsEditable} from '../../Characters/characterHooks';
import {broadcastMessage} from '../../MessageComponents/messageListeners';
import {pathToCSSId} from '../../Utils/css';


function CheckInput(props) {
    const {
       path, size,
    } = props;

    const isEditable = useIsEditable();
    const channel = useCharacterId();
    const storedValue = useCharacterListener(path, path);

    const onChange = (event) => broadcastMessage(channel, path, event.target.checked);

    return (
        <div>
            <Checkbox
                id={pathToCSSId(path)}
                defaultChecked={storedValue}
                onChange={onChange}
                disabled={!isEditable}
                size={size}
            />
        </div>
    );
}

CheckInput.propTypes = {
    path: PropTypes.string.isRequired,
    size: PropTypes.string,
};

CheckInput.defaultProps = {
    size: 'small',
};

export default CheckInput;

