import * as React from 'react';
import CumulativeInput from "../../Components/Inputs/CumulativeInput";

const sliderStyle = {
    color: '#000000',
    '& > *': {
        color: '#000000'
    },
};

export interface Props {
    max: number,
    path: string,
}

const DnD5eSlider: React.FC<Props> = (props) => {
    return <CumulativeInput {...props} sliderStyle={sliderStyle}/>;
}

export default DnD5eSlider;