import React from 'react';
import PropTypes from 'prop-types';
import {useIntl} from "react-intl";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {colors} from "../../appConstants";

const styles = {
    container: {
        backgroundColor: colors.vtmBackgroundColor,
    },
    sheetSectionTitle: {
        textAlign: 'center',
        flexGrow: 1,
    },
    sheetSectionHeader: {
        display: 'flex',
        justifyContent: 'left',
        fontFamily: 'texturina',
    },
    sheetSectionHR: {
        border: '2px solid black',
        width: '100%',
    },
}

function SheetSection(props) {
    const intl = useIntl();

    const {
        titleId,
        children,
        action,
    } = props;
    return (
        <div style={styles.container} id={titleId}>
            <div style={styles.sheetSectionHeader}>
                <div style={styles.sheetSectionTitle}>
                    <Typography variant="h6">
                        <Box fontWeight="bold">
                            {intl.formatMessage({id: titleId})}
                        </Box>
                    </Typography>
                </div>
            </div>
            <hr style={styles.sheetSectionHR}/>
            {action && (
                <div>
                    {action}
                </div>
            )}
            {children}
        </div>
    )
}

SheetSection.propTypes = {
    titleId: PropTypes.string.isRequired,
    action: PropTypes.node,
};

export default SheetSection;