import * as React from 'react';
import SheetSection from "../../Components/SheetSection";

import ListSection from "../../../Components/ListSection";
import EquipmentInput from "../EquipmentInput";
import DnD5eAddButton from "../../Components/DnD5eAddButton";


export const Equipment: React.FC = () => {


    const inputPrototype = EquipmentInput;
    const path="equipment";

    return (
        <ListSection
            titleId="equipment"
            path={path}
            inputPrototype={inputPrototype}
            sheetSection={SheetSection}
            addButton={<DnD5eAddButton addLabelId="add_equipment" path={path}/>}
        />
    );
}

export default Equipment;