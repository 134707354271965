import Tooltip from "@mui/material/Tooltip";
import {IconButton, Typography} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import * as React from "react";
import {useCharacterId, useIsEditable} from "../Characters/characterHooks";
import {useIntl} from "react-intl";
import {generateSafeUUID} from "../Utils/Ids";
import {broadcastMessage} from "../MessageComponents/messageListeners";


export interface Props {
    path: string,
    addLabelId: string,
    style?: React.CSSProperties,
}

const AddButton: React.FC<Props> = (props) => {

    const {addLabelId, path, style} = props;

    const intl = useIntl();
    const isEditable = useIsEditable();
    const channel = useCharacterId();

    function addItem() {
        const date = new Date();
        const id = generateSafeUUID();
        const newItem = { id, dateCreated: date.getTime()}
        broadcastMessage(channel, `${path}.${id}`, newItem);
    }


    const buttonLabel = intl.formatMessage({id: addLabelId});
    return !isEditable ? null : (
        <div style={style}>
            <Tooltip
                title={buttonLabel}
            >
                <IconButton
                    style={style}
                    id={addLabelId}
                    onClick={addItem}
                    size="large">
                    <AddCircle/>
                    <Typography>{buttonLabel}</Typography>
                </IconButton>
            </Tooltip>
        </div>);


}
export default AddButton;