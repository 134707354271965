const UPDATE_ACCOUNT = 'UA';
const UPDATE_SUB = 'UC';


export function updateAccount(doc){
    return {
        type: UPDATE_ACCOUNT,
        doc
    };
}

export function updateSubscription(doc){
    return {
        type: UPDATE_SUB,
        doc
    };
}

function doUpdateAccount(state, action){
    const {doc} = action;
    const data = doc.data();
    const newState = {...state, ...data};
    return newState;
}

// for now only handles one sub
function doUpdateSub(state, action){
    const {doc} = action;
    const data = doc.data();
    const newState = {...state, subscription: data}
    return newState;
}

export function reducer(state, action) {
    switch (action.type) {
        case UPDATE_SUB:
            return doUpdateSub(state,action);
        case UPDATE_ACCOUNT:
            return doUpdateAccount(state, action);
        default: return state;
    }
}