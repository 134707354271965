import React from 'react';
import HealthAndResolveInput from './Components/HealthAndResolveInput';


function StaminaInput(props) {

    return (
        <HealthAndResolveInput {...props}/>
    );
}

export default StaminaInput;