import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {CharacterContainer, CharacterContext} from "../Characters/CharacterContainer";
import {IntlProvider, useIntl} from "react-intl";
import Screen from "../Components/Screen";
import {Container, Typography} from "@mui/material";
import {messages} from "./en";

import {useCharacterId} from '../Characters/characterHooks';
import {broadcastMessage} from '../MessageComponents/messageListeners';
import {createTheme, adaptV4Theme, ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import {fonts} from "../appConstants";
import DicePopop from "../Components/DicePopop";
import SheetSection from "./Components/SheetSection";
import DiceButton from "../Components/Dice/DiceButton";
import DnD5eDrawer from "./DnD5eDrawer";
import AbilitiesAndSkills from "./Inputs/Sections/AbilitiesAndSkills";
import VitalInformation from "./Inputs/Sections/VitalInformation";
import Motives from "./Inputs/Sections/Motives";
import Spells from "./Inputs/Sections/Spells";
import Features from "./Inputs/Sections/Features";
import Proficiencies from "./Inputs/Sections/Proficiencies";
import Wealth from "./Inputs/Sections/Wealth";
import Equipment from "./Inputs/Sections/Equipment";
import CombatStats from "./Inputs/Sections/CombatStats";
import Spellcasting from "./Inputs/Sections/Spellcasting";
import Attacks from "./Inputs/Sections/Attacks";
import Weapons from "./Inputs/Sections/Weapons";



const theme = createTheme(adaptV4Theme({
    typography: {
        fontFamily: fonts.dnd5eSheetFont,
    },
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#000000',
        }
    },
}));


const styles = {
    sheetHeader: {
        fontFamily: fonts.dnd5eTitleFont,
        textAlign: 'center',
    },
    sheetContainer: {
        fontFamily: fonts.dnd5eSheetFont,
        borderStyle: 'double',
        borderRadius: 10,
    },
    nameText: {
        fontFamily: fonts.dnd5eTitleFont,
        fontSize: '32px',
        fontWeight: 'bold',
    }
}

export interface Props {
    characterId: string,
}

export const DnD5eSheet: React.FC<Props> = (props: Props) => {
    //todo replace this with reducer
    const {characterId} = props;
    return (
        <CharacterContainer
            characterId={characterId}
        >
            <IntlProvider locale="en" messages={messages}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <DnD5eSheetContents/>
                    </ThemeProvider>
                </StyledEngineProvider>
            </IntlProvider>
        </CharacterContainer>
    );
}

const DnD5eSheetContents: React.FC = () => {
    const [state] = useContext(CharacterContext);
    const [rollOpen, setRollOpen] = useState(false);
    const [initializing, setInitializing] = useState(true);
    const channel = useCharacterId();
    const intl = useIntl();

    const {name} = state;

    useEffect(() => {
        if (initializing) {
            // @ts-ignore
            broadcastMessage(channel, "", {...state, serverLoadBroadCast: true});
            setInitializing(false);
        }
    }, [state, initializing, channel])

    if (!state) {
        return (<React.Fragment/>);
    }

    const diceAction = <DiceButton key="dice" setRollOpen={setRollOpen}/>;

    return (
        // @ts-ignore
        <Screen
            title={name}
            actions={[diceAction]}
            drawerItems={[<DnD5eDrawer key="vtm"/>]}
        >
            <DicePopop
                sectionTemplate={<SheetSection titleId="roll_dice"/>}
                font={fonts.dnd5eSheetFont}
                // @ts-ignore
                containerStyles={{padding: '8px'}}
                open={rollOpen}
                handleClose={() => setRollOpen(false)}
            />
            <Container style={styles.sheetContainer}>
                <div
                    style={styles.sheetHeader as React.CSSProperties}
                >
                    <Typography style={styles.nameText as React.CSSProperties}>
                        {intl.formatMessage({id: 'dnd5e'})}
                    </Typography>
                </div>
                <VitalInformation/>
                <AbilitiesAndSkills/>
                <CombatStats/>
                <Weapons/>
                <Attacks/>
                <Spellcasting/>
                <Spells/>
                <Motives/>
                <Features/>
                <Proficiencies/>
                <Equipment/>
                <Wealth/>

            </Container>
        </Screen>

    );
}

export default DnD5eSheet;