import React, {useContext} from 'react';
import {CharacterContext} from "../../../Characters/CharacterContainer";
import {computeModifiers} from "../../mathUtils";
import {CON, DEX, WIS} from '../../constants';
import SavingThrowInputRow from '../SavingThrowInputRow';
import SheetSection from '../Components/SheetSection';
import {Grid, Typography, useMediaQuery} from '@mui/material';
import {useIntl} from 'react-intl';
import {useTheme} from '@mui/styles';
import useInView from "@owaiswiz/use-in-view";

const saveDefs = [
    {name: 'fortitude', ability: CON},
    {name: 'reflex', ability: DEX},
    {name: 'will', ability: WIS},
];

const styles = {
    throwsTable: {
        tableLayout: 'fixed',
        width: '100%',
    },
};

function SavingThrowsTable(props) {

    const [character] = useContext(CharacterContext);
    const path = 'saving_throws';
    const intl = useIntl();
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));
    const modifiers = computeModifiers(character);
    const [ref, inView] = useInView();


    function renderThrowRow(definition) {
        const {name, ability} = definition;
        return <SavingThrowInputRow key={name} path={`${path}.${name}`}
                                    modifier={modifiers[ability]} {...definition}/>;
    }

    function renderThrowTable() {
        const headerIds = ['throw', 'total', 'savingBase', 'abilityMod', 'savingMisc'];
        return (
            <table style={styles.throwsTable}>
                <thead>
                <tr>
                    {headerIds.map((id) => <th key={id}><Typography>{intl.formatMessage({id})}</Typography></th>)}
                </tr>
                </thead>
                <tbody>
                {saveDefs.map((definition) => renderThrowRow(definition))}
                </tbody>
            </table>
        );
    }

    function renderThrowGrid() {
        return (
            <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                ref={ref}
            >
                {inView && saveDefs.map((definition) => {
                    const {name, ability} = definition;
                    return (
                        <Grid
                            key={name}
                            item
                            container
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Typography>
                                    {intl.formatMessage({id: name}, {ability})}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <table style={styles.throwsTable}>
                                    <tbody>
                                    {renderThrowRow(definition)}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    return (
        <SheetSection
            key="savingThrows"
            titleId="savingThrows"
        >
            <div
                style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
            >
                <Typography>
                    {intl.formatMessage({id: 'throwsTableKey'})}
                </Typography>

                {largeLayout && renderThrowTable()}
                {!largeLayout && renderThrowGrid()}
            </div>
        </SheetSection>
    );
}

export default SavingThrowsTable;