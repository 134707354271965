import React from 'react';
import SheetSection from "../../Components/SheetSection";
import TextBox from "../../../Components/Inputs/TextBox";


function Notes(props) {

    return (
        <SheetSection
            titleId="notes_header"
        >
        <TextBox path="notes" rows={20} maxLength={2048}/>
        </SheetSection>
    )
}

export default Notes;