import React from 'react';
import PropTypes from 'prop-types';
import SheetSection from "../../Components/SheetSection";
import TextBox from "../../../Components/Inputs/TextBox";


function History(props) {


    return (
        <SheetSection
            titleId="history_header"
        >
        <TextBox path="history" rows={20} maxLength={2048} />
        </SheetSection>
    )
}

History.propTypes = {
    current: PropTypes.string,
    setCurrent: PropTypes.func,
};

History.defaultProps = {
    current: "",
    setCurrent: () => {},
};

export default History;