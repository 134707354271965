import React from 'react';
import SheetSection from '../Components/SheetSection';
import LanguagesInput from '../LanguagesInput';

function Languages(props) {

    return (
        <SheetSection
            key="languages"
            titleId="languages"
        >
            <LanguagesInput path="languages"/>
        </SheetSection>
    );
}

export default Languages;