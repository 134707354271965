import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {initializeApp, getApp} from "firebase/app";
import {IntlProvider} from 'react-intl';
import {messages} from "./en";
import {FirebaseAppProvider, AuthProvider, FirestoreProvider} from "reactfire";
import {getEmulationCapableAuth, getEmulationCapableFirestore} from "./Firebase/initUtils";
import { getStripePayments } from "@stripe/firestore-stripe-payments";
import {CUSTOMERS_COLLECTION, PRODUCTS_COLLECTION} from "./Firebase/firebaseAccounts";




const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID
};
// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getEmulationCapableAuth();
export const firestore = getEmulationCapableFirestore();

// initialize stripe for updates and account info

export const payments = getStripePayments(getApp(), {
    productsCollection: PRODUCTS_COLLECTION,
    customersCollection: CUSTOMERS_COLLECTION,
});

ReactDOM.render(
    <React.StrictMode>
        <FirebaseAppProvider firebaseApp={firebaseApp}>
            <FirestoreProvider sdk={firestore}>
                <AuthProvider sdk={auth}>
                    <IntlProvider messages={messages} locale="en">
                        <App/>
                    </IntlProvider>
                </AuthProvider>
            </FirestoreProvider>
        </FirebaseAppProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();