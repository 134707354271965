import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem } from '@mui/material';

function NumSelect(props) {

    const {
        value,
        onChange,
        possibleValues,
        inputStyle,
    } = props;

    function handleChange(event) {
        const value = parseInt(event.target.value);
        onChange(value);
    }

    return (
        <Select
            style={inputStyle}
            value={value}
            onChange={handleChange}
        >
            {possibleValues.map((value) => <MenuItem style={inputStyle} key={value} value={value}>{value}</MenuItem>)}
        </Select>
    )
}

NumSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    inputStyle: PropTypes.any,
    possibleValues: PropTypes.arrayOf(PropTypes.number)
}

NumSelect.defaultProps = {
    value: 20,
    onChange: () => {},
    possibleValues: [20],
    inputStyle: {},
}

export default NumSelect;