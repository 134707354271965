import React, {useRef} from 'react';
//import _ from 'lodash';
import PropTypes from 'prop-types';
import {useIntl} from "react-intl";
import {TextField} from "@mui/material";
import {useCharacterId, useCharacterListener, useIsEditable} from '../../Characters/characterHooks';
import {broadcastMessage} from '../../MessageComponents/messageListeners';
import {INPUT_SAVE_DELAY_MILLIS} from '../../constants';
import {pathToCSSId} from '../../Utils/css';

let inputTimeout;

function NumberInput(props) {
    const {
        path,
        name,
        size,
        min,
        max,
        labelId,
        labelArgs,
        readOnly,
        readOnlyValue,
        dense,
        noLabel,
        variant,
        inputLabelStyle,
    } = props;

    const usedName = name ?? path;
    const usedLabelId = labelId || usedName;
    const intl = useIntl();
    const fieldRef = useRef();
    const isEditable = useIsEditable();
    const isReadOnly = !isEditable || readOnly;
    const channel = useCharacterId();
    const storedValue = useCharacterListener(usedName, path);

    function onInputChange(event) {
        const newValue = parseInt(event.target.value);
        if (!isNaN(newValue) && newValue !== storedValue) {
            clearTimeout(inputTimeout);
            inputTimeout = setTimeout(() => {
                broadcastMessage(channel, path, newValue);
            }, INPUT_SAVE_DELAY_MILLIS);
        }
    }

    const labelProps = noLabel ? {} : {label: intl.formatMessage({id: usedLabelId}, labelArgs)};
    const readOnlyProps = readOnly? {value: readOnlyValue} : {};
    const margin = dense ? 'dense' : 'normal';
    return (
        <div>
            <TextField
                id={pathToCSSId(path)}
                style={{
                    margin: 0,
                    padding: 0,
                }}
                {...readOnlyProps}
                {...labelProps}
                defaultValue={storedValue}
                type="number"
                onBlur={onInputChange}
                InputLabelProps={{style: inputLabelStyle, shrink: true}}
                inputProps={{size, min, max, style: {width: `${size-1}rem`}, readOnly: isReadOnly, ref: fieldRef}}
                variant={variant}
                margin={margin}
                disabled={isReadOnly}
            />
        </div>
    );
}

NumberInput.propTypes = {
    size: PropTypes.number,
    name: PropTypes.string,
    path: PropTypes.string,
    labelId: PropTypes.string,
    labelArgs: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
    readOnly: PropTypes.bool,
    dense: PropTypes.bool,
    noLabel: PropTypes.bool,
    variant: PropTypes.string,
    inputLabelStyle: PropTypes.object,
};

NumberInput.defaultProps = {
    name: undefined,
    path: 'fakepath',
    size: 3,
    min: 0,
    max: 99,
    readOnly: false,
    dense: false,
    labelId: undefined,
    labelArgs: {},
    noLable: false,
    variant: "outlined",
    inputLabelStyle: {}
};

export default NumberInput;

