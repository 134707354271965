import React from 'react';
import SheetSection from '../Components/SheetSection';
import CreditsInput from '../CreditsInput';
import OtherWealthInput from '../OtherWealthInput';
import Grid from '@mui/material/Grid';

function Wealth(props) {


    return (
        <SheetSection
            key="wealth"
            titleId="wealth"
        >
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                >
                    <Grid
                        item
                    >
                        <CreditsInput path="credits"/>

                    </Grid>

                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <OtherWealthInput path="other_wealth"/>
                    </Grid>
                </Grid>
            </Grid>
        </SheetSection>
    );
}

export default Wealth;