import React, {useContext} from 'react';
import Grid from "@mui/material/Grid";
import SkillInputRow from "../SkillInputRow";
import ProfessionSkillInputRow from "../ProfessionSkillInputRow";
import {CharacterContext} from "../../../Characters/CharacterContainer";
import {computeModifiers} from "../../mathUtils";
import {useTheme} from '@mui/material/styles';
import {Typography, useMediaQuery} from "@mui/material";
import {useIntl} from "react-intl";
import {DEX, STR, INT, CHA, WIS} from '../../constants';
import SheetSection from '../Components/SheetSection';
import SkillNotesInput from '../SkillNotesInput';
import RanksPerLevelInput from '../RanksPerLevelInput';
import RanksUsedDisplay from "../RanksUsedDisplay";
import useInView from "@owaiswiz/use-in-view";

const skillDefs = [
    {name: 'Acrobatics', ability: DEX, armorPenalty: true},
    {name: 'Athletics', ability: STR, armorPenalty: true},
    {name: 'Bluff', ability: CHA},
    {name: 'Computers', ability: INT, trained: true},
    {name: 'Culture', ability: INT, trained: true},
    {name: 'Diplomacy', ability: CHA},
    {name: 'Disguise', ability: CHA},
    {name: 'Engineering', ability: INT, trained: true},
    {name: 'Intimidate', ability: CHA},
    {name: 'Life Science', ability: INT, trained: true},
    {name: 'Medicine', ability: INT, trained: true},
    {name: 'Mysticism', ability: WIS, trained: true},
    {name: 'Perception', ability: WIS},
    {name: 'Physical Science', ability: INT, trained: true},
    {profession: true, key: 'p1', name: 'p1'},
    {profession: true, key: 'p2', name: 'p2'},
    {name: 'Piloting', ability: DEX},
    {name: 'Sense Motive', ability: WIS},
    {name: 'Slight of Hand', ability: DEX, trained: true, armorPenalty: true},
    {name: 'Stealth', ability: DEX},
    {name: 'Survival', ability: WIS},
];


function SkillsTable(props) {

    const [character] = useContext(CharacterContext);
    //console.dir(character);
    const path = 'skills';
    const intl = useIntl();
    const theme = useTheme();
    const [ref, inView] = useInView();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));
    const modifiers = computeModifiers(character);

    const styles = {
        smallSkillContainer: {
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
        },
        ranksSpacer: {
            flexGrow: 1,
        },
        skillsTable: {
            tableLayout: 'fixed',
            width: '100%',
        },
        ranksDescription: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1),
        },
        skillGridEntry: {
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        }

    }

    function getSkillRow(definition) {
        const {name, ability, key} = definition;
        const rowPath = `${path}.${name}`;
        if (definition.profession) {
            return <ProfessionSkillInputRow largeLayout={largeLayout} key={key} path={rowPath} {...definition}/>;
        }
        return <SkillInputRow largeLayout={largeLayout} modifier={modifiers[ability]} key={name}
                              path={rowPath} {...definition} />;
    }

    const headerLabels = ['skillName', 'total', 'skillRanks', 'skillClass', 'abilityMod', 'skillMisc'];


    function getSkillTable() {
        return (
            <table style={styles.skillsTable}>
                <thead>
                <tr>
                    {headerLabels.map((labelId) => (
                        <th key={labelId}><Typography>{intl.formatMessage({id: labelId})}</Typography></th>))}
                </tr>
                </thead>
                <tbody>
                {skillDefs.map((definition) => {
                        const key = (definition.key) ? definition.key : definition.name;
                        return (
                            <tr key={key}>
                                {getSkillRow(definition)}
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>);
    }

    function getSkillsGrid() {
        return (
            <div style={styles.smallSkillContainer}>
                {skillDefs.map((definition) => {
                    const {name, key, profession, ability} = definition;
                    if (profession) {
                        return <ProfessionSkillInputRow key={key} path={`${path}.${name}`} {...definition}/>;
                    }
                    const itemKey = key || name;
                    const label = intl.formatMessage({id: name.toLowerCase()}, {ability});
                    return (
                        <div
                            key={itemKey}
                            style={styles.skillGridEntry}
                        >
                            <Typography>
                                {label}
                            </Typography>
                            <table style={styles.skillsTable}>
                                <tbody>
                                <tr>
                                    {getSkillRow(definition)}
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    );
               })}
            </div>
        );
    }


    const ranksDescription = (
        <div
            style={styles.ranksDescription}
        >
            <RanksPerLevelInput path={path}/>
            <div style={styles.ranksSpacer}/>
            <RanksUsedDisplay path={path}/>
        </div>
    );


    return (
        <SheetSection
            key="skillsTable"
            titleId="skillsTable"
            action={ranksDescription}
        >
            <div
                ref={ref}
                style={{display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
            >
                <Typography>
                    {intl.formatMessage({id: 'skill_table_key'})}
                </Typography>
                {inView && largeLayout && getSkillTable()}
                {inView && !largeLayout && getSkillsGrid()}
                <Grid
                    item
                    container
                    key="footnotes"
                    xs={12}
                >
                    <Typography
                        align="left">{intl.formatMessage({id: 'trainedOnly'})}<br/>{intl.formatMessage({id: 'armorCheckPenaltyApplies'})}
                    </Typography>
                </Grid>
                <Grid
                    item
                    key="skillnotes"
                    container
                    justifyContent="flex-start"
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <SkillNotesInput path={path}/>
                    </Grid>
                </Grid>
            </div>
        </SheetSection>
    );
}

export default SkillsTable;