/**
 * Similar to characters the account store is our in ram copy of the user's
 * account
 */
import React, {createContext, useEffect, useState} from 'react';
import {listenForYourAccount} from "../Firebase/firebaseAccounts";

const AccountStore = createContext({});

function AccountStoreContainer(props) {

    const { children } = props;
    const [initializing, setInitializing] = useState(true);
    const [state, setState] = useState(null ); //singleton
    // set up sync from firestore to ram
    useEffect(() => {
        if (initializing) {
            // make our connection to firebase
            listenForYourAccount((doc) => {
               setState(doc.data());
            });
            setInitializing(false);
        }
    }, [initializing]);

    return (
        <AccountStore.Provider value={[state, setState]}>
            {children}
        </AccountStore.Provider>
    )
}

export { AccountStore, AccountStoreContainer};