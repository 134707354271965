import * as React from 'react';
import SheetSection from "../../Components/SheetSection";
import {Typography} from "@mui/material";
import DnD5eNumberInput from "../../Components/DnD5eNumberInput";
import {useIntl} from "react-intl";
import {useCharacterObjectListener} from "../../../Characters/characterHooks";
import {fonts} from "../../../appConstants";
import CheckInput from "../../../Components/Inputs/CheckInput";

export interface Props {
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    profContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
    },
    abilitySection: {
        border: '1px dashed grey',
        borderRadius: 10,
        margin: 8,
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    inputContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    abilityScoreInput: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    abilitiesContainer: {
        display: 'flex',
        flexWrap: 'wrap',

        justifyContent: 'space-evenly',
    },
    skillList: {
        marginLeft: 16,
    },
    abilityLabel: {
        fontFamily: fonts.dnd5eTitleFont,
        marginBottom: '16px',
        fontWeight: 'bold',
    },
    scoreBox: {
        marginBottom: '8px',
    },
    skillContainer: {
        display: 'flex',
        alignItems: 'center',
    }
}

const AbilitiesAndSkills: React.FC<Props> = (props) => {

    const skillPath = 'skills';
    const abilitiesPath = 'abilities';
    const intl = useIntl();


    const abilitySkills = {
        strength: [
            'str_saving_throws',
            'athletics'
        ],
        dexterity: [
            'dex_saving_throws',
            'acrobatics',
            'slight_of_hand',
            'stealth',
        ],
        constitution: ['con_saving_throws'],

        intelligence: [
            'int_saving_throws',
            'arcana',
            'history',
            'investigation',
            'nature',
            'religion'
        ],

        wisdom: [
            'wis_saving_throws',
            'animal_handling',
            'insight',
            'medicine',
            'perception',
            'survival',
        ],

        charisma: [
            'cha_saving_throws',
            'deception',
            'intimidation',
            'performance',
            'persuasion'
        ]
    }

    // repeated because we get a nice sort from it
    const abilityList = [
        'strength',
        'dexterity',
        'constitution',
        'intelligence',
        'wisdom',
        'charisma',
    ];

    const abilities = useCharacterObjectListener("abilitieslistener", "abilities", {});
    const skillVals = useCharacterObjectListener("abilitieskillslistener", "skills", {});

    // @ts-ignore
    const profBonus = abilities['proficiency_bonus'] ?? 0;

    function renderPassivePerception(modifier: number): React.ReactElement {
        // @ts-ignore
        const perception = skillVals.perception ?? false;
        const passiveBonus = perception? profBonus : 0;
        const value = 10 + modifier + passiveBonus;
        return (
                <Typography>
                    Passive Perception: {value}
                </Typography>
        );
    }


    function renderAbility(ability: string): React.ReactElement {
        // @ts-ignore
        const skills = abilitySkills[ability] ?? [];
        // @ts-ignore
        const score = abilities[ability] ?? 0;
        const modifier = Math.floor((score - 10) / 2);
        return (
            <div style={styles.abilitySection as React.CSSProperties}
                 key={ability}>
                <Typography style={styles.abilityLabel as React.CSSProperties}>
                    {intl.formatMessage({id: ability})}
                </Typography>
                <div style={styles.inputContainer as React.CSSProperties}>
                    <div style={styles.abilityScoreInput as React.CSSProperties}>
                        <div style={styles.scoreBox}>
                            <DnD5eNumberInput
                                path={`${abilitiesPath}.${ability}`}
                                labelId="score"
                                size={4}
                            />
                        </div>
                        <div style={styles.scoreBox}>
                            <DnD5eNumberInput
                                path={`${abilitiesPath}.${ability}`}
                                labelId="mod"
                                readOnly={true}
                                readOnlyValue={modifier}
                                size={4}
                            />
                        </div>
                    </div>
                    <div style={styles.skillList as React.CSSProperties}>
                        {skills.map((skill: string) => {
                            // @ts-ignore
                            const proficient = skillVals[skill] === true;
                            const skillTotal = modifier + (proficient? profBonus : 0);
                            return (
                                <div
                                    key={skill}
                                    style={styles.skillContainer}
                                >
                                    <CheckInput
                                        path={`${skillPath}.${skill}`}
                                    />
                                    <Typography>
                                        {intl.formatMessage({id: skill})}: {skillTotal}
                                    </Typography>
                                </div>
                            )
                        })}
                    </div>
                </div>
                {ability === 'wisdom' && renderPassivePerception(modifier)}
            </div>
        );
    }

    const renderedAbilities = abilityList.map((ability) => renderAbility(ability));
    return (
        <SheetSection titleId="abilities_and_skills">
            <div style={styles.container as React.CSSProperties}>
                <div style={styles.profContainer as React.CSSProperties}>
                    <DnD5eNumberInput
                        labelOnSide
                        size={4}
                        path={`${abilitiesPath}.proficiency_bonus`}
                        labelId="proficiency_bonus"/>
                    <DnD5eNumberInput
                        labelOnSide
                        size={4}
                        path={`${abilitiesPath}.inspiration`}
                        labelId="inspiration"/>
                </div>
                <div style={styles.abilitiesContainer as React.CSSProperties}>
                    {renderedAbilities}
                </div>
            </div>
        </SheetSection>
    );

}

export default AbilitiesAndSkills;