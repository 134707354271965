import React from 'react';
import {Grid, Typography} from "@mui/material";
import SheetSection from "../../Components/SheetSection";
import {useIntl} from "react-intl";
import NumberInput from "../../../Components/Inputs/NumberInput";
import VtMSlider from "../VtMSlider";


function Blood(props) {

    const intl = useIntl();
    const bloodFields = [
        'blood_surge', 'mend_amount', 'power_bonus',
        'rouse_reroll', 'feeding_penalty', 'bane_severity'
    ];

    const styles = {
        potencyRoot: {
            alignItems: 'center',
            display: 'flex',
            marginBottom: 20,
        },
        potencySlider: {
            marginLeft: '10px',
        }
    }

    return (
        <SheetSection
            key="blood"
            titleId="blood"
        >
            <Grid
                container
                justifyContent="center"
                style={styles.potencyRoot}
            >
                <Grid
                    item
                    container
                    justifyContent="center"
                    xs={12}
                >
                    <Typography
                        variant="h6"
                    >
                        {intl.formatMessage({id: 'blood_potency'})}
                    </Typography>
                    <div style={styles.potencySlider}>
                        <VtMSlider
                            max={10}
                            path="blood_potency"
                         />
                    </div>
                </Grid>
            </Grid>
            <Grid
                container
                justifyContent="center"
                spacing={2}
            >
                {bloodFields.map((field) => {
                    return (
                        <Grid
                            item
                            key={field}
                            xs={3}
                            sm={3}
                            md={2}
                        >
                            <NumberInput
                                labelId={field}
                                min={0}
                                max={10}
                                path={field}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </SheetSection>
    )
}


export default Blood;

