import React, {useContext} from 'react';
import {CharacterContext} from '../Characters/CharacterContainer';
import {computeAbilityModifier} from './mathUtils';
import {DEX} from './constants';
import {Typography} from '@mui/material';
import {useIntl} from 'react-intl';


function AcVsCombat(props) {
    const intl = useIntl();

    const [character] = useContext(CharacterContext);

    const dexModifier = computeAbilityModifier(character, DEX);
    const kacData = character.kinetic_armor_class || {};
    const kac = 10 + (kacData.armor || 0) + (kacData.misc || 0) + dexModifier;
    const value = 8 + kac;

    return (
        <Typography>
            {intl.formatMessage({ id: 'combatManeuvers'}, {value})}
        </Typography>
    );
}

export default AcVsCombat;