import React from 'react';
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import {fonts} from "../appConstants";
import _ from "lodash";
import Screen from "../Components/Screen";
import {HomeSection} from "./HomeSection";
import {Container} from "@mui/material";
import {useActiveCharacters, useRetiredCharacters} from "../Characters/characterHooks";
import CharacterList from "../Characters/CharacterList";
import AddCharOrUpgradeButton from "./AddCharOrUpgradeButton";

export const theme = createTheme(adaptV4Theme({
    typography: {
        fontFamily: fonts.appFont,
        fontWeightRegular: 'bold',
    },
}));

function Home(props) {

    const activeCharacters = useActiveCharacters();
    const retiredCharacters = useRetiredCharacters();

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Screen
                    isHome
                >
                    <Container>
                        <HomeSection
                            titleId="yourCharacters"
                            actionButton={<AddCharOrUpgradeButton/>}
                        >
                            <CharacterList characters={activeCharacters}/>
                        </HomeSection>
                        {!_.isEmpty(retiredCharacters) && (
                            <HomeSection
                                titleId="retiredCharacters"
                            >
                                <CharacterList characters={retiredCharacters}/>
                            </HomeSection>
                        )}
                    </Container>
                </Screen>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default Home