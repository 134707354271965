import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import TextBox from '../../Components/Inputs/TextBox';
import StarfinderNumberInput from "./Components/StarfinderNumberInput";

function SpellRankInput(props) {

    const {
        path,
        sortOrder,
    } = props;
    const intl = useIntl();

    function getField(name) {
        return (
            <StarfinderNumberInput
                labelId={'spells' + name.toLowerCase()}
                labelOnSide
                min={0}
                max={40}
                path={`${path}.${name}`}
                size={5}
            />
        );
    }

    const rank0 = sortOrder === 0;

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
        >
            <Grid
                container
                spacing={2}
                item
                xs={12}
            >

                <Grid
                    item>
                    <Typography>{intl.formatMessage({id: 'spellsRank'}, {rank: sortOrder})}</Typography>
                </Grid>
                <Grid
                    item
                >
                    {getField('known')}
                </Grid>
                {!rank0 && <Grid
                    item
                >
                    {getField('per_day')}
                </Grid>}
                {!rank0 && <Grid
                    item
                >
                    {getField('slots_used')}
                </Grid>}
            </Grid>
            <Grid
                item
                xs={12}
            >
                <TextBox labelId="spells"
                         path={`${path}.spell_list`}
                         maxLength={300} rows={6}/>
            </Grid>
        </Grid>
    );
}

SpellRankInput.propTypes = {
    path: PropTypes.string.isRequired,
    sortOrder: PropTypes.number,
};

SpellRankInput.defaultProps = {
    sortOrder: 0,
};


export default SpellRankInput;