import * as React from 'react';
import SheetSection from "../../Components/SheetSection";
import ListSection from "../../../Components/ListSection";
import AttackInput from "../AttackInput";
import DnD5eAddButton from "../../Components/DnD5eAddButton";


export const Attacks: React.FC = () => {


    const inputPrototype = AttackInput;
    const path = 'attacks';

    return (
        <ListSection
            titleId="attacks"
            path={path}
            inputPrototype={inputPrototype}
            sheetSection={SheetSection}
            addButton={<DnD5eAddButton addLabelId="add_attack" path={path}/>}
        />
    );
}

export default Attacks;