import * as React from 'react';
import _ from 'lodash';
import CharacterSummary from "./CharacterSummary";
import {Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {Character} from "../types";

const styles = {
    characterContainer: {
        width: 350,
        margin: '8px',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    }
};


export interface Props {
    characters: Array<Character>
}


export const CharacterList: React.FC<Props> = (props) => {
    const intl = useIntl();
    const {characters} = props;
    // most recently updated first
    const sorted = _.reverse(_.sortBy(characters, ['updatedAt']));

    function renderChar(character: Character): React.ReactNode {
        const {id} = character;
        return (
            <div key={id} style={styles.characterContainer}>
                <CharacterSummary character={character}/>
            </div>
        );
    }

    if (sorted.length === 0) {
        return (
            <div>
                <Typography>
                    {intl.formatMessage({id: 'noCharacters'})}
                </Typography>
            </div>
        )
    }

    return (
        <div style={styles.container as React.CSSProperties}>
                {sorted.map((char) => renderChar(char))}
        </div>
    );
}

export default CharacterList;