import React, {useContext, useEffect, useState} from 'react';
import '../App.css';
import './Starfinder.css';
import {messages} from "./en";
import SkillsTable from "./Inputs/Sections/SkillsTable";
import {CharacterContainer, CharacterContext} from "../Characters/CharacterContainer";
import Abilities from "./Inputs/Sections/Abilities";
import Feats from "./Inputs/Sections/Feats";
import Weapons from './Inputs/Sections/Weapons';
import SavingThrowsTable from './Inputs/Sections/SavingThrowsTable';
import AttackBonusesTable from './Inputs/Sections/AttackBonusesTable';
import {Container, IconButton, Tooltip} from '@mui/material';
import Spells from './Inputs/Sections/Spells';
import VitalInformation from './Inputs/Sections/VitalInformation';
import Health from './Inputs/Sections/Health';
import AbilitiesTable from './Inputs/Sections/AbilitiesTable';
import ArmorClasses from './Inputs/Sections/ArmorClasses';
import Experience from './Inputs/Sections/Experience';
import Initiative from './Inputs/Sections/Initiative';
import Wealth from './Inputs/Sections/Wealth';
import Languages from './Inputs/Sections/Languages';
import Equipment from './Inputs/Sections/Equipment';
import Typography from '@mui/material/Typography';
import {IntlProvider, useIntl} from 'react-intl';
import Screen from '../Components/Screen';
import StarfinderDrawer from "./StarfinderDrawer";
import {useCharacterId} from "../Characters/characterHooks";
import {broadcastMessage} from "../MessageComponents/messageListeners";
import {FlashOn, FlashOff} from "@mui/icons-material";
import {createTheme, ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import {fonts} from "../appConstants";
import DicePopop from "../Components/DicePopop";
import CasinoIcon from "@mui/icons-material/Casino";
import SheetSection from "./Inputs/Components/SheetSection";


const theme = createTheme({
    typography: {
        fontFamily: fonts.starfinderSheetFont,
        fontWeightRegular: 'bold',
    },
});

function StarfinderSheet(props) {
    const {characterId} = props;
    return (
        <CharacterContainer
            characterId={characterId}
        >
            <IntlProvider locale="en" messages={messages}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <StarfinderSheetContents/>
                    </ThemeProvider>
                </StyledEngineProvider>
            </IntlProvider>
        </CharacterContainer>
    );
}


function StarfinderSheetContents(props) {
    const [character] = useContext(CharacterContext);
    const intl = useIntl();
    const [initializing, setInitializing] = useState(true);
    const [combatMode, setCombatMode] = useState(false);
    const [rollOpen, setRollOpen] = useState(false);

    const channel = useCharacterId();
    useEffect(() => {
        if (initializing) {
            broadcastMessage(channel, "", {...character, serverLoadBroadCast: true});
            setInitializing(false);
        }
    }, [character, initializing, channel]);

    if (!character) {
        return (<React.Fragment/>);
    }

    const peaceFields = (
        <>
            <VitalInformation/>
            <Health/>
            <AbilitiesTable/>
            <Initiative/>
            <ArmorClasses/>
            <SkillsTable/>
            <SavingThrowsTable/>
            <AttackBonusesTable/>
            <Abilities/>
            <Feats/>
            <Spells/>
            <Weapons/>
            <Equipment/>
            <Experience/>
            <Wealth/>
            <Languages/>
        </>
    );

    const combatFields = (
        <>
            <AbilitiesTable/>
            <Health/>
            <Initiative/>
            <SavingThrowsTable/>
            <AttackBonusesTable/>
            <Spells/>
            <Weapons/>
        </>
    );

    function renderCombat() {
        if (combatMode)
            return (
                <Tooltip key="leaveCombat" title={intl.formatMessage({id: 'leaveCombat'})}>
                    <IconButton color="inherit" onClick={() => setCombatMode(!combatMode)} size="large">
                        <FlashOff/>
                    </IconButton>
                </Tooltip>
            );
        return (
            <Tooltip key="enterCombat" title={intl.formatMessage({id: 'enterCombat'})}>
                <IconButton color="inherit" onClick={() => setCombatMode(!combatMode)} size="large">
                    <FlashOn/>
                </IconButton>
            </Tooltip>
        );
    }
    const diceAction = (
        <IconButton
            key="dice"
            color="inherit"
            aria-label="roll"
            edge="start"
            onClick={() => setRollOpen(true)}
            size="large">
            <CasinoIcon/>
        </IconButton>
    );

    const headerActions = [
        diceAction,
        renderCombat()
    ]

    // console.error(state);
    // console.error(firebase.auth().currentUser.uid);
    return (
        <Screen
            drawerItems={[<StarfinderDrawer key="sfd" combatMode={combatMode}/>]}
            drawerHeader={<Typography align="left">{intl.formatMessage({id: 'shortcuts'})}</Typography>}
            title={character.name}
            actions={headerActions}
        >
            <DicePopop
                sectionTemplate={<SheetSection titleId="rollDice"/>}
                font={fonts.starfinderSheetFont}
                open={rollOpen}
                handleClose={() => setRollOpen(false)}
            />
            <Container style={{border: '1px solid black', borderRadius: 6, textTransform: 'upperCase'}}>
                <Typography
                    align="center"
                    variant="h4"
                    style={{
                        color: '#1c4e77',
                        fontWeight: 'bold',
                        fontFamily: [fonts.starfinderTitleFont, 'serif'],
                    }}
                >
                    {intl.formatMessage({id: 'pageHeader'})}
                </Typography>
                {combatMode && (<FlashOn style={{width: 100, height: 100}}/>)}
                {combatMode && combatFields}
                {!combatMode && peaceFields}
            </Container>
        </Screen>
    );
}

export default StarfinderSheet;
