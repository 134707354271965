import TextInput from "../../../Components/Inputs/TextInput";

const textFieldStyle = {
    margin: 0,
    padding: 0,
};
const inputLabelStyle = {};

const StarfinderTextInput: React.FC = (props) => {

    return (
        <TextInput {...props} style={textFieldStyle} inputLabelStyle={inputLabelStyle}/>
    )
}

export default StarfinderTextInput;