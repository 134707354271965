import * as React from 'react';
import _ from 'lodash';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ListItemIcon, Typography} from "@mui/material";


export interface CharSummaryMoreItem {
    onClick: () => void;
    name: string,
    icon: React.ReactNode,
}
export interface Props {
    items: Array<CharSummaryMoreItem>;
}

export const CharacterSummaryMore: React.FC<Props> = (props) => {
    const {items} = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    // @ts-ignore
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    if (_.isEmpty(items)){
        return null;
    }

    return (
        <div>
            <IconButton
                id="moreIcon"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                >
                {items.map((item: CharSummaryMoreItem) => {
                    return (
                        <MenuItem
                            key={item.name}
                            onClick={item.onClick}
                            >
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <Typography>
                                {item.name}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}

export default CharacterSummaryMore;