import React, {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {CharacterContext} from '../../Characters/CharacterContainer';
import NumberInput from '../../Components/Inputs/NumberInput';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {computeAbilityModifier} from '../mathUtils';
import {CHA, INT, WIS} from '../constants';
import {makeStyles, useTheme} from '@mui/styles';
import {useCharacterId, useCharacterObjectListener} from "../../Characters/characterHooks";
import {broadcastMessage} from "../../MessageComponents/messageListeners";
import {pathToCSSId} from "../../Utils/css";
import StarfinderTextInput from "./Components/StarfinderTextInput";

const rowStyles = makeStyles((theme) => {
    return {
        smallSkillContainer: {
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        smallSkillHeader: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        smallSkill: {
            marginLeft: 'auto',
            marginRight: 'auto',
            display: 'block',
        },
    };
});

function ProfessionSkillInputRow(props) {
    const {
        path,
        armorPenalty,
        largeLayout
    } = props;

    const intl = useIntl();
    const [character] = useContext(CharacterContext);
    const theme = useTheme();
    const rowClasses = rowStyles(theme);

    const hasPenalty = armorPenalty && character.armor_check_penalty !== 0;
    const armorCheckPenalty = hasPenalty ? character.armor_check_penalty : 0;
    const channel = useCharacterId();
    const current = useCharacterObjectListener(path, path);
    const [selectedAbility, setSelectedAbility] = useState(current.selected_ability);
    const safeRanks = current.ranks ?? 0;
    const safeClass = current.class ?? 0;
    const safeMisc = current.misc ?? 0;
    const modifier = selectedAbility ? computeAbilityModifier(character, selectedAbility) : 0;
    const total = safeRanks === 0? 0 : (safeRanks + safeClass + safeMisc + modifier) - armorCheckPenalty;

    const groupPath = `${path}.selected_ability`;

    function renderAbilitySelectors() {
        function radioChange(event) {
            const {value} = event.target;
            setSelectedAbility(value);
            broadcastMessage(channel, groupPath, value);
        }

        return (
            <RadioGroup
                id={groupPath}
                row
                value={selectedAbility}
                onChange={radioChange}
            >
                <FormControlLabel value={CHA} control={<Radio id={pathToCSSId(`${groupPath}.cha`)}/>} label={intl.formatMessage({id: 'CHA'})}/>
                <FormControlLabel value={INT} control={<Radio id={pathToCSSId(`${groupPath}.int`)}/>} label={intl.formatMessage({id: 'INT'})}/>
                <FormControlLabel value={WIS} control={<Radio id={pathToCSSId(`${groupPath}.wis`)}/>} label={intl.formatMessage({id: 'WIS'})}/>
            </RadioGroup>
        );
    }

    const variantProps = largeLayout ? {} : {variant: 'standard'};

    function renderRow() {
        return (
            <React.Fragment>
                {largeLayout && (<td
                    >
                        <StarfinderTextInput
                            labelId="profession"
                            path={`${path}.name`}
                            size={15}
                        />
                        {renderAbilitySelectors()}
                    </td>
                )}
                <td
                >
                    <NumberInput
                        noLabel={largeLayout}
                        labelId="total"
                        {...variantProps}
                        min={-10}
                        max={40}
                        size={4}
                        path={`${path}.total`}
                        readOnlyValue={total}
                        readOnly
                    />
                </td>
                <td
                >
                    <NumberInput
                        noLabel={largeLayout}
                        labelId="skillRanks"
                        {...variantProps}
                        min={0}
                        max={40}
                        size={4}
                        path={`${path}.ranks`}
                    />
                </td>
                <td
                >
                    <NumberInput
                        noLabel={largeLayout}
                        {...variantProps}
                        labelId="skillClass"
                        min={0}
                        max={40}
                        size={4}
                        path={`${path}.class`}
                    />
                </td>
                <td
                >
                    <NumberInput
                        noLabel={largeLayout}
                        {...variantProps}
                        labelId="abilityMod"
                        min={-10}
                        max={40}
                        size={4}
                        readOnly
                        readOnlyValue={modifier}
                    />
                </td>
                <td
                >
                    <NumberInput
                        noLabel={largeLayout}
                        {...variantProps}
                        labelId="skillMisc"
                        min={0}
                        max={40}
                        size={4}
                        path={`${path}.misc`}
                    />
                </td>
            </React.Fragment>
        );
    }

    // render the small version which fits in a single column
    if (!largeLayout) {
        return (
            <div className={rowClasses.smallSkillContainer}>
                <div className={rowClasses.smallSkillHeader}>
                    <StarfinderTextInput
                        labelId="profession"
                        path={`${path}.name`}
                        size={15}
                    />
                    {renderAbilitySelectors()}
                    <table style={{
                        tableLayout: 'fixed',
                        width: '100%'
                    }}
                    >
                        <tbody>
                        <tr>
                            {renderRow()}
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
    // just render the row if we're in a big container
    return renderRow();


}

export default ProfessionSkillInputRow;