import React from 'react';
import SheetSection from '../Components/SheetSection';
import NameInput from '../NameInput';
import ClassAndLevelInput from '../ClassAndLevelInput';
import RaceInput from '../RaceInput';
import GenderInput from '../GenderInput';
import AlignmentInput from '../AlignmentInput';
import DeityInput from '../DeityInput';
import ThemeInput from '../ThemeInput';
import SpeedInput from '../SpeedInput';
import DescriptionInput from '../DescriptionInput';
import SizeInput from '../SizeInput';
import {Grid, useMediaQuery, useTheme} from '@mui/material';


function VitalInformation(props) {
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <SheetSection
            key="vitalInformation"
            titleId="vitalInformation"
        >
            <Grid
                container
                spacing={2}
            >

                <Grid
                    item
                >
                    <NameInput size={largeLayout? 40: 32} path="name"/>
                </Grid>
                <Grid
                    item
                >
                    <RaceInput path="race"/>
                </Grid>
                <Grid
                    item
                >
                    <GenderInput path="gender"/>
                </Grid>


                <Grid
                    item
                >
                    <SpeedInput path="speed"/>
                </Grid>
                <Grid
                    item
                >
                    <SizeInput path="size"/>
                </Grid>

                <Grid
                    item
                >
                    <ClassAndLevelInput path="class_level"/>
                </Grid>
                <Grid
                    item
                >
                    <ThemeInput path="theme"/>
                </Grid>
                <Grid
                    item
                >
                    <AlignmentInput path="alignment"/>
                </Grid>
                <Grid
                    item
                >
                    <DeityInput path="deity"/>
                </Grid>

                <Grid
                    item
                >

                </Grid>

                <Grid
                    item
                    xs={12}
                >
                    <DescriptionInput path="description"/>
                </Grid>
            </Grid>
        </SheetSection>
    );
}

export default VitalInformation;