import React, {useContext, useState} from 'react';
import _ from 'lodash';
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {CharacterStore} from "./CharacterStore";
import {registerListener, registerPathListener} from "../MessageComponents/messageListeners";
import {saveCharacter} from "../Firebase/firebaseCharacters";
import {dirtyUpdateChar} from "./characterStoreReducer";
import App404 from "../App404";
import {fonts} from "../appConstants";
import {useIntl} from "react-intl";

const CharacterContext = React.createContext([]);
let loadTimer = null;

const styles = {
    loadingContainer: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    spinnerContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    loadingText: {
        fontFamily: fonts.logoFont,
        marginRight: '8px',
    }
};

function CharacterContainer(props) {
    const {children} = props;
    const {characterId} = useParams();
    const safeId = characterId || 'NO_CHAR'
    const [allCharacters, charDispatch] = useContext(CharacterStore);
    const [is404, setIs404] = useState(false);


    const intl = useIntl();

    const character = allCharacters[safeId] ?? {};
    // register the listener for updating firebase
    registerPathListener(characterId, "char container path root", (path, update) => {
        if (!update.serverLoadBroadCast && path != null && path !== "") {
            // both firebase and we use dot notation for our keys, so this will just update whats needed
            // this is the only place we'll write character updates to firestore since it's
            // the only place with the path, AND the update
            // note this will trigger an update, since it's a diff doc
            saveCharacter(safeId, {[path]: update})
        }
    });
    // register a listener for updating our ram state (will be overwritten by firebase when it loads)
    registerListener(characterId, "char container main root", "", (update) => {
        if (!update.serverLoadBroadCast) {
            const clone = {...character};
            const merged = _.merge(clone, update);
            const updatedChar = {...merged, id: safeId, dirty: true};
            charDispatch(dirtyUpdateChar(updatedChar));
        }
    });

    if (!_.isEmpty(character)) {
        if (is404) {
            setIs404(false);
        }
        if (loadTimer != null) {
            clearTimeout(loadTimer)
            loadTimer = null;
        }
    }

    if (is404) {
        return <App404/>;
    }

    if (_.isEmpty(character)) {
        loadTimer = setTimeout(() => {
            clearTimeout(loadTimer);
            setIs404(true);
        }, 5000);
        return (
            <div style={styles.loadingContainer}>
                <div style={styles.spinnerContainer}>
                    <div style={styles.loadingText}>
                        {intl.formatMessage({id: 'loadingCharacter'})}
                    </div>
                    <CircularProgress size={120}/>
                </div>
            </div>
        );
    }

    return (
        <CharacterContext.Provider value={[character]}>
            {character && children}
        </CharacterContext.Provider>
    );
}


export {CharacterContext, CharacterContainer}