import React from 'react';
import HealthAndResolveInput from './Components/HealthAndResolveInput';


function ResolveInput(props) {

    return (
        <HealthAndResolveInput {...props}/>
    );
}

export default ResolveInput;