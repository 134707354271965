import React from 'react';
import PropTypes from 'prop-types';
import {Typography, useTheme} from "@mui/material";
import {useIntl} from "react-intl";
import TextBox from '../../Components/Inputs/TextBox';
import DnD5eNumberInput from "../Components/DnD5eNumberInput";

function SpellLevelInput(props) {

    const {
        path,
        sortOrder,
    } = props;
    const intl = useIntl();
    const theme = useTheme();

    const styles = {
        container: {
            display: 'flex',
            padding: 8,
            marginTop: 8,
            marginBottom: 8,
            flexDirection: 'column',
            width: '100%',
            border: '1px dashed',
            borderRadius: 10,
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        },
        slotContainer: {
            display: 'flex',
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        }
    }


    function getField(name) {
        return (
            <DnD5eNumberInput
                labelOnSide
                labelId={name}
                min={0}
                max={40}
                path={`${path}.${name}`}
                size={4}
            />
        );
    }

    const rank0 = sortOrder === 0;

    return (
        <div style={styles.container}>
            <div style={styles.slotContainer}>
                <Typography fontSize="20px" fontWeight="bold">{intl.formatMessage({id: 'spell_level'}, {level: sortOrder})}</Typography>
                {rank0 && <Typography fontSize="20px" fontWeight="bold">{intl.formatMessage({id: 'cantrips'})}</Typography>}
                {!rank0 && getField('slots_total')}
                {!rank0 && getField('slots_expended')}
            </div>
            <TextBox
                labelId="spells"
                path={`${path}.spell_list`}
                maxLength={300} rows={15}
            />
        </div>
    );
}

SpellLevelInput.propTypes = {
    path: PropTypes.string.isRequired,
    sortOrder: PropTypes.number,
};

SpellLevelInput.defaultProps = {
    sortOrder: 0,
};


export default SpellLevelInput;