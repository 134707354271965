import * as React from 'react';
import AddButton from "../../Components/AddButton";

export interface Props {
    path: string,
    addLabelId: string,
}

export const DnD5eAddButton: React.FC<Props> = (props) => {
    const {path, addLabelId} = props;

    const style = {
        color: '#000000',
    };
    return (
        <AddButton
            path={path}
            addLabelId={addLabelId}
            style={style}
        />);
}

export default DnD5eAddButton;