import React, {useContext} from 'react';
import {Grid} from "@mui/material";
import PropTypes from 'prop-types';
import {CharacterContext} from '../../Characters/CharacterContainer';
import {computeAbilityModifier} from '../mathUtils';
import {DEX} from '../constants';
import NumberInput from '../../Components/Inputs/NumberInput';
import {useCharacterObjectListener} from '../../Characters/characterHooks';


function InitiativeInput(props) {

    const {
        path
    } = props;

    const current = useCharacterObjectListener(path, path);
    const [character] = useContext(CharacterContext);
    const modifier = computeAbilityModifier(character, DEX);
    const total = (current.misc || 0) + (modifier || 0);


    return (
        <Grid
            container
            spacing={1}
            justifyContent="space-evenly"
            alignItems="center"
        >
            <Grid
                item
                xs={4}
            >
                <NumberInput
                    labelId="initiative"
                    size={5}
                    min={-10}
                    max={40}
                    path={`${path}.total`}
                    readOnlyValue={total}
                    readOnly
                />
            </Grid>
            <Grid
                item
                xs={4}
            >
                <NumberInput
                    labelId="initiativeDex"
                    readOnlyValue={modifier}
                    min={0}
                    max={40}
                    size={5}
                    readOnly
                />
            </Grid>
            <Grid
                item
                xs={4}
            >
                <NumberInput
                    labelId="initiativeMisc"
                    min={0}
                    max={40}
                    size={5}
                    path={`${path}.misc`}
                />
            </Grid>
        </Grid>
    );
}

InitiativeInput.propTypes = {
    current: PropTypes.object,
    setCurrent: PropTypes.func,
};

InitiativeInput.defaultProps = {
    current: {},
    setCurrent: () => {
    },
};

export default InitiativeInput;