import React from 'react';
import StarfinderTextInput from "./Components/StarfinderTextInput";

function NameInput(props) {

    return (
        <StarfinderTextInput {...props} />
    );
}

export default NameInput;