import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Typography, useMediaQuery} from "@mui/material";
import {useIntl} from "react-intl";
import {CharacterContext} from '../../../Characters/CharacterContainer';
import {computeAbilityModifier} from '../../mathUtils';
import {DEX} from '../../constants';
import {useTheme} from "@mui/styles";
import {useCharacterObjectListener} from "../../../Characters/characterHooks";
import StarfinderNumberInput from "./StarfinderNumberInput";


function ArmorClassInput(props) {

    const {
        path,
        labelId,
    } = props;
    const intl = useIntl();
    const [character] = useContext(CharacterContext);
    const current = useCharacterObjectListener(path, path);

    const abilityModifier = computeAbilityModifier(character, DEX);
    const adl = character.armor_dex_limit;
    const usableDexLimit =  adl !== undefined && !isNaN(adl)  && adl !== null;
    const modifier = usableDexLimit ? Math.min(abilityModifier, character.armor_dex_limit) : abilityModifier;
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));
    const variantProps = largeLayout? {} : {variant: 'standard'}
    const total = Object.keys(current).reduce((acc, key) => acc + parseInt(current[key]), 0) + (modifier || 0) + 10;

    return (
        <tr>
            {largeLayout && (
            <td>
                <Typography align="left">{intl.formatMessage({id: labelId})}</Typography>
            </td>
            )}
            <td>
                <StarfinderNumberInput
                    noLabel={largeLayout}
                    path={`${path}.total`}
                    labelId="total"
                    size={4}
                    min={0}
                    max={999}
                    readOnlyValue={total}
                    readOnly
                    {...variantProps}
                />
            </td>
            <td>
                <StarfinderNumberInput
                    noLabel={largeLayout}
                    labelId="armorClassArmor"
                    min={0}
                    max={40}
                    size={4}
                    {...variantProps}
                    path={`${path}.armor`}
                />
            </td>
            <td>
                <StarfinderNumberInput
                    noLabel={largeLayout}
                    labelId="armorClassDex"
                    path={`${path}.modifier`}
                    min={0}
                    max={40}
                    size={4}
                    readOnly
                    {...variantProps}
                    readOnlyValue={modifier}
                />
            </td>
            <td>
                <StarfinderNumberInput
                    noLabel={largeLayout}
                    labelId="armorClassMisc"
                    min={0}
                    max={40}
                    size={4}
                    {...variantProps}
                    path={`${path}.misc`}
                />
            </td>
        </tr>
    );
}

ArmorClassInput.propTypes = {
    path: PropTypes.string.isRequired,
    labelId: PropTypes.string.isRequired,
};


export default ArmorClassInput;