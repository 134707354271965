import React from 'react';
import EquipmentInput from '../EquipmentInput';
import ListSection from '../../../Components/ListSection';
import CarryingCapacity from '../CarryingCapacity';
import SheetSection from '../Components/SheetSection';
import StarfinderAddButton from "../Components/StarfinderAddButton";

function Equipment(props) {
    const path = "equipment";

    return (
        <ListSection
            path={path}
            inputPrototype={EquipmentInput}
            titleId="equipment"
            addButton={<StarfinderAddButton addLabelId="addEquipment" path={path}/>}
            sheetSection={SheetSection}
            {...props}

        >
            <CarryingCapacity/>
        </ListSection>
    );
}

export default Equipment;