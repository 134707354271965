import * as React from 'react';
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {useIntl} from "react-intl";
import {colors, fonts} from "../../appConstants";

export interface Props {
    titleId: string,
    action?: JSX.Element,
}

const styles = {
    container: {
        border: 'thick double black',
        backgroundColor: colors.dnd5eBackgroundColor,
        borderStyle: 'double',
        marginBottom: 16,
        borderRadius: 15,
        padding: '8px',
        textTransform: 'uppercase'
    },
    sheetSectionTitle: {
        textAlign: 'center',
        flexGrow: 1,
    },
    sheetSectionHeader: {
        display: 'flex',
        justifyContent: 'left',
        marginBottom: 8,
    },
    sheetSectionHeaderText: {
        fontFamily: fonts.dnd5eTitleFont,
        fontWeight: 'bold',
        textTransform: 'none',
        fontSize: '24px',
    }
}


export const SheetSection:React.FC<Props> = (props) => {

    const {
        titleId,
        action,
        children,
    } = props;


    const intl = useIntl();


    return (
        <div style={styles.container as React.CSSProperties} id={titleId}>
            <div style={styles.sheetSectionHeader as React.CSSProperties}>
                <div style={styles.sheetSectionTitle as React.CSSProperties}>
                    <Typography
                        variant="h6"
                        style={styles.sheetSectionHeaderText as React.CSSProperties}>
                        <Box fontWeight="bold">
                            {intl.formatMessage({id: titleId})}
                        </Box>
                    </Typography>
                </div>
            </div>
            {action && (
                <div>
                    {action}
                </div>
            )}
            {children}
        </div>
    )
}

export default SheetSection;