import React from 'react';
import {Grid} from "@mui/material";
import NumberInput from '../../Components/Inputs/NumberInput';


function ExperienceInput(props) {

    const { path } = props



    function getField(name) {
        return (
            <NumberInput
                labelId={'exp' + name.toLowerCase()}
                min={0}
                max={999999}
                name={name}
                size={6}
                path={`${path}.${name}`}
             />
        );
    }


    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-evenly"
        >
            <Grid
                item
            >
                {getField('earned')}
            </Grid>

            <Grid
                item
            >
                {getField('next')}
            </Grid>
        </Grid>
    );
}

export default ExperienceInput;