import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from "@mui/styles";
import {Grid, IconButton, Tooltip} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {useIntl} from "react-intl";
import {broadcastMessage} from '../../MessageComponents/messageListeners';
import {useCharacterId, useIsEditable} from '../../Characters/characterHooks';
import VtMSlider from "./VtMSlider";
import VtMTextInput from "./VtMTextInput";

const advantageStyles = makeStyles((theme) => {
    return {
        sliderContainer: {
            marginLeft: theme.spacing(1),
        },
        root: {
            display: 'flex',
            margin: theme.spacing(1),
        },
        textContainer: {}
    };
});

function AdvantageOrFlaw(props) {

    const {
        path,
    } = props;

    const isEditable = useIsEditable()  ;
    const theme = useTheme();
    const advantageClasses = advantageStyles(theme);
    const intl = useIntl();
    const channel = useCharacterId();

    function removeItem() {
        broadcastMessage(channel, `${path}.deleted`, true);
    }

    return (
        <Grid
            container
            justifyContent="center"
            spacing={2}
        >
            <Grid
                item
                key="rank"
                container
                justifyContent="center"
            >
                {isEditable && (<div>
                    <Tooltip title={intl.formatMessage({id: 'remove'})}>
                        <IconButton onClick={removeItem} size="large">
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                </div>)}
                <div
                    className={advantageClasses.sliderContainer}
                >
                    <VtMSlider
                        min={1}
                        max={5}
                        path={`${path}.score`}
                    />
                </div>

            </Grid>
            <Grid
                item
                key="rank"
                container
                justifyContent="center"
            >
                <VtMTextInput
                    labelId="advantage_or_flaw"
                    path={`${path}.name`}
                    size={45}
                    controlled
                />
            </Grid>

        </Grid>
    );
}

AdvantageOrFlaw.propTypes = {
    path: PropTypes.string.isRequired,
};


export default AdvantageOrFlaw;

