import * as React from 'react';
import DnD5eNumberInput from "../Components/DnD5eNumberInput";

export interface Props {
    path: string,
}

const styles = {
    container: {
        border: `1px dashed`,
        borderRadius: 10,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    },
    sliderBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    sliderLabel: {
        textAlign: 'left',
        marginRight: 8,
    }

}

export const HitDice: React.FC<Props> = (props) => {

    const {path} = props;


    return (
        <div
            style={styles.container as React.CSSProperties}
        >
            <DnD5eNumberInput size={4} labelOnSide path={`${path}.total`} labelId="hit_dice_total"/>
            <DnD5eNumberInput size={4} labelOnSide path={`${path}.num_dice`} labelId="hit_dice"/>
         </div>
    );

}

export default HitDice;