import React from 'react';
import SelectInput from "../../Components/Inputs/SelectInput";


function ThemeInput(props) {

    const themes = [
        {displayId: 'themeAcePilot', value: 'ace_pilot'},
        {displayId: 'themeBountyHunter', value: 'bounty_hunter'},
        {displayId: 'themeIcon', value: 'icon'},
        {displayId: 'themeMercenary', value: 'mercenary'},
        {displayId: 'themeOutlaw', value: 'outlaw'},
        {displayId: 'themePriest', value: 'priest'},
        {displayId: 'themeScholar', value: 'scholar'},
        {displayId: 'themeSpacefarer', value: 'spacefarer'},
        {displayId: 'themeXenoseeker', value: 'xenoseeker'},
        {displayId: 'themeThemeless', value: 'themeless'},
    ];

    return (<SelectInput labelId="theme" options={themes} {...props}/>);
}

export default ThemeInput;