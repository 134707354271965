import React, {useContext} from 'react';
import {CharacterContext} from '../Characters/CharacterContainer';
import Typography from '@mui/material/Typography';
import {useIntl} from 'react-intl';
import { computeBulk } from './mathUtils';

function TotalBulk(props) {

    const [character] = useContext(CharacterContext)
    const intl = useIntl();



    return (
        <Typography>
            {intl.formatMessage({id: 'totalBulk'}, {bulk: computeBulk(character)})}
        </Typography>
    )
}

export default TotalBulk;