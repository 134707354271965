import {firebaseApp} from "../index";
import {query, where, collection, onSnapshot, updateDoc, doc, addDoc} from "firebase/firestore";
import {getEmulationCapableAuth, getEmulationCapableFirestore} from "./initUtils";

const CHARACTER_COLLECTION = 'characters';

export function saveCharacter(id, data) {
    const db = getEmulationCapableFirestore(firebaseApp);
    //console.error(data);
    const augmentedData = {
        ...data,
        dirty: false,
        updatedAt: new Date().getTime(),
    };
    const charRef = doc(db, CHARACTER_COLLECTION, id);
    return updateDoc(charRef, augmentedData);
}

export function retireCharacter(id){
    setCharRetirement(id, true);
}

export function reactivateCharacter(id){
    setCharRetirement(id, false);
}


// This will get to the context via the normal update mechanism, so updates are bi/directional
function setCharRetirement(id, retired){
    const db = getEmulationCapableFirestore();
    const charRef = doc(db, CHARACTER_COLLECTION, id);
    return updateDoc(charRef, {retired});
}

export function listenForYourCharacters(snapshotHandler) {
    const db = getEmulationCapableFirestore();
    const auth = getEmulationCapableAuth();
    const user = auth.currentUser;
    const charsRef = collection(db, CHARACTER_COLLECTION);
    const q = query(charsRef, where('userId', '==', user.uid));
    onSnapshot(q, snapshotHandler);
}

export function makeNewCharacter(charData) {
    const db = getEmulationCapableFirestore();
    const auth = getEmulationCapableAuth();
    const user = auth.currentUser;
    const newCharStub = {...charData, userId: user.uid};
    return addDoc(collection(db, CHARACTER_COLLECTION), newCharStub);
}