import React, {useContext} from 'react';
import {Grid, Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {CharacterContext} from '../../Characters/CharacterContainer';
import {computeBulk} from '../mathUtils';
import TotalBulk from '../TotalBulk';
import {useTheme} from "@mui/material/styles";


function CarryingCapacity(props) {

    const theme = useTheme();
    const intl = useIntl();
    const [character] = useContext(CharacterContext);
    const safeCharacter = character || {};
    const safeAbilities = safeCharacter.ability_scores || 0;
    const safeStr = safeAbilities.str || {};
    const base = safeStr.score || 0;
    const upgraded = safeStr.upgraded_score || 0;

    const strength = base + upgraded;
    const unencumberedLimit = (strength / 2.0) + .9;
    const bulk = computeBulk(character);
    const overBurdened = bulk > strength + 1;
    const encumbered = bulk > (strength / 2.0) + 1;

    const styles = {
        normal: {
        },
        warning: {
            color: 'red',
        },
        bulkContainer: {
            border: '1px solid grey',
            borderRadius: 6,
            marginBottom: theme.spacing(1),
            padding: theme.spacing(1),
        }
    }

    const encumberedText = encumbered ? intl.formatMessage({id: 'yes'}) : intl.formatMessage({id: 'no'});
    const overBurdenedText = overBurdened ? intl.formatMessage({id: 'yes'}) : intl.formatMessage({id: 'no'});
    return (
        <div style={styles.bulkContainer}>
        <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
        >
            <Grid
                item
            >
                <TotalBulk/>
            </Grid>


            <Grid
                item
            >
                <Typography>
                    {intl.formatMessage({id: 'ccUnencumbered'}, {value: unencumberedLimit})}
                </Typography>
            </Grid>

            <Grid
                item
            >
                <Typography style={encumbered? styles.warning : styles.normal}>
                    {intl.formatMessage({id: 'ccEncumbered'}, {value: encumberedText})}
                </Typography>
            </Grid>
            <Grid
                item
            >
                <Typography style   ={overBurdened? styles.warning : styles.normal}>
                    {intl.formatMessage({id: 'ccOverburdened'}, {value: overBurdenedText})}
                </Typography>
            </Grid>
        </Grid>
        </div>
    );
}

export default CarryingCapacity;