import * as React from 'react';
import {Button, Typography} from "@mui/material";
import {useHistory} from "react-router-dom";
import {fonts} from "./appConstants";
import Wordmark from "./Marks/Wordmark";


export const App404: React.FC = () => {
    const styles = {
        container: {
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: "center",
            flexDirection: 'column',
            alignItems: "center",
        },
        errorMessage: {
            marginBottom: '16px',
            fontFamily: fonts.logoFont,
        },
        button: {
            fontFamily: fonts.logoFont,
        }
    }

    const history = useHistory();


    return (
        <div style={styles.container as React.CSSProperties}>
            <Wordmark />
            <Typography style={styles.errorMessage}>
                Oh no! We couldn't find what you're looking for.
            </Typography>
            <Button
                style={styles.button}
                variant="contained"
                onClick={() => history.push('/')}
            >
                Go Home
            </Button>
        </div>
    );
}

export default App404;