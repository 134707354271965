import * as React from 'react';
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import {useAuth, useUser} from "reactfire";
import {Avatar, Menu} from "@mui/material";
import {useState} from "react";
import {useIntl} from "react-intl";
import {openStripeManagePage, subscribeWithStripe} from "../Firebase/firebaseAccounts";
import {useHasSubscribedBefore} from "../Accounts/accountHooks";


function nameToAvatar(name?: string | null): string {
    if (name == null) {
        return ''
    }
    return name.charAt(0).toUpperCase();
}

const styles = {
    avatar: {
        fontFamily: 'Roboto',
    },
    largeAvatar: {
        fontFamily: 'Roboto',
        width: '48px',
        height: '48px',
    },
    name: {
        marginTop: '0.5rem',
        fontFamily: 'Roboto',
        marginBottom: '0.25rem'
    },
    email: {
        fontFamily: 'Roboto',
        fontSize: 12,
        color: '#808080',
    },
    logoutButton: {
        marginTop: '1rem',
        fontFamily: 'Roboto',
        marginBottom: '1rem',
    },
    bio: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    signoutSection: {
        width: '100%',
        borderTop: '1px solid #e8eaed',
        display: 'flex',
        justifyContent: 'center'
    },
    manageSection: {},
    menu: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Roboto',
        minWidth: '15rem',
    }
}

export const Profile: React.FC = () => {
    const auth = useAuth();
    const intl = useIntl();
    const {data: user} = useUser();
    const subscribedBefore = useHasSubscribedBefore();
    const [loadingActive, setLoadingActive] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = anchorEl != null;

    function closeMenu() {
        setAnchorEl(null);
    }

    function logOut() {
        auth.signOut();
    }

    function openMenu(e: any): void {
        // @ts-ignore
        setAnchorEl(e.target);
    }

    function visitStripeUrl(url: string) {
        window.location.assign(url);
        setLoadingActive(false);
    }

    function manageAccount() {
        setLoadingActive(true);
        return openStripeManagePage()
            .then(visitStripeUrl);
    }

    function subscribe() {
        setLoadingActive(true);
        return subscribeWithStripe()
            .then(visitStripeUrl);
    }



    return (
        <div key="profile">
            <Button
                id="profile"
                onClick={openMenu}>
                <Avatar style={styles.avatar}>
                    {nameToAvatar(user?.displayName)}
                </Avatar>
            </Button>
            <Menu
                id="moreMenu"
                anchorEl={anchorEl}
                keepMounted
                open={menuOpen}
                onClose={closeMenu}
            >
                <div key="menuContainer" style={styles.menu as React.CSSProperties}>
                    <div key="avatar" style={styles.bio as React.CSSProperties}>
                        <Avatar
                            style={styles.largeAvatar}
                        >
                            {nameToAvatar(user?.displayName)}
                        </Avatar>
                        <div style={styles.name}>
                            {user?.displayName}
                        </div>
                        <div style={styles.email as React.CSSProperties}>
                            {user?.email}
                        </div>

                    </div>
                    <div key="manage" style={styles.manageSection}>
                        <LoadingButton
                            id="subscription"
                            style={styles.logoutButton}
                            variant="outlined"
                            loading={loadingActive}
                            onClick={subscribedBefore? manageAccount : subscribe}
                        >
                            {subscribedBefore? "Manage Your Account" : "Start Subscription"}
                        </LoadingButton>
                    </div>
                    <div key="signout" style={styles.signoutSection}>
                        <Button
                            style={styles.logoutButton}
                            variant="outlined"
                            onClick={logOut}
                        >
                            {intl.formatMessage({id: 'logOut'})}
                        </Button>
                    </div>
                </div>
            </Menu>
        </div>
    )
}

export default Profile;