import * as React from 'react';
import AddButton from '../../../Components/AddButton';

export interface Props {
    path: string,
    addLabelId: string,
}

export const StarfinderAddButton: React.FC<Props> = (props) => {
    const {path, addLabelId} = props;
    return (
        <AddButton
            path={path}
            addLabelId={addLabelId}
            style={{color: '#1c4e77', textTransform: 'uppercase'}}
        />);
}

export default StarfinderAddButton;