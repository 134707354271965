import React from 'react';
import {Typography, useMediaQuery} from "@mui/material";
import {computeModifierFromScore} from "../mathUtils";
import {useIntl} from "react-intl";
import {useTheme} from "@mui/styles";
import {useCharacterObjectListener} from '../../Characters/characterHooks';
import StarfinderNumberInput from "./Components/StarfinderNumberInput";


function AbilityInputRow(props) {

    const {path, name} = props;
    const intl = useIntl();
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));
    const current = useCharacterObjectListener(`${name}.row`, path);


    const safeScore = current.score || 0;
    const initialUpgradedScore = safeScore + (current.upgraded_score || 0);
    const modifier = computeModifierFromScore(safeScore);
    const variantProps = largeLayout ? {} : {variant: 'standard'};
    const upgradedModifier = computeModifierFromScore(initialUpgradedScore);
    return (
        <tr>
            {largeLayout && (
                <td>
                    <Typography align="left">{intl.formatMessage({id: name})}</Typography>
                </td>
            )}
            <td>
                <StarfinderNumberInput
                    noLabel={largeLayout}
                    labelId="total"
                    path={`${path}.score`}
                    size={4}
                    min={0}
                    max={30}
                    {...variantProps}
                />
            </td>
            <td>
                <StarfinderNumberInput
                    noLabel={largeLayout}
                    labelId="modifier"
                    readOnlyValue={modifier}
                    readOnly
                    path={`${path}.modifier`}
                    min={-30}
                    max={30}
                    size={4}
                />
            </td>
            <td>
                <StarfinderNumberInput
                    noLabel={largeLayout}
                    labelId="upgradedScore"
                    path={`${path}.upgraded_score`}
                    size={4}
                    min={0}
                    max={30}
                    {...variantProps}
                />
            </td>
            <td>
                <StarfinderNumberInput
                    noLabel={largeLayout}
                    labelId="upgradedScore"
                    readOnlyValue={upgradedModifier}
                    readOnly
                    min={-30}
                    max={30}
                    size={4}
                />
            </td>
        </tr>
    );
}

export default AbilityInputRow;