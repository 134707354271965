import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material';
import {useIntl} from 'react-intl';
import StarfinderNumberInput from "./StarfinderNumberInput";

function HealthAndResolveInput(props) {

    const {path, labelId} = props;
    const intl = useIntl();

    const totalPath = `${path}.total`;
    const currentPath = `${path}.current`;
    const usedLabel = labelId ?? path;
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
            spacing={2}
            >
            <Grid
                item
            >
                <Typography>{intl.formatMessage({id: usedLabel})}</Typography>
            </Grid>
            <Grid
                item
            >
                <StarfinderNumberInput max={9999} min={-9999} size={6} path={totalPath} labelId="total"/>
            </Grid>
            <Grid
                item
            >
                <StarfinderNumberInput min={-9999} max={9999} size={6} path={currentPath} labelId="current"/>
            </Grid>
        </Grid>
    )
}

HealthAndResolveInput.propTypes = {
    path: PropTypes.string.isRequired,
    labelId: PropTypes.string,
}

export default HealthAndResolveInput;