export const charTypes = {
    starfinder: 'starfinder',
    vtm: 'vtm',
    dnd5e: 'dnd5e',
};
export const fonts = {
    logoFont: 'logofont',
    appFont: 'logofont',
    starfinderSheetFont: 'orbitron',
    starfinderTitleFont: 'texturina',
    dnd5eTitleFont: 'cormorant-unicase',
    dnd5eSheetFont: 'Roboto',
    vtmSheetFont: 'cormorant',
    vtmTitleFont: 'cormorant',
    dice: 'sans-serif',
}

export const colors = {
    starfinderTitleColor: '#1c4e77',
    vtmBackgroundColor: '#f9f2e6',
    toolbarColor: '#fffffff',
    dnd5eBackgroundColor: '#ffffff',
}