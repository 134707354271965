
const UPDATE_CHAR = 'UC';
// when the UI updates the ram state, but it's not in the backend yet
const DIRTY_UPDATE_CHAR = 'DUC';
const REMOVE_CHAR = 'RC';

export function updateChar(doc) {
    return {
        type: UPDATE_CHAR,
        doc
    };
}

export function dirtyUpdateChar(char) {
    return {
        type: DIRTY_UPDATE_CHAR,
        char,
    };
}

export function removeChar(doc) {
    return {
        type: REMOVE_CHAR,
        doc,
    };
}

function doRemoveChar(state, action) {
    const { doc } = action;
    const { id } = doc;
    const newState = {...state};
    delete newState[id];
    return newState;
}

function doDirtyUpdateChar(state, action) {
    const { char } = action;
    const { id } = char;
    const newState = {...state};
    newState[id] = {...char};
    return newState;
}

function doUpdateChar(state, action) {
    const { doc } = action;
    const { id } = doc;
    const data = doc.data();
    const newState = {...state};
    newState[id] = {...data, id};
    return newState;
}

export function reducer(state, action) {
    switch (action.type) {
        case REMOVE_CHAR:
            return doRemoveChar(state, action);
        case UPDATE_CHAR:
            return doUpdateChar(state, action);
        case DIRTY_UPDATE_CHAR:
            return doDirtyUpdateChar(state, action);
        default:
            return state;
    }
}