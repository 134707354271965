import React from 'react';
import PropTypes from 'prop-types';
import ListSection from "../../../Components/ListSection";
import Discipline from "../Discipline";
import SheetSection from '../../Components/SheetSection';
import AddButton from "../../../Components/AddButton";

function Disciplines(props){
    const path = "disciplines";
    return (
        <ListSection
            titleId="disciplines"
            addButton={<AddButton path={path} addLabelId="add_discipline"/>}
            inputPrototype={Discipline}
            path={path}
            sheetSection={SheetSection}
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
        />
    );
}

Disciplines.propTypes = {
    current: PropTypes.array,
    setCurrent: PropTypes.func,
};

Disciplines.defaultProps = {
    current: [],
    setCurrent: () => {}
};

export default Disciplines;