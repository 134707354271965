import * as React from 'react';
import SheetSection from "../../Components/SheetSection";
import DeathSaves from "../DeathSaves";
import DnD5eNumberInput from "../../Components/DnD5eNumberInput";
import HP from "../HP";
import HitDice from "../HitDice";
import {useTheme} from "@mui/material";



export const CombatStats: React.FC = () => {

    const theme = useTheme();


    const styles = {
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        },
        boxContainer: {
            width: '100%',
        },
        headerContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        }
    };

    return (
        <SheetSection
            titleId="combat_stats"
        >
            <div style={styles.container as React.CSSProperties}>
                <div style={styles.headerContainer as React.CSSProperties}>
                <DnD5eNumberInput labelOnSide path="armor_class" labelId="armor_class"/>
                <DnD5eNumberInput labelOnSide path="initiative" labelId="initiative"/>
                <DnD5eNumberInput labelOnSide path="speed" labelId="speed"/>
                </div>
                <HP path="hp"/>
                <HitDice path="hit_dice"/>
                <DeathSaves path="death_saves"/>
            </div>
        </SheetSection>
    );
}

export default CombatStats;