import React, {useContext} from 'react';
import Grid from "@mui/material/Grid";
import {CharacterContext} from "../../../Characters/CharacterContainer";
import {computeModifiers} from "../../mathUtils";
import {STR, DEX} from '../../constants';
import AttackBonusInputRow from '../AttackBonusInputRow';
import SheetSection from '../Components/SheetSection';
import Typography from '@mui/material/Typography';
import {useIntl} from 'react-intl';
import {useTheme} from '@mui/styles';
import {useMediaQuery} from '@mui/material';
import StarfinderNumberInput from "../Components/StarfinderNumberInput";
import useInView from "@owaiswiz/use-in-view";

const styles = {
    attackTable: {
        tableLayout: 'fixed',
        width: '100%',
    },
};


const skillDefs = [
    {name: 'melee', ability: STR},
    {name: 'ranged', ability: DEX},
    {name: 'thrown', ability: STR},
];

function AttackBonusesTable(props) {

    const [character] = useContext(CharacterContext);
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));
    const intl = useIntl();
    const path = 'attack_bonuses';
    const [ref, inView] = useInView();

    const modifiers = computeModifiers(character);

    function renderThrowRow(definition) {
        const {name, ability} = definition;
        return (
            <tr
                key={name}
            >
                <AttackBonusInputRow
                    key={name}
                    path={`${path}.${name}`}
                    modifier={modifiers[ability]}
                    {...definition}/>
            </tr>
        );
    }

    function renderThrowTable() {
        return (<table style={styles.attackTable}>
                <tbody>
                {skillDefs.map((definition) => renderThrowRow(definition))}
                </tbody>
            </table>
        );
    }

    function renderThrowGrid() {
        return (
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
            >
                {skillDefs.map((definition) => {
                    const {name} = definition;
                    return (
                        <Grid
                            item
                            container
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                            key={name}
                        >
                            <Grid
                                item
                            >
                                <Typography>
                                    {intl.formatMessage({id: name})}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <table style={styles.attackTable}>
                                    <tbody>
                                    {renderThrowRow(definition)}
                                    </tbody>
                                </table>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        );
    }


    return (
        <SheetSection
            key="attackBonuses"
            titleId="attackBonuses"
        >
            <Grid
                container
                ref={ref}
            >
                <Grid
                    item
                    direction="column"
                    container
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        item
                    >
                        <StarfinderNumberInput
                            min={0}
                            max={99}
                            size={4}
                            labelOnSide
                            labelId="baseAttackBonus"
                            path={`${path}.base_attack_bonus`}
                        />
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography style={{marginTop: '8px', marginBottom: '8px'}}>
                            {intl.formatMessage({id: 'attackKey'})}
                        </Typography>
                    </Grid>
                </Grid>
                {inView && largeLayout && renderThrowTable()}
                {inView && !largeLayout && renderThrowGrid()}
            </Grid>
        </SheetSection>
    );
}

export default AttackBonusesTable;