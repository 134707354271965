import * as React from 'react';
import SheetSection from "../../Components/SheetSection";

import TextBox from "../../../Components/Inputs/TextBox";


export const Proficiencies: React.FC = () => {

    const styles = {
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        boxContainer: {
            width: '100%',
        }
    };

    return (
        <SheetSection
            titleId="proficiencies"
        >
            <div style={styles.container as React.CSSProperties}>
                <div style={styles.boxContainer}>
                    <TextBox
                        maxLength={1024}
                        rows={20}
                        path="proficiencies"
                    />
                </div>

            </div>
        </SheetSection>
    );
}

export default Proficiencies;