/** @jsxImportSource @emotion/react */
import React from 'react';
import PropTypes from 'prop-types';
import {Box, Typography} from '@mui/material';
import {useIntl} from 'react-intl';
import {css} from "@emotion/react";
import {fonts} from "../../../appConstants";

function SheetSection(props) {


    const intl = useIntl();
    const {
        titleId,
        children,
        action,
    } = props;

    return (
        <div
            css={css`
              margin-top: 15px;
              margin-bottom: 15px;
            `}
            id={titleId}
        >
            <div
                css={css`
                  width: 300px;
                  display: block;
                  margin-left: auto;
                  margin-right: auto;
                  background-color: #1c4e77;
                  color: #ffffff;
                  border-radius: 6px 6px 0 0;
                `}
            >
                <div
                    style={{textAlign: 'center'}}
                >
                    <Typography
                        style={{
                            fontFamily: fonts.starfinderTitleFont
                        }}
                        variant="h5">
                        <Box fontWeight="bold">
                            {intl.formatMessage({id: titleId})}
                        </Box>
                    </Typography>
                </div>
            </div>
            <div
                style={{
                border: '1px solid #1c4e77',
                borderRadius: '6px',
                    backgroundColor: "#ffffff",
                padding: '10px'
                }}
            >
                <div
                    css={css`
                    display: block;
                    margin-left: auto;
                    margin-right: auto;    
                `}
                >
                    {action}
                </div>
                {children}
            </div>
        </div>
    );
}

SheetSection.propTypes = {
    titleId: PropTypes.string.isRequired,
    action: PropTypes.node,
}


export default SheetSection;