export const messages = {
    rollDice: "Dice Roller",
    enterCombat: 'Enter Combat Mode',
    leaveCombat: 'Leave Combat Mode',
    shortcuts: 'Shortcuts',
    notes: 'Notes',
    quantity: 'qty',
    // these keys must be present in all sheets (consider amalgamating from central includ
    home: 'Home',
    journal: 'Journal',
    logOut: 'Log Out',
    // end keys
    total: 'Total',
    skillName: 'Skill Name',
    skillEmpty: ' ',
    skillRanks: 'Ranks',
    skillClass: 'Class Bonus',
    abilityMod: 'Ability Mod',
    skillMisc: 'Misc. Mod',
    race: 'Race',
    ranksPerLevel: 'Skill Ranks Per Level',
    strength: 'Strength',
    STR: 'STR',
    dexterity: 'Dexterity',
    DEX: 'DEX',
    constitution: 'Constitution',
    CON: 'CON',
    intelligence: 'Intelligence',
    INT: 'INT',
    wisdom: 'Wisdom',
    WIS: 'WIS',
    charisma: 'Charisma',
    CHA: 'CHA',
    skill_table_key: 'Total = Ranks + Class Bonus + Ability Mod + Misc Mod - ACP',
    acrobatics: 'Acrobatics* ({ability})',
    athletics: 'Athletics* ({ability})',
    bluff: 'Bluff ({ability})',
    computers: '✝Computers ({ability})',
    culture: '✝Culture ({ability})',
    diplomacy: 'Diplomacy ({ability})',
    disguise: 'Disguise ({ability})',
    engineering: '✝Engineering ({ability})',
    intimidate: 'Intimidate ({ability})',
    'life science': 'Life Science ({ability})',
    medicine: '✝Medicine ({ability})',
    mysticism: 'Mysticism ({ability})',
    perception: 'Perception ({ability})',
    'physical science': '✝Physical Science ({ability})',
    piloting: 'Piloting ({ability})',
    'sense motive': 'Sense Motive ({ability})',
    'slight of hand': '✝Slight of Hand* ({ability})',
    stealth: 'Stealth* ({ability})',
    survival: 'Survival ({ability})',
    trainedOnly: '✝Trained Only',
    armorCheckPenaltyApplies: '* Armor Check Penalty Applies',
    name: 'Name',
    description: 'Description',
    classAndLevel: 'Class/Level',
    theme: 'Theme',
    size: 'Size',
    speed: 'Speed',
    gender: 'Gender',
    homeworld: 'Homeworld',
    alignment: 'Alignment',
    deity: 'Deity',
    player: 'Player',
    select: 'Select',
    skillNotes: 'Skill Notes',
    sizeFine: 'Fine',
    sizeDiminutive: 'Diminutive',
    sizeTiny: 'Tiny',
    sizeSmall: 'Small',
    sizeMedium: 'Medium',
    sizeLarge: 'Large',
    sizeHuge: 'Huge',
    sizeGargantuan: 'Gargantuan',
    sizeColossal: 'Colossal',

    abilities: 'Abilities',
    credits: 'Credits',
    languages: 'Languages',
    otherWealth: 'Other Wealth',
    wealth: 'Wealth',
    featsAndProficiencies: 'Feats and Proficiencies',

    themeAcePilot: 'Ace Pilot',
    themeBountyHunter: 'Bounty Hunter',
    themeIcon: 'Icon',
    themeMercenary: 'Mercenary',
    themeOutlaw: 'Outlaw',
    themePriest: 'Priest',
    themeScholar: 'Scholar',
    themeSpacefarer: 'Spacefarer',
    themeXenoseeker: 'Xenoseeker',
    themeThemeless: 'Themeless',
    lawfulGood: 'Lawful Good',
    lawfulNeutral: 'Lawful Neutral',
    lawfulEvil: 'Lawful Evil',
    neutralGood: 'Neutral Good',
    neutral: 'Neutral',
    neutralEvil: 'Neutral Evil',
    chaoticGood: 'Chaotic Good',
    chaoticNeutral: 'Chaotic Neutral',
    chaoticEvil: 'Chaotic Evil',

    weapons: 'Weapons',
    weapon: 'Weapon',
    level: 'Level',
    attackBonus: 'Bonus',
    attackBonuses: 'Attack Bonuses',
    damage: 'Damage',
    critical: 'Critical',
    range: 'Range',
    type: 'Type',
    ammo: 'Ammo',
    usage: 'Usage',
    special: 'Special',

    BAB: 'BAB',
    attackMisc: 'Misc. Mod',
    attackAbilityMod: '{ability} Mod',
    melee: 'Melee Attack',
    ranged: 'Ranged Attack',
    thrown: 'Thrown Attack',

    throw: 'Throw',
    throwsTableKey: 'Total = Base Save + Ability Mod + Misc Mod',
    savingBase: 'Base Save',
    savingMisc: 'Misc. Mod',
    fortitude: 'Fortitude ({ability})',
    reflex: 'Reflex ({ability})',
    will: 'Will ({ability})',

    initiative: 'Initiative',
    initiativeKey: 'Initiative = Dex Mod + Misc Mod',
    initiativeDex: 'DEX Modifier',
    initiativeMisc: 'Misc. Modifier',

    equipmentName: 'Equipment Name',
    equipmentBulk: 'Bulk',

    totalBulk: 'Total Bulk: {bulk}',

    healthAndResolve: 'Health And Resolve',
    stamina: 'Stamina',
    resolve: 'Resolve',
    hitPoints: 'Hit Points',
    current: 'Current',

    armorClassDescription: 'Total = 10 + Armor Bonus + DEX Mod + Misc Mod',
    energyArmorClass: 'Energy Armor Class',
    kineticArmorClass: 'Kinetic Armor Class',
    armorClassArmor: 'Armor Bonus',
    armorClassDex: 'DEX Mod',
    armorClassMisc: 'Misc. Mod',
    combatManeuvers: 'AC VS. Combat Maneuvers (8+KAC): {value}',
    dr: 'Dmg Reduction',
    resistances: 'Resistances',

    exp: 'Experience Points',
    expearned: 'XP Earned',
    expnext: 'Next Level',

    carryingCapacity: 'Carrying Capacity',
    ccUnencumbered: 'Unencumbered Capacity: {value}',
    ccEncumbered: 'Encumbered: {value}',
    ccOverburdened: 'Overburdened: {value}',

    spellsknown: 'Spells Known',
    spellsper_day: 'Spells Per Day',
    spellsslots_used: 'Spell Slots Used',
    spellsRank: 'Rank: {rank}',
    spells: "Spells",
    dexLimit: 'DEX Limit',
    armorCheckPenalty: 'ACP',
    removeACP: 'Remove ACP',
    ACP: 'ACP',
    yes: 'Yes',
    no: 'No',

    vitalInformation: 'Vital Information',
    armorClasses: 'Armor Class',
    skillsTable: 'Skills',
    health: 'Health',

    abilityName: 'Ability Name',
    abilitiesTable: 'Ability Scores',
    score: 'Score',
    upgradedScore: 'Score Upgrade',
    upgradedModifier: 'Upgraded Modifier',
    modifier: 'Modifier',
    equipment: 'Equipment',

    addEquipment: 'Add Equipment',
    addWeapon: 'Add Weapon',
    baseAttackBonus: 'Base Attack Bonus',
    attackKey: 'Total = BAB + Ability Mod + Misc Mod',
    savingThrows: 'Saving Throws',
    profession: 'Profession',

    pageHeader: 'STARFINDER',
    deleteWeapon: 'Remove Weapon',
    deleteEquipment: 'Remove Item',
    addSpellRank: 'Add Spell Rank',
    ranksUsed: "Ranks Used: {ranksUsed}",


}