import * as React from "react";
import {StyledEngineProvider, Theme, ThemeProvider} from "@mui/material/styles";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import FrontPage from "./FrontPage/FrontPage";
import Login from "./FrontPage/pages/Login";
import AboutUs from "./FrontPage/pages/AboutUs";

interface Props {
    theme: Theme
}

export const FrontPageContainer: React.FC<Props> = (props: Props) => {

    const {theme} = props;

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                    <div className="App">
                        <Router>
                            <Switch>
                                <Route
                                    exact
                                    path="/about"
                                >
                                    <AboutUs/>
                                </Route>
                                <Route
                                    path="/login"
                                >
                                    <Login/>
                                </Route>
                                <Route
                                    path="/"
                                >
                                    <FrontPage/>
                                </Route>

                            </Switch>
                        </Router>
                    </div>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default FrontPageContainer;