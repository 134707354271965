import SheetSection from '../Components/SheetSection';
import AbilityInputRow from '../AbilityInputRow';
import React from 'react';
import {useIntl} from "react-intl";
import {Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/styles";
import useInView from "@owaiswiz/use-in-view";


const styles = {
    abilitiesTable: {
        tableLayout: 'fixed',
        width: '100%',
    },
    smallContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    }
};

function AbilitiesTable(props) {

    const path='ability_scores';
    const intl = useIntl();
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));
    const [ref, inView] = useInView();

    const abilityMap = [
        {name: 'strength', key: 'str'}, {name: 'dexterity', key: 'dex'}, {name: 'constitution', key: 'con'},
        {name: 'intelligence', key: 'int'}, {name: 'wisdom', key: 'wis'}, {name: 'charisma', key: 'cha'}
    ];

    function generateRow(element) {
        const {name, key} = element;
        const rowPath = `${path}.${key}`;
        return (
            <AbilityInputRow key={key} name={name} path={rowPath}/>
        );
    }

    //render a different UI for the small screens
    if (!largeLayout) {
        return (
            <SheetSection
                key="abilitiesTable"
                titleId="abilitiesTable"
            >
                <div style={styles.smallContainer} ref={ref}>
                    {inView && abilityMap.map((element) => {
                        const {name} = element;
                        return (
                            <div key={name}>
                                <Typography align="center">{intl.formatMessage({id: name})}</Typography>
                                <table style={styles.abilitiesTable}>
                                        <tbody>
                                        {generateRow(element)}
                                        </tbody>
                                </table>
                            </div>
                        );
                    })}
                </div>
            </SheetSection>
        );
    }

    return (
        <SheetSection
            key="abilitiesTable"
            titleId="abilitiesTable"
        >
            <table style={styles.abilitiesTable} ref={ref}>
            <thead>
                <tr>
                    <th><Typography>{intl.formatMessage({id: 'abilityName'})}</Typography></th>
                    <th><Typography>{intl.formatMessage({id: 'score'})}</Typography></th>
                    <th><Typography>{intl.formatMessage({id: 'modifier'})}</Typography></th>
                    <th><Typography>{intl.formatMessage({id: 'upgradedScore'})}</Typography></th>
                    <th><Typography>{intl.formatMessage({id: 'upgradedModifier'})}</Typography></th>
                </tr>
                </thead>
                <tbody>
                {inView && abilityMap.map((element) => generateRow(element))}
                </tbody>
            </table>
        </SheetSection>
    );
}

export default AbilitiesTable;