import React from 'react';
import HealthAndResolveInput from './Components/HealthAndResolveInput';


function HitPointsInput(props) {

    return (
        <HealthAndResolveInput labelId={"hitPoints"} {...props}/>
    );
}

export default HitPointsInput;