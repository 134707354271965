import React from "react";
import AnimationRevealPage from "../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header from "../components/headers/light.js";
import Footer from "../components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "../components/features/TwoColWithButton.js";
// import MainFeature2 from "./components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "./components/features/TwoColSingleFeatureWithStats2.js";
// import Features from "../components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "../components/cards/ProfileThreeColGrid.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export const AboutUs = () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <MainFeature1
        subheading={<Subheading>About Character Place</Subheading>}
        heading="We are players"
        buttonRounded={false}
        primaryButtonText="Sign Up"
        primaryButtonUrl={"/login?signup=true"}
        description={(<div>Games made us who we are. From dragon's cave to starship's bridge, we love
            them all. Above all, we want to make a place where anyone can come together and play, regardless
            of where they are.</div>)}
        imageSrc=""
      />

        <TeamCardGrid
            heading="Our Team"
            subheading={null}
        />
      <Footer />
    </AnimationRevealPage>
  );
};

export default AboutUs;