import * as React from 'react';
import TextInput from "../../Components/Inputs/TextInput";

export interface Props {
    path: string,
    labelId?: string
}

export const DnD5eTextInput: React.FC<Props> = (props) => {

    return (
            <TextInput {...props} variant="outlined"/>
    );
}

export default DnD5eTextInput;