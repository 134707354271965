import {useContext} from "react";
import {AccountStore} from "./AccountStore";
import {StripeAccountStore} from "./StripeAccountStore";
import {useActiveCharacters} from "../Characters/characterHooks";


export function useAccount(){
    const [account] = useContext(AccountStore);
    return account;
}

export function useStripeAccount(){
    const [stripeAccount] = useContext(StripeAccountStore);
    return stripeAccount;
}

export function useHasSubscribedBefore() {
    const account = useStripeAccount();
    const {subscription} = account;
    return subscription != null;
}

export function useHasActiveSubscription(){
    const account = useStripeAccount();
    const {subscription} = account;
    if(subscription != null){
        const {status} = subscription;
        return status === "active";
    }
    return false;
}

const SUBBED_CHAR_LIMIT = 25;
const FREE_CHAR_LIMIT = 6;

export function useCanAddCharacters() {
    const activeCharacters = useActiveCharacters();
    const isSubscribed = useHasActiveSubscription();
    const limit = isSubscribed? SUBBED_CHAR_LIMIT : FREE_CHAR_LIMIT;
    return activeCharacters.length < limit;
}