export const messages = {
    home: 'Home',
    logOut: 'Log Out',

    dnd5e: 'Dungeons & Dragons 5e',

    journal: 'journal',
    roll_dice: 'Dice Roller',
    mod: 'Mod',
    notes: 'Notes',
    abilities_and_skills: 'Abilities & Skills',


    str_saving_throws: 'Saving Throws',
    wis_saving_throws: 'Saving Throws',
    int_saving_throws: 'Saving Throws',
    con_saving_throws: 'Saving Throws',
    dex_saving_throws: 'Saving Throws',
    cha_saving_throws: 'Saving Throws',

    strength: 'Strength',
    athletics: 'Athletics',

    proficiency_bonus: 'Proficiency Bonus',
    inspiration: 'Inspiration',

    acrobatics: 'Acrobatics',
    slight_of_hand: 'Sleight of Hand',
    stealth: 'stealth',
    dexterity: 'Dexterity',

    constitution: 'Constitution',

    arcana: 'Arcana',
    history: 'History',
    investigation: 'Investigation',
    nature: 'Nature',
    religion: 'Religion',
    intelligence: 'Intelligence',

    animal_handling: 'Animal Handling',
    insight: 'Insight',
    medicine: 'Medicine',
    perception: 'Perception',
    survival: 'Survival',
    wisdom: 'Wisdom',

    deception: 'Deception',
    intimidation: 'Intimidation',
    performance: 'Performance',
    persuasion: 'Persuasion',
    charisma: 'Charisma',


    passiveWisdom: 'Passive Wisdom (Perception)',

    //general ability stuff

    score: 'Score',
    modifier: 'Modifier',

    // vital information
    vital_information: 'Vital Information',
    lawfulGood: 'Lawful Good',
    lawfulNeutral: 'Lawful Neutral',
    lawfulEvil: 'Lawful Evil',
    neutralGood: 'Neutral Good',
    neutral: 'Neutral',
    neutralEvil: 'Neutral Evil',
    chaoticGood: 'Chaotic Good',
    chaoticNeutral: 'Chaotic Neutral',
    chaoticEvil: 'Chaotic Evil',

    exp: 'Experience Points',
    name: 'Name',
    background: 'Background',
    race: 'Race',
    alignment: 'Alignment',
    class_and_level: 'Class & Level',


    // personality
    motives: 'Motives',
    personality_traits: 'Personality Traits',
    ideals: 'Ideals',
    bonds: 'Bonds',
    flaws: 'Flaws',


    //spells
    spells: 'Spells',
    spell_level: 'Spell Level: {level} ',
    slots_total: 'Slots Total',
    slots_expended: 'Slots Expended',
    cantrips: 'Cantrips',
    add_spell_level: 'Add Spell Level',

    //features

    features_and_traits: 'Features & Traits',

    // proficiencies
    proficiencies: 'Other Proficiencies & Languages',

    //wealth
    wealth: 'Wealth',
    cp: 'CP',
    sp: 'SP',
    ep: 'EP',
    gp: 'GP',
    pp: 'PP',

    //equipment
    equipment: 'Equipment',
    add_equipment: 'Add Equipment',
    weight: 'Weight',
    equipment_name: 'Name',
    delete_equipment: 'Delete Equipment',

    //combat stats
    combat_stats: 'Combat Stats',
    armor_class: 'Armor Class',
    initiative: 'Initiative',
    speed: 'Speed',
    current_hp: 'Current Hit Points',
    temp_hp: 'Temporary Hit Points',
    max_hp: 'Maximum Hit Points',
    hit_dice_total: 'Hit Dice Total',
    hit_dice: 'Hit Dice',
    death_saves: 'Death Saves',
    successes: 'Successes',
    failures: 'Failures',

    //attacks
    delete_attack: 'Delete Attack',
    attacks: 'Attacks',
    attack_bonus: 'Bonus',
    attack_name: 'Name',
    attack_damage_type: 'Damage / Type',
    add_attack: 'Add Attack',

    //spellcasting
    spellcasting: 'Spellcasting',
    spellcasting_class: 'Spellcasting Class',
    spellcasting_ability: 'Spellcasting Ability',
    spell_save_dc: 'Spell Save DC',
    spell_attack_bonus: 'Spell Attack Bonus',

    //weapns
    weapons: 'Weapons',
    add_weapon: 'Add Weapon',
    delete_weapon: 'Delete Weapon',
    weapon_name: 'Name',
    weapon_damage_type: 'Damage / Type',
    weapon_bonus: 'Bonus',

};