import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from '@mui/material';
import {fonts} from "../../appConstants";

function RollResult(props) {
    const {
        rolls,
        font,
    } = props;

    const total = rolls.reduce((acc, elem) => acc + elem, 0);

    if (rolls.length === 0) {
        return <React.Fragment/>
    }

    function renderDie(value) {
        return (
            <div style={
                {
                    border: '1px solid',
                    borderRadius: 6,
                    margin: 4,
                    width: '44px',
                    height: '44px',
                }
            }
            >
                <Typography style={{
                    fontFamily: font,
                    marginBottom: 10,
                    marginTop: 10,
                    textAlign: 'center'
                }}>{value}</Typography>
            </div>
        )
    }

    return (
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
            {rolls.map((roll) => <td>{renderDie(roll)}</td>)}
            {rolls.length > 1 && (
                <Typography style={{fontFamily: font, marginTop: 14, marginBottom: 10}}>= {total}</Typography>
            )}
        </div>
    );
}

RollResult.propTypes = {
    rolls: PropTypes.arrayOf(PropTypes.number),
    font: PropTypes.string,
}
RollResult.defaultProps = {
    rolls: [],
    font: fonts.dice,
}

export default RollResult;