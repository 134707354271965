import React from 'react';
import PropTypes from 'prop-types';
import SheetSection from '../Components/SheetSection';
import InitiativeInput from '../InitiativeInput';
import {useIntl} from 'react-intl';
import {Typography} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';

const initStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        label: {
            marginBottom: theme.spacing(2),
        }
    };
})

function Initiative(props) {

    const intl = useIntl();
    const theme = useTheme();
    const initClasses = initStyles((theme));
    return (
        <SheetSection
            key="initiative"
            titleId="initiative"
        >
            <div className={initClasses.container}>
                <Typography className={initClasses.label}>
                    {intl.formatMessage({id: 'initiativeKey'})}
                </Typography>
                <InitiativeInput path="initiative"/>
            </div>
        </SheetSection>
    );
}

Initiative.propTypes = {
    state: PropTypes.object,
    onChangeCreator: PropTypes.func,
};

Initiative.defaultProps = {
    state: {},
    onChangeCreator: () => {
    },
};

export default Initiative;