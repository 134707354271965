export const messages = {
    home: 'Home',
    delete: 'Delete',
    logOut: 'Log Out',
    unnamed: 'The Nameless One',
    unknownRace: 'Unknown',
    unknownLevel: 'Unknown',


    noCharacters: "You don't have any active characters!!?? You should create one!",
    loadingCharacter: 'Loading Character',
    addCharacter: 'Add Character',

    upgradeNow: 'Upgrade Now',

    vtmName: 'Vampire the Masquerade',
    starfinderName: 'Starfinder',
    dnd5eName: 'Dungeons & Dragons 5e',

    yourCharacters: 'Active Characters',
    retiredCharacters: 'Retired Characters',
}