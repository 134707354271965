import React from 'react';
import {Grid, Typography} from "@mui/material";
import SheetSection from "../../Components/SheetSection";
import {useIntl} from "react-intl";
import VtMSlider from "../VtMSlider";
import VtMTextInput from "../VtMTextInput";

function CharacterInfo(props) {

    const intl = useIntl();

    const components = [
        'name', 'concept', 'predator',
        'chronicle', 'ambition', 'clan',
        'sire', 'desire', 'generation',
    ];

    const sliders = [
        {
            name: 'hunger',
            max: 5,
        },
        {
            name: 'humanity',
            max: 10,
        },
        {
            name: 'health',
            max: 13,
        },
        {
            name: 'willpower',
            max: 10,
        }
    ];

    return (
        <SheetSection
            titleId="characterInfo"
        >
            <Grid
                container
                justifyContent='center'
                spacing={1}
            >
                {components.map((component) => {
                    return (
                        <Grid
                            item
                            container
                            key={component}
                            xs={12}
                            sm={6}
                            md={4}
                            justifyContent="center"
                        >
                            <VtMTextInput
                                labelId={component}
                                path={component}
                                size={45}
                            />
                        </Grid>
                    );
                })}
                {sliders.map((slider) => {
                    const {name, max} = slider;
                    return (
                        <Grid
                            key={name}
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            container
                            justifyContent="center"
                        >
                            <Typography
                                variant="h6"
                            >
                                {intl.formatMessage({id: name})}
                            </Typography>
                            <div
                                style={{marginLeft: 10}}
                            >
                            <VtMSlider
                                path={name}
                                max={max}
                              />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
            <VtMTextInput
                dense
                labelId="resonance"
                path="resonance"
                 width={40}
            />
        </SheetSection>
    );
}

export default CharacterInfo;