/**
 Input that emulates vtm's dots cumulative input,
 where the number of dots that are filled in is the
 number you have. We're using a slider instead, though
 **/
import React from 'react';
import PropTypes from 'prop-types';
import {Slider} from "@mui/material";
import {useCharacterId, useCharacterListener, useIsEditable} from '../../Characters/characterHooks';
import {broadcastMessage} from '../../MessageComponents/messageListeners';
import {pathToCSSId} from "../../Utils/css";

function CumulativeInput(props) {

    const {
        max,
        min,
        path,
        readOnly,
        readOnlyValue,
        sliderStyle,
    } = props;

    const isEditable = useIsEditable();
    const storedValue = useCharacterListener(path, path);
    const channel = useCharacterId();

    function onChange(event, value) {
        if(!readOnly) {
            broadcastMessage(channel, path, value);
        }
      }

    // fill up our marks from min to max
    const marks = []
    for (let x = min; x <= max; x++) {
        marks.push({
            label: x,
            value: x,
        });
    }

    // if we're readonly just use the input we're provided
    const usedValue = readOnly? readOnlyValue : storedValue;

    const sliderWidth = ((max - min) + 1) * 20;
    return (
        <div style={{width: sliderWidth}}>
            <Slider
                id={pathToCSSId(path)}
                style={sliderStyle}
                marks={marks}
                step={1}
                min={min}
                max={max}
                value={usedValue}
                onChange={onChange}
                valueLabelDisplay="auto"
                disabled={!isEditable || readOnly}
            />
        </div>
    )
}

CumulativeInput.propTypes = {
    max: PropTypes.number.isRequired,
    min: PropTypes.number,
    path: PropTypes.string.isRequired,
    readOnly: PropTypes.bool,
    readOnlyValue: PropTypes.number,
    sliderStyle: PropTypes.object,
}

CumulativeInput.defaultProps = {
    min: 0,
    readOnly: false,
    readOnlyValue: 0,
    sliderStyle: {},
};

export default CumulativeInput;