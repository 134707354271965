import React from 'react';
import PropTypes from 'prop-types';
import SheetSection from "../../Components/SheetSection";
import AttributeSubSection from "./AttributeSubSection";
import Grid from "@mui/material/Grid";

const attributes = [
    {
        name: 'physical',
        attributes: ['strength', 'dexterity', 'stamina']
    },
    {
        name: 'social',
        attributes: ['charisma', 'manipulation', 'composure']
    },
    {
        name: 'mental',
        attributes: ['intelligence', 'wits', 'resolve']
    }
];

function Attributes(props) {
    const path = 'attributes';

    return (
        <SheetSection
            titleId="attributes"
        >
            <Grid
                container
            >
                {attributes.map((definition) => {
                    const {name, attributes} = definition;
                    return (
                        <Grid
                            item
                            key={name}
                            xs={12}
                            sm={4}
                        >
                            <AttributeSubSection
                                path={`${path}.${name}`}
                                titleId={name}
                                attributes={attributes}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </SheetSection>
    )


}

Attributes.propTypes = {
    current: PropTypes.object,
    setCurrent: PropTypes.func,
};
Attributes.defaultProps = {
    current: {},
    setCurrent: () => {},
};

export default Attributes;