import * as React from 'react';
import Screen from "../Components/Screen";
import {useAccount} from "./accountHooks";
import {Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ThemeProvider} from "@mui/material/styles";
import {theme} from "../Home/Home";


const Account: React.FC = () => {

    const account = useAccount();

    return (
        <ThemeProvider theme={theme}>
            <Screen
            >
                <Container>
                    <Typography>
                        You are on the {account?.tier ?? "Unknown"} plan.
                    </Typography>
                </Container>

            </Screen>
        </ThemeProvider>
    )
}

export default Account;