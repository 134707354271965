import React from 'react';

import SelectInput from "../../Components/Inputs/SelectInput";


function AlignmentInput(props) {

    const alignments = [
        {displayId: 'lawfulGood', value: 'lawful_good'},
        {displayId: 'lawfulNeutral', value: 'lawful_neutral'},
        {displayId: 'lawfulEvil', value: 'lawful_evil'},
        {displayId: 'neutralGood', value: 'neutral_good'},
        {displayId: 'neutral', value: 'neutral'},
        {displayId: 'neutralEvil', value: 'neutral_evil'},
        {displayId: 'chaoticGood', value: 'chaotic_good'},
        {displayId: 'chaoticNeutral', value: 'chaotic_neutral'},
        {displayId: 'chaoticEvil', value: 'chaotic_evil'}
    ];
    return (<SelectInput options={alignments} {...props}/>  );
}

export default AlignmentInput;