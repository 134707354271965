import {DEX, WIS, STR, INT, CHA, CON} from './constants';

export function computeModifierFromScore(score) {
    return Math.floor((score - 10) / 2.0);
}

export function computeAbilityModifier(character, ability){
    const scores = character.ability_scores || {};
    const charVals = scores[ability.toLowerCase()] ?? {}
    const score = charVals.score ?? 0;
    const upgradedScore = charVals.upgraded_score ?? 0;
    const withUpgrades = score + upgradedScore;
    const computed = computeModifierFromScore(withUpgrades);
    return computed;
}


export function computeModifiers(character) {
    return [STR, DEX, INT, WIS, CHA, CON].reduce((acc, ability) => {
        return {
            ...acc,
            [ability]: computeAbilityModifier(character, ability)
        };
    }, {});
}

export function computeBulk(character){
    const safeCharacter = character || {};
    const safeEquipment = safeCharacter.equipment || {};

    function getItemBulk(item){
        const safeItem = item || {};
        const {bulk} = safeItem;
        if (bulk) {
            switch (bulk) {
                case "-":
                    return 0;
                case "L":
                    return 0.1;
                default:
                    return parseInt(bulk);
            }
        }
        return 0;
    }

    const bulk = Object.keys(safeEquipment).reduce((acc, key) => {
        const item = safeEquipment[key];
        if (item.deleted) {
            return acc;
        }
        const itemBulk = getItemBulk(item);
        return acc + itemBulk;
    } , 0);
    return bulk;
}