import React from 'react';
import SheetSection from '../Components/SheetSection';
import ExperienceInput from '../ExperienceInput';


function Experience(props) {
    return (
        <SheetSection
            key="exp"
            titleId="exp"
        >
            <ExperienceInput path="experience"/>
        </SheetSection>
    );
}

export default Experience;