import React from 'react';
import NumberInput from "../../Components/Inputs/NumberInput";
import {Typography} from '@mui/material';
import {useIntl} from 'react-intl';
import {useTheme, makeStyles} from '@mui/styles';

const ranksStyles = makeStyles((theme) => {
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
        },
        inputLabel: {
            marginRight: theme.spacing(1),
        },
    };
})
/** This one uses a side label because it is way to long to be in the field **/
function RanksPerLevelInput(props) {
    const intl = useIntl();
    const theme = useTheme();
    const ranksClasses = ranksStyles(theme);
    const { path } = props;
    const myPath = `${path}.srpl`;

    return (
        <div className={ranksClasses.container}>
            <Typography className={ranksClasses.inputLabel}>
                {intl.formatMessage({id: 'ranksPerLevel'})}
            </Typography>
            <NumberInput path={myPath} noLabel size={4}/>
        </div>);

}

export default RanksPerLevelInput;