import React from 'react';
import ArmorClassInput from './Components/ArmorClassInput';

function KACInput(props) {

    return (
        <ArmorClassInput path="kinetic_armor_class" labelId="kineticArmorClass" {...props} />
    )
}

export default KACInput;