import * as React from 'react';
import SheetSection from "../../Components/SheetSection";
import ListSection from "../../../Components/ListSection";
import SpellLevelInput from "../SpellLevelInput";
import DnD5eAddButton from "../../Components/DnD5eAddButton";


export const Spells: React.FC = () => {


    const inputPrototype = SpellLevelInput;
    const path="spells";

    return (
        <ListSection
            titleId="spells"
            path={path}
            inputPrototype={inputPrototype}
            sheetSection={SheetSection}
            addButton={<DnD5eAddButton addLabelId="add_spell_level" path={path}/>}
        />
    );
}

export default Spells