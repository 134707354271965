import React from 'react';
import ArmorClassInput from './Components/ArmorClassInput';

function EACInput(props) {

    return (
        <ArmorClassInput path="energy_armor_class" labelId="energyArmorClass"/>
    )
}

export default EACInput;