import React, {useEffect, useRef} from 'react';
import {useCharacterObjectListener} from "../../Characters/characterHooks";
import Typography from "@mui/material/Typography";
import {useIntl} from "react-intl";

function RanksUsedDisplay(props){
    const { path } = props;

    const ref = useRef();
    const intl = useIntl();

    const current = useCharacterObjectListener("ranksUsedDisplay", path);

    useEffect(() => {
        const ranksUsed = Object.keys(current).reduce((acc, key) => {
                let used = 0;
                const skill = current[key];
                if (skill && skill.ranks) {
                    used += skill.ranks;
                }
                return acc + used;
            }
            , 0);
            if(ref.current) {
                ref.current.innerHTML =  intl.formatMessage({id: 'ranksUsed'}, {ranksUsed});
            }
    }, [current, intl, ref]);

    return (
        <Typography ref={ref}>
            0
        </Typography>
    )
}

export default RanksUsedDisplay;