import React from 'react';
import TextBox from "../../Components/Inputs/TextBox";


function DescriptionInput(props) {
    return (
        <TextBox
            {...props}
            rows={5}
            cols={40}
            maxLength={200}

        />
    );
}

export default DescriptionInput;