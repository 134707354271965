import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './App.css';
import StarfinderSheet from './Starfinder/StarfinderSheet';
import Home from './Home/Home';
import VtMSheet from "./VtM/VtMSheet";
import {CharacterStoreContainer} from "./Characters/CharacterStore";
import {createTheme, adaptV4Theme} from "@mui/material/styles";
import {CircularProgress, Container} from "@mui/material";
import FrontPageContainer from "./FrontPageContainer";
import App404 from "./App404";
import DnD5eSheet from "./DnD5e/DnD5eSheet";
import {useSigninCheck} from "reactfire";
import {AccountStoreContainer} from "./Accounts/AccountStore";
import Account from "./Accounts/Account";
import {StripeAccountStoreContainer} from "./Accounts/StripeAccountStore";

const theme = createTheme(adaptV4Theme({
    typography: {}
}));

function App() {

    const {status, data: signInCheckResult} = useSigninCheck();

    // return a spinner if we can't tell if they are logged in
    if (status === "loading") {
        return (
            <div className="App">
                <Container>
                    <CircularProgress size={120}/>
                </Container>
            </div>
        );
    }

    const {signedIn} = signInCheckResult;

    // consider pulling this into a component. It's the marketing site router
    if (!signedIn) {
        return (<FrontPageContainer theme={theme}/>);
    }

    return (
        <div className="App">
            <AccountStoreContainer>
                <StripeAccountStoreContainer>
                    <CharacterStoreContainer>
                        <Router>
                            <Switch>
                                <Route
                                    path="/starfinder/:characterId"
                                >
                                    <StarfinderSheet/>
                                </Route>
                                <Route
                                    path="/vtm/:characterId"
                                >
                                    <VtMSheet/>
                                </Route>
                                <Route
                                    path="/dnd5e/:characterId"
                                >
                                    <DnD5eSheet/>
                                </Route>
                                <Route
                                    path="/account"
                                >
                                    <Account/>
                                </Route>
                                <Route
                                    path="/"
                                    exact
                                >
                                    <Home/>
                                </Route>
                                <Route
                                    path=""
                                    exact
                                >
                                    <Home/>
                                </Route>
                                <Route path="*">
                                    <App404/>
                                </Route>
                            </Switch>
                        </Router>
                    </CharacterStoreContainer>
                </StripeAccountStoreContainer>
            </AccountStoreContainer>
        </div>
    );
}

export default App;
