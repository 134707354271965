export const messages = {
    rollDice: 'Dice Roller',
    journal: 'Journal',
    logOut: 'Log Out',
    home: 'Home',
    remove: 'Remove',
    profile: 'PROFILE',
    notes_header: 'NOTES',
    background_info: 'BACKGROUND',
    vampire_the_masquerade: 'Vampire the Masquerade',
    add_advantage_or_flaw: 'Add Advantage or Flaw',
    advantage_or_flaw: 'Advantage or Flaw',
    skills: 'SKILLS',
    characterInfo: 'GENERAL INFORMATION',
    discipline: 'Discipline',
    discipline_rank: 'Discipline Rank',
    blood: 'BLOOD',
    power: 'Power {num}',
    athletics: 'Athletics',
    animal_ken: 'Animal Ken',
    academics: 'Academics',
    brawl: 'Brawl',
    etiquette: 'Etiquette',
    awareness: 'Awareness',
    craft: 'Craft',
    insight: 'Insight',
    finance: 'Finance',
    drive: 'Drive',
    intimidation: 'Intimidation',
    investigation: 'Investigation',
    firearms: 'Firearms',
    leadership: 'Leadership',
    medicine: 'Medicine',
    melee: 'Melee',
    performance: 'Performance',
    occult: 'Occult',
    larceny: 'Larceny',
    persuasion: 'Persuasion',
    politics: 'Politics',
    stealth: 'Stealth',
    streetwise: 'Streetwise',
    science: 'Science',
    survival: 'Survival',
    subterfuge: 'Subterfuge',
    technology: 'Technology',

    attributes: 'ATTRIBUTES',
    physical: 'Physical',
    social: 'Social',
    mental: 'Mental',
    strength: 'Strength',
    dexterity: 'Dexterity',
    stamina: 'Stamina',
    charisma: 'Charisma',
    manipulation: 'Manipulation',
    composure: 'Composure',
    intelligence: 'Intelligence',
    wits: 'Wits',
    resolve: 'Resolve',

    name: 'Name',
    concept: 'Concept',
    ambition: 'Ambition',
    predator: 'Predator',
    chronicle: 'Chronicle',
    sire: 'Sire',
    desire: 'Desire',
    clan: 'Clan',
    generation: 'Generation',
    chronicle_tenets: 'Chronicle Tenets',
    touchstones_and_convictions: 'Touchstones & Convictions',
    clan_bane: 'Clan Bane',
    total_experience: 'Total Experience',
    spent_experience: 'Spent Experience',
    true_age: 'True age',
    apparent_age: 'Apparent age',
    date_of_birth: 'Date of birth',
    date_of_death: 'Date of death',
    appearance: 'Appearance',
    distinguishing_features: 'Distinguishing features',
    history_header: 'HISTORY',
    history: 'History',
    notes: 'Notes',
    advantages_and_flaws: 'ADVANTAGES & FLAWS',
    resonance: 'Resonance',
    hunger: 'Hunger',
    humanity: 'Humanity',
    disciplines: 'DISCIPLINES',
    add_discipline: 'Add Discipline',
    health: 'Health',
    willpower: 'Willpower',
    blood_potency: 'Blood Potency',
    blood_surge: 'Blood Surge',
    mend_amount: 'Mend Amount',
    power_bonus: 'Power Bonus',
    rouse_reroll: 'Rouse Re-Roll',
    feeding_penalty: 'Feeding Penalty',
    bane_severity: 'Bane Severity',
    is_specialization: "= Specialization"
}