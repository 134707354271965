import React from 'react';
import PropTypes from 'prop-types';
import {Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {useIntl} from "react-intl";
import {useCharacterObjectListener, useIsEditable} from "../../Characters/characterHooks";
import VtMSlider from "./VtMSlider";
import VtMTextInput from "./VtMTextInput";

/**
 * Disciplines can have between 1 - 5 powers.
 * an individual discipline's rank goes up with the number of powers.
 * so we're going to visually show that by statically allocating
 * the 5 powers, and as you fill them in the rank will go up.
 */

const styles = {
    disciplineBlock: {
        border: '1px solid',
        borderRadius: 6,
        padding: 10,
    },
}

function Discipline(props) {
    const {
        path,
        onRemove,
    } = props;

    const intl = useIntl();

    const isEditable = useIsEditable();
    // rank is the number of non empty elements
    const current = useCharacterObjectListener(path, path, {});
    const rank = Object.keys(current).reduce((acc, key) => {
        //extract only the element array keys
        if (key.startsWith("element")) {
            const value = current[key];
            // if it's got a value, increment our ranks
            if(value && value.trim()) {
                return acc + 1;
            }
        }
        return acc;
    }, 0);

    return (
        <div
            style={styles.disciplineBlock}
        >
            <Grid
                container
                justifyContent="center"
                spacing={2}
            >
                <Grid
                    item
                    key="rank"
                    container
                    justifyContent="center"
                >
                    {isEditable && (<div>
                        <Tooltip title={intl.formatMessage({id: 'remove'})}>
                            <IconButton onClick={onRemove} size="large">
                                <Delete/>
                            </IconButton>
                        </Tooltip>
                    </div>)}
                    <Typography
                        variant="h6"
                    >
                        {intl.formatMessage({id: 'discipline_rank'})}
                    </Typography>
                    <div
                        style={{marginLeft: 10}}
                    >
                        <VtMSlider path={`${path}.rank`} readOnly max={5} readOnlyValue={rank} />
                    </div>

                </Grid>
                <Grid
                    item
                    key="header"
                >
                    <VtMTextInput
                        labelId="discipline"
                        path={`${path}.name`}
                        size={45}
                        controlled
                    />
                </Grid>
                {[1,2,3,4,5].map((number) => {
                    return (
                        <Grid
                            item
                            container
                            key={number}
                            justifyContent="center"
                        >
                            <VtMTextInput
                                label={intl.formatMessage({id: 'power'}, {num:number})}
                                size={45}
                                dense
                                path={`${path}.element${number}`}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
}

Discipline.propTypes = {
    path: PropTypes.string,
    onRemove: PropTypes.func,
};

Discipline.defaultProps = {
    path: 'fakepath',
    onRemove: () => {
    },
};

export default Discipline;