import * as React from 'react';
import NumberInput from "../../Components/Inputs/NumberInput";
import {Typography} from "@mui/material";
import {useIntl} from "react-intl";

export interface Props {
    path: string,
    labelId?: string
    labelOnSide?: boolean,
    max?: number,
    min?: number,
    size?: number,
    noLabel?: boolean,
    readOnly?: boolean,
    readOnlyValue?: number,
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    label: {
        marginLeft: '8px',
        fontSize: '14px',
    },
    inputLabel: {
        overflowWrap: 'break-word',
    }
}


export const DnD5eNumberInput: React.FC<Props> = (props) => {

    const intl = useIntl();
    const {labelId, labelOnSide} = props;
    if (!labelOnSide) {
        return <NumberInput {...props} variant="outlined" inputLabelStyle={styles.inputLabel}/>;
    }
    return (
        <div style={styles.container}>
            <NumberInput {...props} noLabel={labelOnSide} variant="outlined"/>
            <Typography style={styles.label as React.CSSProperties}>
                {intl.formatMessage({id: labelId})}
            </Typography>
        </div>
    )
}

export default DnD5eNumberInput;