import React from 'react';

import SelectInput from "../../Components/Inputs/SelectInput";


function SizeInput(props) {

    const sizes = [
        {displayId: 'sizeFine', value: 'fine'},
        {displayId: 'sizeDiminutive', value: 'diminutive'},
        {displayId: 'sizeTiny', value: 'tiny'},
        {displayId: 'sizeSmall', value: 'small'},
        {displayId: 'sizeMedium', value: 'medium'},
        {displayId: 'sizeLarge', value: 'large'},
        {displayId: 'sizeHuge', value: 'huge'},
        {displayId: 'sizeGargantuan', value: 'gargantuan'},
        {displayId: 'sizeColossal', value: 'colossal'}
    ];
    return (<SelectInput options={sizes} {...props}/>  );
}

export default SizeInput;