import * as React from 'react';
import {Tooltip} from "@mui/material";
import {PersonAdd} from "@mui/icons-material";
import {colors} from "../appConstants";
import {useIntl} from "react-intl";
import {subscribeWithStripe} from "../Firebase/firebaseAccounts";
import {useState} from "react";
import {LoadingButton} from "@mui/lab";
import {useHasActiveSubscription} from "../Accounts/accountHooks";

const styles = {
    addButton: {
        color: "#000000",
    },
    addIcon: {
        color: colors.toolbarColor,
        height: '40px',
        width: '40px',
    },
};

export const UpgradeButton: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const intl = useIntl();
    const isSubscribed = useHasActiveSubscription();

    function visitStripeUrl(url: string) {
        window.location.assign(url);
        setLoading(false);
    }

    function subscribe(){
        setLoading(true);
        return subscribeWithStripe()
            .then(visitStripeUrl);
    }


    if(isSubscribed){
        return null; // can't upgrade more
    }

    return (
        <div>
            <div id="upgradeButton">
                <Tooltip
                    title={intl.formatMessage({id: 'upgradeNow'})}
                >
                    <LoadingButton
                        loading={loading}
                        style={styles.addButton}
                        onClick={subscribe}
                        endIcon={<PersonAdd style={styles.addIcon}/>}
                    >
                        {intl.formatMessage({id: 'upgradeNow'})}
                    </LoadingButton>
                </Tooltip>
            </div>
        </div>
    );

}

export default UpgradeButton;
