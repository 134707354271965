import * as React from 'react';
import SheetSection from "../../Components/SheetSection";
import DnD5eTextInput from "../../Components/DnD5eTextInput";
import SelectInput from "../../../Components/Inputs/SelectInput";
import DnD5eNumberInput from "../../Components/DnD5eNumberInput";
import {useTheme} from "@mui/material";

const alignments = [
    {displayId: 'lawfulGood', value: 'lawful_good'},
    {displayId: 'lawfulNeutral', value: 'lawful_neutral'},
    {displayId: 'lawfulEvil', value: 'lawful_evil'},
    {displayId: 'neutralGood', value: 'neutral_good'},
    {displayId: 'neutral', value: 'neutral'},
    {displayId: 'neutralEvil', value: 'neutral_evil'},
    {displayId: 'chaoticGood', value: 'chaotic_good'},
    {displayId: 'chaoticNeutral', value: 'chaotic_neutral'},
    {displayId: 'chaoticEvil', value: 'chaotic_evil'}
];



export const VitalInformation: React.FC = () => {

    const theme = useTheme();
    const styles = {
        container: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'end',
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        },
    };

    return (
        <SheetSection
            titleId="vital_information"
        >
            <div style={styles.container as React.CSSProperties}>
                <DnD5eTextInput path="name"/>
                <DnD5eTextInput path="class_and_level"/>
                <DnD5eTextInput path="background"/>
                <DnD5eTextInput path="race"/>
                <SelectInput path="alignment" options={alignments}/>
                <DnD5eNumberInput max={100000000000} size={9} min={0} path="exp"/>
            </div>
        </SheetSection>
    );
}

export default VitalInformation;