import React from 'react';
import TextBox from "../../Components/Inputs/TextBox";

function SkillNotesInput(props) {
    const {path} = props;
    const myPath = `${path}.skill_notes`;
    //console.log(`Skill notes path is ${myPath}`);
    return (
        <TextBox path={myPath} labelId="skillNotes" rows={3} cols={40} maxLength={120}/>
    );
}

export default SkillNotesInput;