import React from 'react';
import {IconButton, Tooltip, useMediaQuery} from '@mui/material';
import SelectInput from '../../Components/Inputs/SelectInput';
import {Delete} from '@mui/icons-material';
import {useIntl} from 'react-intl';
import {useCharacterId, useIsEditable} from "../../Characters/characterHooks";
import {broadcastMessage} from "../../MessageComponents/messageListeners";
import {useTheme} from "@mui/material/styles";
import StarfinderTextInput from "./Components/StarfinderTextInput";
import StarfinderNumberInput from "./Components/StarfinderNumberInput";

function EquipmentInput(props) {

    const {path} = props;
    const intl = useIntl();
    const channel = useCharacterId();
    const theme = useTheme();
    const isEditable = useIsEditable();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));


    const styles = {
        equipmentContainer: {
            marginBottom: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            border: '1px solid grey',
            borderRadius: 6,
            padding: theme.spacing(1),
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            rowGap: theme.spacing(1),
            columnGap: theme.spacing(1),
        },
    }

    const bulkValues = [
        {value: '-'},
        {value: 'L'},
        {value: '1'},
        {value: '2'},
        {value: '3'},
        {value: '4'},
        {value: '5'},
        {value: '6'},
        {value: '7'},
        {value: '8'}
    ];

    function softDelete() {
        broadcastMessage(channel, `${path}.deleted`, true);
    }


    return (
        <div style={styles.equipmentContainer}>
            <StarfinderTextInput size={largeLayout? 40 : 33} labelId="equipmentName" path={`${path}.name`}/>
            <StarfinderNumberInput labelId="level" size={4} path={`${path}.level`}/>
            <StarfinderNumberInput labelId="quantity" size={4} path={`${path}.quantity`}/>
            <SelectInput
                labelId="equipmentBulk"
                options={bulkValues}
                path={`${path}.bulk`}
            />
            <StarfinderTextInput size={largeLayout? 40: 33} labelId="notes" path={`${path}.notes`}/>
            {isEditable && (
                <React.Fragment>
                    <div style={{flexGrow: 1}}/>
                    <div>
                        <Tooltip title={intl.formatMessage({id: 'deleteEquipment'})}>
                            <IconButton onClick={softDelete} size="large">
                                <Delete/>
                            </IconButton>
                        </Tooltip>

                    </div>
                </React.Fragment>
            )}
        </div>
    );
}

export default EquipmentInput;