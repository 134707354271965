import React from 'react';
import PropTypes from 'prop-types';
import {List, ListItem, ListItemText} from "@mui/material";
import {useIntl} from "react-intl";
import {APP_BAR_OFFSET} from './constants';


function TOCDrawer(props) {

    const intl = useIntl();
    const {
        sheetSections
    } = props;


    function getSectionItems() {
        return sheetSections.map((id) => {
            return (
                <ListItem
                    button
                    key={id}
                    onClick={() => {
                        const section = document.getElementById(id);
                        if(section != null) {
                            section.scrollIntoView();
                            window.scrollBy(0, -APP_BAR_OFFSET);
                        }

                    }}
                >
                    <ListItemText
                        key="text"
                    >
                        {intl.formatMessage({id})}
                    </ListItemText>
                </ListItem>);
        });
    }

    return (
        <List
            key="vtm"
        >
            {getSectionItems()}
        </List>
    )

}

TOCDrawer.propTypes = {
    sheetSections: PropTypes.arrayOf(PropTypes.string),
};

TOCDrawer.defaultProps = {
    sheetSections: [],
};

export default TOCDrawer;