import {useContext, useState} from 'react';
import {CharacterContext} from './CharacterContainer';
import {registerListener} from "../MessageComponents/messageListeners";
import _ from 'lodash';
import {useUser} from "reactfire";
import {CharacterStore} from "./CharacterStore";

export function useIsEditable(){
    const myChar = useIsMyCharacter();
    const retired = useIsRetiredCharacter();
    return myChar && !retired;
}

export function useIsRetiredCharacter(){
    const character = useCharacter();
    return character.retired;
}

function useIsMyCharacter(){
    const character = useCharacter();
    const {data: user} = useUser();
    const userId = user.uid;
    return character.userId === userId;
}

export function useCharacter() {
    const [character] = useContext(CharacterContext) ?? [];
    return character ?? {};
}

export function useFilteredCharacters(filter) {
    const [allCharacters] = useContext(CharacterStore);
    const list = _.values(allCharacters);
    return list.filter(filter);
}

export function useActiveCharacters() {
    return useFilteredCharacters((char) => !char.deleted && !char.retired);
}

export function useRetiredCharacters() {
    return useFilteredCharacters((char) => !char.deleted && char.retired);
}

export function useCharacterId() {
    const [character] = useContext(CharacterContext);
    return character && character.id;
}

export function useCharacterListener(name, path) {
    const [character] = useContext(CharacterContext);
    const channel = character?.id;
    const initial = _.get(character, path);
    const [state, setState] = useState(initial);
    registerListener(channel, name, path, (value) => {
      //  console.error(`setting state to ${value}`);
        setState(value);
    });
    return state;
}

/** A character listener which repeatedly merges in the updates
 * to an initially empty object.
 * @param name
 * @param path
 * @returns {{}}
 */
export function useCharacterObjectListener(name, path, initialValue={}) {
    const [character] = useContext(CharacterContext);
    const channel = character?.id;
    const initial = _.get(character, path);
    const safeInitial = initial ?? initialValue;
    return useCharacterObjectListenerOnChannel(channel, name, path, safeInitial);
}

/**
 * Unsave version of char object listener. primarily used for root level listeners
 *
 * @param channel
 * @param name
 * @param path
 * @param initialValue
 * @returns {{}}
 */
export function useCharacterObjectListenerOnChannel(channel, name, path, initialValue = {}){
    // console.dir(character);
    const [state, setState] = useState(initialValue);
    registerListener(channel, name, path, (value) => {
        const cloned = {...state};
        //console.error(`setting state for path ${path} to ${value}`);
        setState(_.merge(cloned, value));
    });
    return state;
}