import React from 'react';
import SheetSection from "../../Components/SheetSection";
import { Grid } from "@mui/material";
import TextBox from "../../../Components/Inputs/TextBox";

function BackgroundInfo(props) {
    const fields = [ 'chronicle_tenets', 'touchstones_and_convictions', 'clan_bane'];

    return (
        <SheetSection
            titleId="background_info"
        >
            <Grid
                container
                spacing={2}
            >
                {fields.map((field) => {
                    return (
                        <Grid
                            item
                            key={field}
                            container
                            justifyContent="center"
                            xs={12}
                            sm={4}
                        >
                            <div style={{width: 400}}>
                            <TextBox
                                labelId={field}
                                rows={10}
                                maxLength={1024}
                                path={field}
                            />
                            </div>
                        </Grid>
                    );
                })}
            </Grid>
        </SheetSection>
    );
}

export default BackgroundInfo;