import React from 'react';
import StaminaInput from '../StaminaInput';
import HitPointsInput from '../HitPointsInput';
import ResolveInput from '../ResolveInput';
import SheetSection from '../Components/SheetSection';


const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
    }
}

function Health(props) {


    return (
        <SheetSection
            key="health"
            titleId="health"
        >
            <div style={styles.container}>
                <StaminaInput path="stamina"/>
                <HitPointsInput path="hit_points"/>
                <ResolveInput path="resolve"/>
            </div>
        </SheetSection>
    );
}

export default Health;