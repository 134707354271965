import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useIntl} from "react-intl";
import {TextField} from "@mui/material";
import {useCharacterId, useCharacterListener, useIsEditable} from "../../Characters/characterHooks";
import {broadcastMessage} from "../../MessageComponents/messageListeners";
import {INPUT_SAVE_DELAY_MILLIS} from '../../constants';
import {pathToCSSId} from '../../Utils/css';

let inputTimeout;
function TextInput(props) {
    const {
        name, size,
        labelId, path, dense,
        label, readOnly,
        inputLabelStyle,
        variant,
        style
    } = props;

    const usedName = name ?? path;
    const usedLabelId = labelId || usedName;
    const fieldRef = useRef();
    const channel = useCharacterId();
    const storedValue = useCharacterListener(usedName, path);
    const isEditable = useIsEditable();
    const isReadOnly = !isEditable || readOnly;
    const intl = useIntl();

    function onInputChange(event) {
        const newValue = event.target.value;
        if (newValue !== storedValue) {
            clearTimeout(inputTimeout);
            inputTimeout = setTimeout(() => {
                broadcastMessage(channel, path, newValue);
            }, INPUT_SAVE_DELAY_MILLIS);
        }
    }

    const margin = dense? 'dense' : 'normal';
    const usedLabel = label? label : intl.formatMessage({id: usedLabelId});
    const usedStyle = {
        margin: 0,
            padding: 0,
        ...style
    };
    return (
        <div style={{width: `${size}ch`}}>
            <TextField
                variant={variant}
                id={pathToCSSId(path)}
                style={usedStyle}
                label={usedLabel}
                defaultValue={storedValue}
                inputProps={{readOnly: isReadOnly, ref: fieldRef}}
                fullWidth
                InputLabelProps={{style: inputLabelStyle, shrink: true}}
                onBlur={onInputChange}
                disabled={isReadOnly}
                margin={margin}
            />
        </div>
    );
}

TextInput.propTypes = {
    name: PropTypes.string,
    path: PropTypes.string.isRequired,
    size: PropTypes.number,
    labelId: PropTypes.string,
    label: PropTypes.string,
    dens: PropTypes.bool,
    inputLabelStyle: PropTypes.object,
    style: PropTypes.object,
    variant: PropTypes.string,
};

TextInput.defaultProps = {
    name: undefined,
    size: 40,
    labelId: '',
    label: '',
    dense: false,
    path: 'fakepath',
    inputLabelStyle: {},
    variant: "outlined",
    style: {},
};

export default TextInput;

