import React from 'react';
import PropTypes from 'prop-types';
import {Typography, useTheme} from "@mui/material";
import {useIntl} from "react-intl";
import {makeStyles} from "@mui/styles";
import CheckInput from "../../Components/Inputs/CheckInput";
import VtMSlider from "./VtMSlider";

const SKILL_MAX = 5;

const skillStyles = makeStyles((theme) => {
    return {
        root: {
            marginRight: theme.spacing(2),
            display: 'flex',
        },
        name: {
            textAlign: 'left',
            overflow: 'hidden',
            whiteSpace: 'noWrap',
            flexGrow: 1,
        },
        input: {
            marginLeft: 4,
        },
        specialty: {
            marginTop: -5,
            marginRight: -4,
            color: '#000000',
        }
    }
})

function Skill(props) {

    const intl = useIntl();
    const theme = useTheme();
    const skillClasses = skillStyles(theme);
    const overflow = '..................................................';
    const {
        translationId,
        path,
        appendOverflow
    } = props;


    return (
        <div
            className={skillClasses.root}
        >
            <div className={skillClasses.specialty}>
                <CheckInput
                    path={`${path}.specialty`}
                />
            </div>
            <div
                className={skillClasses.name}
            >
                <Typography className={skillClasses.nameText} variant="h6">
                    {intl.formatMessage({id: translationId})}{appendOverflow && `${overflow}`}
                </Typography>
            </div>
            <div
                className={skillClasses.input}
            >
                <VtMSlider
                    max={SKILL_MAX}
                    path={`${path}.score`}
                />
            </div>
        </div>
    );
}

Skill.propTypes = {
    path: PropTypes.string.isRequired,
};

Skill.defaultProps = {
};

export default Skill;
