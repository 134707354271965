import React, {useContext} from 'react';
import {Typography} from "@mui/material";
import {useIntl} from "react-intl";
import {CharacterContext} from '../../Characters/CharacterContainer';
import NumberInput from '../../Components/Inputs/NumberInput';
import {useCharacterObjectListener} from "../../Characters/characterHooks";


function SkillInputRow(props) {

    const {
        path,
        ability,
        modifier,
        name,
        armorPenalty,
        largeLayout,
    } = props;

    const intl = useIntl();
    const [character] = useContext(CharacterContext);

    const acp = character.armor_check_penalty;
    const usableAcp = acp !== null && acp !== undefined && !isNaN(acp);
    const hasPenalty = armorPenalty && usableAcp && acp !== 0;
    const armorCheckPenalty = hasPenalty ? acp : 0;
    const current = useCharacterObjectListener(path, path);
   // console.dir(current)
    const safeRanks = current.ranks ?? 0;
    const safeClass = current.class ?? 0;
    const safeMisc = current.misc ?? 0;
    const safeModifier = modifier ?? 0;
    const totalBeforeModifier = (safeRanks + safeClass + safeMisc + safeModifier);
    //console.debug(`${name}: ${totalBeforeModifier} ${safeRanks} ${safeClass} ${safeMisc} ${safeModifier}`);
    const total = (safeRanks === 0)? 0 :  (totalBeforeModifier - armorCheckPenalty);
    const variantProps = largeLayout? {} : {variant: 'standard'}
    const abilityType = intl.formatMessage({id: ability});

    return (
        <React.Fragment>
            {largeLayout &&(<td>
                <Typography align="left">{intl.formatMessage({id: name.toLowerCase()}, {ability: abilityType})}</Typography>
            </td>)}
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    labelId="total"
                    min={-10}
                    max={40}
                    size={4}
                    path={`${path}.total`}
                    readOnlyValue={total}
                    readOnly
                    {...variantProps}
                />
            </td>
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    labelId="skillRanks"
                    min={0}
                    max={40}
                    size={4}
                    path={`${path}.ranks`}
                    {...variantProps}
                />
            </td>
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    labelId="skillClass"
                    min={0}
                    max={40}
                    size={4}
                    path={`${path}.class`}
                    {...variantProps}
                />
            </td>
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    labelId="abilityMod"
                    min={-10}
                    max={40}
                    size={4}
                    readOnly
                    path={`${path}.abilityMod`}
                    readOnlyValue={safeModifier}
                    {...variantProps}
                />
            </td>
            <td>
                <NumberInput
                    noLabel={largeLayout}
                    labelId="skillMisc"
                    min={0}
                    max={40}
                    size={4}
                    path={`${path}.misc`}
                    {...variantProps}
                />
            </td>
        </React.Fragment>
    );
}

export default SkillInputRow;