import React from 'react';
import StarfinderNumberInput from "./Components/StarfinderNumberInput";


function DexLimitInput(props) {


    return (
            <StarfinderNumberInput path="armor_dex_limit" labelId="dexLimit" size={5} {...props}/>
    );
}

export default DexLimitInput;