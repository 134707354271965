import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "./helpers/AnimationRevealPage.js";
import Hero from "./components/hero/TwoColumnWithInput.js";
import Features from "./components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "./components/features/TwoColWithSteps.js";
import Footer from "./components/footers/FiveColumnWithInputForm.js";
import macHeroScreenshotImageSrc from "./images/hero-screenshot-2.png";
import TwoPlansWithDurationSwitcher from "./components/pricing/TwoPlansWithDurationSwitcher";

export const FrontPage = () => {
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>
      <Hero roundedHeaderButton={false} />
      <Features
        subheading={null}
        heading={
          <>
            Many Characters <HighlightedText>One Place.</HighlightedText>
          </>
        }
        description={
            <>
                All your characters are accessible from one simple interface.
            </>
        }
      />
      <FeatureWithSteps
        subheading={null}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={true}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
        <TwoPlansWithDurationSwitcher/>
      <Footer />
    </AnimationRevealPage>
  );
}

export default FrontPage;