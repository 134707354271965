import React from 'react';
import ListSection from '../../../Components/ListSection';
import AdvantageOrFlaw from "../AdvantageOrFlaw";
import SheetSection from '../../Components/SheetSection';
import AddButton from "../../../Components/AddButton";

function AdvantagesAndFlaws(props) {
    const path = 'advantages_and_flaws';
    return (
        <ListSection
            titleId="advantages_and_flaws"
            addButton={<AddButton path={path} addLabelId="add_advantage_or_flaw"/>}
            path={path}
            inputPrototype={AdvantageOrFlaw}
            sheetSection={SheetSection}
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
        />
    );
}

export default AdvantagesAndFlaws