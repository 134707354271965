import React from 'react';
import ListInput from './Inputs/ListInput';
import useInView from "@owaiswiz/use-in-view";

function ListSection(props) {
    const {
        path,
        titleId,
        inputPrototype,
        children,
        sheetSection,
        addButton,
    } = props;

    const SheetSection = sheetSection;
    const [ref, inView] = useInView();
    return (
        <SheetSection
            key={titleId}
            titleId={titleId}
            action={addButton}
        >
            <div ref={ref}>
                {inView && (<ListInput
                        path={path}
                        inputPrototype={inputPrototype}
                    />
                )}
                {children}
            </div>
        </SheetSection>
    );
}

export default ListSection;