import React from 'react';
import {FaDiceD20} from 'react-icons/fa';
import {useHistory} from "react-router-dom";
import {Tooltip} from "@mui/material";
import {useIntl} from "react-intl";
import {fonts} from "../appConstants";

function Wordmark(props) {
    const intl = useIntl();
    const history = useHistory();

    return (
        <Tooltip title={intl.formatMessage({id: 'home'})}>
            <div
                onClick={() => history.push('/')}
                style={{
                    cursor: "pointer",
                    fontFamily: fonts.logoFont,
                    fontSize: 24,
                }}
            >
                Character<FaDiceD20/>Place
            </div>
        </Tooltip>
    );
}

export default Wordmark