import * as React from 'react';
import {Typography} from "@mui/material";
import {useIntl} from "react-intl";
import DnD5eSlider from "../Components/Dnd5eSlider";

export interface Props {
    path: string,
}

const styles = {
    container: {
        border: `1px dashed`,
        borderRadius: 10,
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    sliderBox: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    sliderLabel: {
        textAlign: 'left',
        marginTop: 4,
        marginRight: 16,
        fontSize: '14px',
    }

}

export const DeathSaves: React.FC<Props> = (props) => {

    const {path} = props;

    const intl = useIntl();


    return (
        <div
            style={styles.container as React.CSSProperties}
        >
            <div style={styles.sliderBox}>
                <Typography
                    style={styles.sliderLabel as React.CSSProperties}
                >
                    {intl.formatMessage({id: 'successes'})}
                </Typography>
                <DnD5eSlider max={3} path={`${path}.{successes}`}/>
            </div>
            <div style={styles.sliderBox}>
                <Typography
                    style={styles.sliderLabel as React.CSSProperties}
                >
                    {intl.formatMessage({id: 'failures'})}
                </Typography>
                <DnD5eSlider max={3} path={`${path}.{failures}`}/>
            </div>
            <Typography>
                {intl.formatMessage({id: 'death_saves'})}
            </Typography>

        </div>

    )

}

export default DeathSaves;