import React from 'react';
import {Grid, Typography} from "@mui/material";
import SheetSection from "../../Components/SheetSection";
import Skill from "../Skill";
import {CheckBox} from "@mui/icons-material";
import {useIntl} from "react-intl";

const skills = [
    "athletics",
    "animal_ken",
    "academics",
    "brawl",
    "etiquette",
    "awareness",
    "craft",
    "insight",
    "finance",
    "drive",
    "intimidation",
    "investigation",
    "firearms",
    "leadership",
    "medicine",
    "melee",
    "performance",
    "occult",
    "larceny",
    "persuasion",
    "politics",
    "stealth",
    "streetwise",
    "science",
    "survival",
    "subterfuge",
    "technology",
]


function Skills(props) {

    const styles = {
        checkbox: {
            marginTop: '5px',
        },
        headerContainer: {
            display: 'flex',
            justifyContent: 'center',
        }
    };


    const path = 'skills';

    const intl = useIntl();

    function renderSkills() {
        return skills.map((skill) => {
            return (
                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={skill}
                >
                    <Skill
                        appendOverflow
                        path={`${path}.${skill}`}
                        translationId={skill}
                    />
                </Grid>);
        });
    }

    return (
        <SheetSection titleId='skills'>
            <Grid
                container
                spacing={2}

            >
                <Grid
                    item
                    container
                    xs={12}
                    key="explaination"
                    justifyContent="center"
                >
                    <div style={styles.headerContainer}>
                        <div>
                    <CheckBox style={styles.checkbox}/>
                        </div>
                    <Typography variant="h6" style={styles.specialization}>
                        {intl.formatMessage({id: 'is_specialization'})}
                    </Typography>
                    </div>
                </Grid>
                {renderSkills()}
            </Grid>
        </SheetSection>
    )
}

export default Skills;
