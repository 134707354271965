import * as React from 'react';

import {Typography} from "@mui/material";
import {useIntl} from "react-intl";
import NumberInput from "../../../Components/Inputs/NumberInput";


export interface Props {
    path: string,
    labelId?: string
    labelArgs?: object,
    labelOnSide?: boolean,
    max?: number,
    min?: number,
    size?: number,
    noLabel?: boolean,
    readOnly?: boolean,
    readOnlyValue?: number,
}

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8,
    },
    label: {
        marginLeft: '8px',
        fontSize: '14px',
    },
    inputLabel: {
        overflowWrap: 'break-word',
    }
}


export const StarFinderNumberInput: React.FC<Props> = (props) => {

    const intl = useIntl();
    const {labelId, labelOnSide, labelArgs} = props;
    if (!labelOnSide) {
        return <NumberInput {...props} variant="outlined" inputLabelStyle={styles.inputLabel}/>;
    }
    // @ts-ignore
    const label = intl.formatMessage({id: labelId}, labelArgs)
    return (
        <div style={styles.container}>
            <NumberInput {...props} noLabel={labelOnSide} variant="outlined"/>
            <Typography style={styles.label as React.CSSProperties}>
                {label}
            </Typography>
        </div>
    )
}

export default StarFinderNumberInput;