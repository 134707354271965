import React from 'react';
import SheetSection from '../Components/SheetSection';
import {Grid, Typography, useMediaQuery} from '@mui/material';
import EACInput from '../EACInput';
import KACInput from '../KACInput';
import DexLimitInput from '../DexLimitInput';
import AcVsCombat from '../../AcVsCombat';
import DRInput from '../DRInput';
import ResistancesInput from '../ResistancesInput';
import ArmorCheckPenalty from '../ArmorCheckPenaltyInput';
import {useIntl} from "react-intl";
import {useTheme} from "@mui/styles";

const styles = {
    armorClassTable: {
        tableLayout: 'fixed',
        width: '100%',
    },
};

function ArmorClasses(props) {
    const intl = useIntl();
    const theme = useTheme();
    const largeLayout = useMediaQuery(theme.breakpoints.up('sm'));
    const headerIds = ['armorClasses', 'total', 'armorClassArmor', 'armorClassDex', 'armorClassMisc'];

    function generateClassTable() {
        return (
            <table style={styles.armorClassTable}>
                <thead>
                <tr>
                    {headerIds.map((id) => {
                        return (
                            <th key={id}><Typography>{intl.formatMessage({id})}</Typography></th>
                        );
                    })}

                </tr>
                </thead>
                <tbody>
                <EACInput/>
                <KACInput/>
                </tbody>
            </table>
        );
    }

    function generateClassGrid() {
        return (
            <Grid
                container
                direction="column"
            >
                <Grid
                    item
                >
                    <Typography align="center">{intl.formatMessage({id: 'energyArmorClass'})}</Typography>
                </Grid>
                <Grid
                    item
                >
                    <table style={styles.armorClassTable}>
                        <tbody>
                        <EACInput/>
                        </tbody>
                    </table>
                </Grid>
                <Grid
                    item
                >
                    <Typography align="center">{intl.formatMessage({id: 'kineticArmorClass'})}</Typography>
                </Grid>
                <Grid
                    item
                >
                    <table style={styles.armorClassTable}>
                        <tbody>
                        <KACInput/>
                        </tbody>
                    </table>
                </Grid>
            </Grid>
        );
    }

    return (
        <SheetSection
            key="armorClasses"
            titleId="armorClasses"
        >
            <div>
                <Typography align="center">
                    {intl.formatMessage({id: 'armorClassDescription'})}
                </Typography>
                {largeLayout && (generateClassTable())}
                {!largeLayout && (generateClassGrid())}
                <div
                    style={{marginTop: 10, display: 'block', marginLeft: 'auto', marginRight: 'auto'}}
                >
                    <AcVsCombat/>
                </div>
                <hr/>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                >
                    <Grid
                        item
                    >
                        <DexLimitInput/>
                    </Grid>
                    <Grid
                        item
                    >
                        <ArmorCheckPenalty/>
                    </Grid>
                    <Grid
                        item
                    >
                        <DRInput/>
                    </Grid>
                    <Grid
                        item
                    >
                        <ResistancesInput/>
                    </Grid>
                </Grid>
            </div>
        </SheetSection>
    );
}

export default ArmorClasses;
