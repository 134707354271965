import React from 'react';
import TOCDrawer from '../TOCDrawer';


function StarfinderDrawer(props) {
    const {combatMode} = props;


    const peaceSections = [
        'vitalInformation',
        'health',
        'abilitiesTable',
        'initiative',
        'armorClasses',
        'skillsTable',
        'savingThrows',
        'attackBonuses',
        'abilities',
        'featsAndProficiencies',
        'spells',
        'weapons',
        'equipment',
        'exp',
        'wealth',
        'languages'
    ];


    const combatSections = [
        'abilitiesTable',
        'health',
        'initiative',
        'armorClasses',
        'savingThrows',
        'attackBonuses',
        'spells',
        'weapons',
        'equipment',
    ];

    const sheetSections = combatMode? combatSections : peaceSections;
    return (
        <TOCDrawer sheetSections={sheetSections}/>
    )

}

export default StarfinderDrawer;